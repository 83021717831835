import { useEffect } from 'react';
import useRefreshToken, { setAuth } from './useRefreshToken';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { Navigate } from 'react-router-dom';
import { APP_ROUTES, API_ROUTES } from '../utils/constants';
import { toast } from 'react-toastify';
const website: any = process.env.REACT_APP_ENV;

export type authDetailsType = {
  access: {
    token: string;
  };
  refresh: {
    token: string;
  };
  is_onboarded: boolean;
  FirstName: string;
  LastName: string;

  Email: string;
};

export const getCurrentAuth = () => {
  const localAuth = localStorage.getItem('auth');
  const auth: authDetailsType = localAuth ? JSON.parse(localStorage.getItem('auth') || '') : {};
  return auth;
};

export const Logout = () => {
  localStorage.removeItem('auth');
};
const axiosInstance = axios.create();

const refresh = async () => {
  const auth = getCurrentAuth();
  console.log('auth ::', auth);

  console.log('I am in refreshn  ', auth);

  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `bearer ${auth.access?.token}`
    };
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_API_URL}${API_ROUTES.RefreshToken}`,
      {
        refresh_token: auth.refresh?.token
      },
      { headers: headers }
    );
    if (response.data) {
      const body: any = response.data.data;
      setAuth(body);
      return body.access.token;
    }
  } catch (error: any) {
    Logout();
  }
};

let flag = false;
const refreshExpiredTokenClosure = () => {
  let isCalled = false;
  let runningPromise: any = undefined;
  return () => {
    if (isCalled && flag) {
      return runningPromise;
    } else {
      isCalled = true;
      flag = true;
      runningPromise = refresh();

      return runningPromise;
    }
  };
};
function shouldRetry(config: any) {
  return config.retries.count < 3;
}
const refreshExpiredToken = refreshExpiredTokenClosure();

axiosInstance.interceptors.request.use(
  // @ts-ignore: Unreachable code error
  (config: AxiosRequestConfig) => {
    const auth = getCurrentAuth();
    if (!config.headers!['Authorization']) {
      config.headers!['Authorization'] = `bearer ${auth?.access?.token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    error.config.retries = error.config?.retries || {
      count: 0
    };
    const originalRequest = error.config;

    // logout user's session if refresh token api responds 401 UNAUTHORIZED

    // if request fails with 401 UNAUTHORIZED status and 'Token has expired' as response message
    // then it calls the api to generate new access token
    if (error.response.status === 401 && shouldRetry(error.config)) {
      error.config.retries.count += 1;
      const updatedToken = await refreshExpiredToken();
      if (!updatedToken) {
        Logout();
        window.location.href = `${APP_ROUTES.Landing}`;
      } else {
        originalRequest.headers['Authorization'] = `bearer ${updatedToken}`;
        flag = false;
        return axiosInstance(originalRequest);
      }
    } else {
      toast.error(error?.response.data.message);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
