import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import HttpService from '../../hooks/Https-services';
import { API_ROUTES } from '../../utils/constants';
import sound from '../../assets/audio/audio.mp3';

const TimerContext: any = createContext(null);

export const TimerContextProvider = ({ children }: any) => {
  const [timerValue, setTimerValue] = useState('00:00');
  const [deadline, setDeadline] = React.useState('Focus');
  const [timerInterval, setTimerInterval] = useState<any>(null); // State to hold timer interval reference
  const [focus, setFocus] = useState<any>(null);
  const [short, setShort] = useState<any>(null);
  const [long, setLong] = useState<any>(null);
  const [isSound, setIsSound] = useState(false);
  const getUserTimer = async () => {
    try {
      const res = await HttpService.get(API_ROUTES.GetUserTimer);
      const data = res.data.data;
      setIsSound(data && data.sound);

      setTimerValue(data ? `${data?.focus}:00` : '00:00');
      setFocus(data ? `${data?.focus}:00` : '00:00');
      setShort(data ? `${data?.short_break}:00` : '00:00');
      setLong(data ? `${data?.long_break}:00` : '00:00');
      // localStorage.setItem('focus', `${data?.focus}:00`);
      // localStorage.setItem('short', `${data?.short_break}:00`);
      // localStorage.setItem('long', `${data?.long_break}:00`);
      const checkTimer: any = JSON.parse(localStorage.getItem('timer') || '{}'); // Parse JSON data or default to an empty object
      if (checkTimer) {
        if (checkTimer.short) {
          setDeadline('Short');
          if (checkTimer.isTimerStart) {
            // Check if timer should be started
            startTimer('Short');
          } else {
            setTimerValue(checkTimer.short); // Set timer value without starting the timer
          }
        } else if (checkTimer.focus) {
          setDeadline('Focus');
          if (checkTimer.isTimerStart) {
            // Check if timer should be started
            startTimer('Focus');
          } else {
            setTimerValue(checkTimer.focus); // Set timer value without starting the timer
          }
        } else if (checkTimer.long) {
          setDeadline('Long');
          if (checkTimer.isTimerStart) {
            // Check if timer should be started
            startTimer('Long');
          } else {
            setTimerValue(checkTimer.long); // Set timer value without starting the timer
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const startTimer = (tab = 'Focus') => {
    const checkTimer: any = JSON.parse(localStorage.getItem('timer') || 'false'); // Parse JSON data or default to an empty object
    let storedTimerValue: any = '00:00';
    if (checkTimer) {
      if (checkTimer.short) {
        storedTimerValue = checkTimer.short;
      } else if (checkTimer.focus) {
        storedTimerValue = checkTimer.focus;
      } else if (checkTimer.long) {
        storedTimerValue = checkTimer.long;
      }
    } else {
      if (tab.toLowerCase() === 'focus') {
        storedTimerValue = focus;
      } else if (tab.toLowerCase() === 'long') {
        storedTimerValue = long;
      } else if (tab.toLowerCase() === 'short') {
        storedTimerValue = short;
      }
    }

    // storedTimerValue = localStorage.getItem(deadline.toLowerCase());

    if (storedTimerValue) {
      setTimerValue(storedTimerValue);
      const [minutes, seconds] = storedTimerValue.split(':').map(Number);
      let totalSeconds = minutes * 60 + seconds;

      const interval = setInterval(() => {
        totalSeconds -= 1;
        if (totalSeconds >= 0) {
          const mins = Math.floor(totalSeconds / 60);
          const secs = totalSeconds % 60;
          setTimerValue(`${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`);
          //   const currentTab = deadline.toLowerCase();
          const timer: any = {
            [tab.toLowerCase()]: `${mins.toString().padStart(2, '0')}:${secs
              .toString()
              .padStart(2, '0')}`,
            isTimerStart: true
          };
          localStorage.setItem('timer', JSON.stringify(timer));
        } else {
          clearInterval(interval);
          setTimerValue('00:00');
          setTimerInterval(null);
          localStorage.removeItem('timer');
          if (isSound) {
            let mySound = new Audio(sound);
            mySound.play();
          }
          getUserTimer();
          toast.success('Timer Finish successfully.');
        }
      }, 1000);

      setTimerInterval(interval);
    }
  };
  const resetTimer = () => {
    pauseTimer();
    localStorage.removeItem('timer');
    setDeadline('Focus');
    getUserTimer();
  };

  const pauseTimer = () => {
    clearInterval(timerInterval);
    setTimerInterval(null);
    const currentTab = deadline.toLowerCase();
    const timer: any = {
      [currentTab]: timerValue,
      isTimerStart: false
    };
    localStorage.setItem('timer', JSON.stringify(timer));
    // localStorage.setItem(deadline.toLowerCase(), timerValue);
  };

  const handleDeadlineChange = (value: string) => {
    localStorage.removeItem('timer');

    if (timerInterval === null) {
      setDeadline(value);
      if (value.toLowerCase() === 'focus') {
        setTimerValue(focus);
      } else if (value.toLowerCase() === 'long') {
        setTimerValue(long);
      } else if (value.toLowerCase() === 'short') {
        setTimerValue(short);
      }
      // Store the current timer value before switching modes
      // const storedTimerValue = localStorage.getItem(deadline?.toLowerCase());
      // if (storedTimerValue) {
      //   localStorage.setItem(deadline.toLowerCase(), timerValue);
      // }
      // // // Update the deadline state to the new mode
      // // setDeadline(value);
      // // Retrieve the new timer value for the selected mode from local storage
      // const newTimerValue = localStorage.getItem(value?.toLowerCase()) || '25:00';
      // setTimerValue(newTimerValue);
    }
  };
  return (
    <TimerContext.Provider
      value={{
        timerValue,
        setTimerValue,
        handleDeadlineChange,
        pauseTimer,
        resetTimer,
        startTimer,
        getUserTimer,
        long,
        short,
        focus,
        timerInterval,
        deadline
      }}>
      {children}
    </TimerContext.Provider>
  );
};

export const useTimerContext = () => {
  return useContext(TimerContext);
};
