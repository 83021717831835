import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timerGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import { Box, Button, CircularProgress, IconButton } from '@mui/material';
import AddTaskPopUp from '../AddTaskPopUp';
import HttpService from '../../../hooks/Https-services';
import { API_ROUTES, APP_ROUTES } from '../../../utils/constants';
import { useNavigate } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import left from '../../../assets/Icons/left.svg';
import upRight from '../../../assets/Icons/upRight.svg';

function ScheduleHome() {
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState<any>([{}]);

  const [isOtherComponentVisible, setIsOtherComponentVisible] = useState(false);

  const [selectedDateTime, setSelectedDateTime] = useState({});

  const handleSelect = (info: any) => {
    setSelectedDateTime({
      startTime: info.startStr,
      endTime: info.endStr
    });
    setIsOtherComponentVisible(true);
  };

  const navigate = useNavigate();

  const markOverlappingEvents = (events: any) => {
    // Sort events by start time
    const sortedEvents = [...events].sort(
      (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
    );

    // Traverse through sorted events to find overlaps
    for (let i = 1; i < sortedEvents.length; i++) {
      const prevEvent = sortedEvents[i - 1];
      const currEvent = sortedEvents[i];

      if (new Date(prevEvent.end).getTime() > new Date(currEvent.start).getTime() + 60000) {
        if (prevEvent.is_user_calendar_task) {
          prevEvent.color = 'red';
        }
        if (currEvent.is_user_calendar_task) {
          currEvent.color = 'red';
        }
        // currEvent.color = 'red';
      }
    }

    return sortedEvents;
  };
  const getAllEvents = async () => {
    try {
      setIsLoading(true);
      const Priority = await HttpService.get(API_ROUTES.GetAllCalendarEvent);
      const eventsWithColors = Priority.data.data.map((event: any) => ({
        ...event,
        color: event?.is_user_calendar_task ? 'orange' : '#06C7BB'
      }));
      setEvents(markOverlappingEvents(eventsWithColors));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllEvents();
  }, []);

  const calendarRef = useRef<any>(null);

  const handleGoToToday = () => {
    const calendarApi = calendarRef?.current?.getApi();
    calendarApi.gotoDate(new Date());
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    // for (let i = 0; i < 6; i++) {
    //   color += letters[Math.floor(Math.random() * 16)];
    // }
    return '#000';
  };

  const renderEventContent = (eventInfo: any) => {
    return (
      <Box
        sx={{
          height: 1,
          display: 'flex',
          flexDirection: 'column',
          cursor: eventInfo.event._def.extendedProps.is_user_calendar_task ? 'default' : 'pointer',
          justifyContent: 'space-between'
        }}>
        <div
          style={{
            backgroundColor: eventInfo.event.extendedProps.color,
            padding: '5px',
            borderRadius: '3px',
            color: '#fff'
          }}>
          <i>{eventInfo.event.title}</i>
          <br />
          <b>{eventInfo.timeText}</b>
        </div>
      </Box>
    );
  };

  return (
    <Box>
      <Box mb={1} sx={{ marginLeft: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
        {/* <Button
          variant="outlined"
          color="secondary"
          size="small"
          sx={{ borderRadius: '7px' }}
          onClick={() => {
            setOpen(true);
          }}>
          <AddIcon fontSize="small" sx={{ mr: '5px' }} />
          Add Task
        </Button> */}
        <AddTaskPopUp
          isEditTask={false}
          getAllEvents={getAllEvents}
          setIsOtherComponentVisible={setIsOtherComponentVisible}
        />
      </Box>

      {!isLoading ? (
        <>
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timerGridPlugin, interactionPlugin]}
            dayHeaderFormat={{ weekday: 'short' }}
            allDaySlot={false}
            hiddenDays={[0]}
            events={events}
            eventContent={renderEventContent}
            eventColor={'#06C7BB'}
            eventChange={(info: any) => {
              const findIndex = events.findIndex((el: any) => info.event.id === el?.id);
              events[findIndex].start = info.event.startStr;
              events[findIndex].end = info.event.endStr;
              setEvents([...events]);
            }}
            eventClick={(e) => {
              if (e.event._def.extendedProps.is_internal_link) {
                navigate(APP_ROUTES.SchedulePlans + `?id=${e?.event?._def?.publicId}`);
              }
              if (e.event._def.extendedProps.link) {
                window.open(e.event._def.extendedProps.link);
              }
            }}
            slotMinTime={'0:00:00'}
            slotMaxTime={'24:00:00'}
            initialView={'timeGridWeek'}
            editable={false}
            droppable={false}
            headerToolbar={{
              start: 'prev todayButton',
              center: 'title',
              end: 'timeGridDay,timeGridWeek next'
            }}
            height={'63vh'}
            customButtons={{
              todayButton: {
                text: 'Today',
                click: handleGoToToday
              }
            }}
            selectable={true}
            select={(info) => {
              handleSelect(info);
            }}
          />
          {isOtherComponentVisible && (
            <AddTaskPopUp
              isEditTask={false}
              getAllEvents={getAllEvents}
              selectedDateTime={selectedDateTime}
              setIsOtherComponentVisible={setIsOtherComponentVisible}
              isOtherComponentVisible={isOtherComponentVisible}
            />
          )}
        </>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
          <CircularProgress size={36} sx={{ margin: '0 auto' }} />
        </Box>
      )}
    </Box>
  );
}

export default ScheduleHome;
