// colors.js or colors.ts

const colors = {
  primary: '#08283D',
  secondary: '#06C7BB',
  secondaryLight: '#5AC4BA',
  secondaryLighter: '#06C7BB24',
  light: '#484848',
  lighter: '#F6F6F6',
  darkGrey: '#8A8E91',
  green: '#46ff1761',
  darkRed: '#DE0000',
  red: '#de000029',
  redLighter: '#fdcac8',
  white: '#fff'
  // success: "#28a745",
  // danger: "#dc3545",
  // warning: "#ffc107",
  // info: "#17a2b8",
  // dark: "#343a40",
};

export default colors;
