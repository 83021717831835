import {
  Box,
  Button,
  Card,
  Collapse,
  Dialog,
  FormControl,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import colors from '../colorConfig/colors';
import React, { useEffect, useState } from 'react';
import TourIcon from '@mui/icons-material/Tour';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import moment from 'moment';
import HttpService from '../../../hooks/Https-services';
import { API_ROUTES, Priority, PriorityColor } from '../../../utils/constants';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateGroupPopUp from '../CreateGroupPopUp';

function CreatePlanPopUp(props: any) {
  const { getPlans, isEdit, datas } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [daysOffPlan, setDaysOffPlan] = useState<any>(['mon']);
  const [schedule, setSchedule] = useState<any>([]);
  const [scheduler, setScheduler] = useState<any>(null);

  const [planPriority, setPlanPriority] = useState<any>(null);
  const [selPlanPriority, setSelPlanPriority] = useState('Low');

  const [openCreatePlan, setOpenCreatePlan] = React.useState(false);

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    unregister,
    formState: { errors }
  } = useForm();

  const handleChangeDaysOffPlan = (event: SelectChangeEvent) => {
    const {
      target: { value }
    } = event;
    setDaysOffPlan(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChangeSchedule = (event: SelectChangeEvent) => {
    const {
      target: { value }
    } = event;
    setSchedule(typeof value === 'string' ? value.split(',') : value);
  };

  const getScheduler = async () => {
    try {
      setIsLoading(true);
      const AllSchedules = await HttpService.get(API_ROUTES.GetAllSchedules);
      setScheduler(AllSchedules.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getPriority = async () => {
    try {
      setIsLoading(true);
      const PriorityID = 1;
      const Priority = await HttpService.get(
        API_ROUTES.ApplicationObject.replace(':id', `${PriorityID}`)
      );
      setPlanPriority(Priority.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onSubmitUpdate = async (data: any) => {
    try {
      const dataBody = {
        plan_title: data?.plan_title,
        start_date: data?.start_date,
        deadline: data?.endDate,
        days_off: daysOffPlan,
        schedule_ids: schedule,
        priority: selPlanPriority
      };
      await HttpService.patch(
        API_ROUTES.UpdatePlan.replace(':id', `${datas.user_plan_sid}`),
        dataBody
      );
      reset();
      toast.success('Plan Updated successfully');
      setOpenCreatePlan(false);
      getPlans();
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (data: any) => {
    try {
      const dataBody = {
        plan_title: data.plan,
        start_date: data.startDate,
        deadline: data.endDate,
        days_off: daysOffPlan,
        schedule_id: schedule,
        priority: selPlanPriority
      };
      await HttpService.post(API_ROUTES.CreatePlan, dataBody);
      reset();
      toast.success('Plan created successfully');
      setOpenCreatePlan(false);
      getPlans();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (openCreatePlan) {
      getScheduler();
      getPriority();

      if (isEdit) {
        setValue('plan', datas.plan_title);
        setSelPlanPriority(datas.priority);
        setValue('startDate', moment(datas.start_date).format('YYYY-MM-DD'));
        setValue('endDate', moment(datas.deadline).format('YYYY-MM-DD'));
        setDaysOffPlan(datas.days_off);
        setSchedule(datas.schedule_ids);
      }
    }
  }, [openCreatePlan]);

  return (
    <>
      {isEdit ? (
        <ModeEditOutlineIcon
          fontSize="small"
          sx={{ m: '0 10px 0 20px' }}
          onClick={() => setOpenCreatePlan(true)}
        />
      ) : (
        <Button
          color="secondary"
          variant="outlined"
          size="small"
          sx={{ borderRadius: '7px' }}
          onClick={() => setOpenCreatePlan(true)}>
          <AddIcon fontSize="small" sx={{ mr: '5px' }} />
          Create Plan
        </Button>
      )}
      {isEdit ? (
        <Dialog
          onClose={() => setOpenCreatePlan(false)}
          open={openCreatePlan}
          maxWidth={'md'}
          fullWidth>
          <Card sx={{ padding: '1.5rem 2rem !important', overflowY: 'scroll' }}>
            <>
              <form onSubmit={handleSubmit(onSubmitUpdate)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="body1" sx={{ mb: '7px 0' }}>
                      Plan Title
                    </Typography>
                    <TextField
                      size="small"
                      id="outlined-basic"
                      {...register('plan', {
                        required: 'This field is required.'
                      })}
                      onChange={() => console.log(watch('title'))}
                      helperText={
                        errors.plan && (
                          <Typography variant="caption" sx={{ color: 'red' }}>
                            {errors?.plan?.message as any}
                          </Typography>
                        )
                      }
                      fullWidth
                      variant="outlined"
                      placeholder="Amino acids introduction and further studies"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">Start Date</Typography>
                    <TextField
                      type="date"
                      variant="outlined"
                      fullWidth
                      size="small"
                      {...register('startDate', {
                        required: 'This field is required.'
                      })}
                      onKeyDown={(event: any) => {
                        event.preventDefault();
                      }}
                      helperText={
                        errors.startDate && (
                          <Typography variant="caption" sx={{ color: 'red' }}>
                            {errors?.startDate?.message as any}
                          </Typography>
                        )
                      }
                      inputProps={{
                        min: moment().format('YYYY-MM-DD')
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">Deadline</Typography>
                    <TextField
                      type="date"
                      variant="outlined"
                      fullWidth
                      size="small"
                      {...register('endDate', {
                        required: 'This field is required.'
                      })}
                      onKeyDown={(event: any) => {
                        event.preventDefault();
                      }}
                      helperText={
                        errors.endDate && (
                          <Typography variant="caption" sx={{ color: 'red' }}>
                            {errors?.endDate?.message as any}
                          </Typography>
                        )
                      }
                      disabled={!watch('startDate')}
                      inputProps={{
                        min: moment(watch('startDate')).format('YYYY-MM-DD')
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">Days off from Plan</Typography>
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        fullWidth
                        multiple
                        value={daysOffPlan}
                        onChange={handleChangeDaysOffPlan}>
                        <MenuItem value={'mon'}>Monday</MenuItem>
                        <MenuItem value={'tue'}>Tuesday</MenuItem>
                        <MenuItem value={'wen'}>Wednesday</MenuItem>
                        <MenuItem value={'thu'}>Thursday</MenuItem>
                        <MenuItem value={'fri'}>Friday</MenuItem>
                        <MenuItem value={'sat'}>Saturday</MenuItem>
                        <MenuItem value={'sun'}>Sunday</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">Schedule</Typography>
                    {!isLoading ? (
                      <FormControl fullWidth>
                        <Select
                          size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          fullWidth
                          value={schedule}
                          onChange={handleChangeSchedule}>
                          {scheduler?.map((ele: any) => (
                            <MenuItem value={ele.value} key={ele.value}>
                              {ele.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <Skeleton variant="rectangular" height={'60%'} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Typography variant="body1" sx={{ mb: '7px 0' }}>
                      Priority
                    </Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={planPriority}
                      exclusive
                      fullWidth
                      aria-label="Platform"
                      sx={{ display: 'flex' }}>
                      {planPriority?.map((ele: any, index: number) => (
                        <ToggleButton
                          key={index}
                          value={ele.name}
                          onClick={() => setSelPlanPriority(ele.name)}
                          className={selPlanPriority === ele.name ? 'activeButton' : ''}
                          sx={{
                            backgroundColor: '#D9D9D9',
                            '&:hover': {
                              backgroundColor: '#D9D9D9'
                            },
                            '&.activeButton': {
                              backgroundColor: colors.primary,
                              color: 'white',
                              '& .MuiTypography-root': {
                                color: 'white'
                              }
                            },
                            '&.activeButton:hover': {
                              backgroundColor: colors.primary,
                              color: 'white'
                            },
                            border: 'none',
                            mr: '5px',
                            borderRadius: '10px !important'
                          }}>
                          <Typography
                            variant="body2"
                            sx={{ display: 'flex', textTransform: 'none' }}>
                            <TourIcon
                              fontSize="small"
                              sx={{ mr: 1, color: PriorityColor(ele?.name) }}
                            />{' '}
                            {ele.name}
                          </Typography>
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: { xs: 'unset', sm: 'flex-end' },
                      marginTop: '20px'
                    }}>
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="outlined"
                        fullWidth
                        sx={{
                          color: colors.primary,
                          borderColor: colors.primary,
                          fontWeight: 'bold',
                          '&:hover': { borderColor: `${colors.primary} !important` }
                        }}
                        onClick={() => setOpenCreatePlan(false)}>
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        fullWidth
                        type="submit"
                        sx={{
                          backgroundColor: colors.primary,
                          fontWeight: 'bold',
                          '&:hover': { backgroundColor: `${colors.primary} !important` }
                        }}>
                        Save
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </form>
            </>
          </Card>
        </Dialog>
      ) : (
        <Dialog
          onClose={() => setOpenCreatePlan(false)}
          open={openCreatePlan}
          maxWidth={'md'}
          fullWidth>
          <Card sx={{ padding: '1.5rem 2rem !important', overflowY: 'scroll' }}>
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="body1" sx={{ mb: '7px 0' }}>
                      Plan Title
                    </Typography>
                    <TextField
                      size="small"
                      id="outlined-basic"
                      {...register('plan', {
                        required: 'This field is required.'
                      })}
                      onChange={() => console.log(watch('title'))}
                      helperText={
                        errors.plan && (
                          <Typography variant="caption" sx={{ color: 'red' }}>
                            {errors?.plan?.message as any}
                          </Typography>
                        )
                      }
                      fullWidth
                      variant="outlined"
                      placeholder="Amino acids introduction and further studies"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">Start Date</Typography>
                    <TextField
                      type="date"
                      variant="outlined"
                      fullWidth
                      size="small"
                      {...register('startDate', {
                        required: 'This field is required.'
                      })}
                      onKeyDown={(event: any) => {
                        event.preventDefault();
                      }}
                      helperText={
                        errors.startDate && (
                          <Typography variant="caption" sx={{ color: 'red' }}>
                            {errors?.startDate?.message as any}
                          </Typography>
                        )
                      }
                      inputProps={{
                        min: moment().format('YYYY-MM-DD')
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">Deadline</Typography>
                    <TextField
                      type="date"
                      variant="outlined"
                      fullWidth
                      size="small"
                      {...register('endDate', {
                        required: 'This field is required.'
                      })}
                      onKeyDown={(event: any) => {
                        event.preventDefault();
                      }}
                      helperText={
                        errors.endDate && (
                          <Typography variant="caption" sx={{ color: 'red' }}>
                            {errors?.endDate?.message as any}
                          </Typography>
                        )
                      }
                      disabled={!watch('startDate')}
                      inputProps={{
                        min: moment(watch('startDate')).format('YYYY-MM-DD')
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">Days off from Plan</Typography>
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        fullWidth
                        multiple
                        value={daysOffPlan}
                        onChange={handleChangeDaysOffPlan}>
                        <MenuItem value={'mon'}>Monday</MenuItem>
                        <MenuItem value={'tue'}>Tuesday</MenuItem>
                        <MenuItem value={'wen'}>Wednesday</MenuItem>
                        <MenuItem value={'thu'}>Thursday</MenuItem>
                        <MenuItem value={'fri'}>Friday</MenuItem>
                        <MenuItem value={'sat'}>Saturday</MenuItem>
                        <MenuItem value={'sun'}>Sunday</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">Schedule</Typography>
                    {!isLoading ? (
                      <FormControl fullWidth>
                        <Select
                          size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          fullWidth
                          value={schedule}
                          onChange={handleChangeSchedule}>
                          {scheduler?.map((ele: any) => (
                            <MenuItem value={ele.value} key={ele.value}>
                              {ele.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <Skeleton variant="rectangular" height={'60%'} />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Typography variant="body1" sx={{ mb: '7px 0' }}>
                      Priority
                    </Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={planPriority}
                      exclusive
                      fullWidth
                      aria-label="Platform"
                      sx={{ display: 'flex' }}>
                      {planPriority?.map((ele: any, index: number) => (
                        <ToggleButton
                          key={index}
                          value={ele.name}
                          onClick={() => setSelPlanPriority(ele.name)}
                          className={selPlanPriority === ele.name ? 'activeButton' : ''}
                          sx={{
                            backgroundColor: '#D9D9D9',
                            '&:hover': {
                              backgroundColor: '#D9D9D9'
                            },
                            '&.activeButton': {
                              backgroundColor: colors.primary,
                              color: 'white',
                              '& .MuiTypography-root': {
                                color: 'white'
                              }
                            },
                            '&.activeButton:hover': {
                              backgroundColor: colors.primary,
                              color: 'white'
                            },
                            border: 'none',
                            mr: '5px',
                            borderRadius: '10px !important'
                          }}>
                          <Typography
                            variant="body2"
                            sx={{ display: 'flex', textTransform: 'none' }}>
                            <TourIcon
                              fontSize="small"
                              sx={{ mr: 1, color: PriorityColor(ele?.name) }}
                            />{' '}
                            {ele.name}
                          </Typography>
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: { xs: 'unset', sm: 'flex-end' },
                      marginTop: '20px'
                    }}>
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="outlined"
                        fullWidth
                        sx={{
                          color: colors.primary,
                          borderColor: colors.primary,
                          fontWeight: 'bold',
                          '&:hover': { borderColor: `${colors.primary} !important` }
                        }}
                        onClick={() => setOpenCreatePlan(false)}>
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        fullWidth
                        type="submit"
                        sx={{
                          backgroundColor: colors.primary,
                          fontWeight: 'bold',
                          '&:hover': { backgroundColor: `${colors.primary} !important` }
                        }}>
                        Save
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </form>
            </>
          </Card>
        </Dialog>
      )}
    </>
  );
}

export default CreatePlanPopUp;
