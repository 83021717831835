import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import colors from '../colorConfig/colors';
import google from '../../../assets/image/google.png';
import microsoft from '../../../assets/image/microsoft.png';
import { API_ROUTES, APP_ROUTES } from '../../../utils/constants';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import axios from '../../../utils/axios';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import commonDetailSlice from '../../../store/reducers/commonReducer';
import { setAuth } from '../../../hooks/useRefreshToken';
import { useNavigate } from 'react-router';
import { GoogleLogin } from '@react-oauth/google';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { error } from 'console';
import HttpService from '../../../hooks/Https-services';
import { PublicClientApplication } from '@azure/msal-browser';
import MicrosoftLogin from 'react-microsoft-login';
import { LoadingButton } from '@mui/lab';

const LoginPop = (props: any) => {
  const [userDetail, setUserDetail] = useState({ email: '', password: '' });
  const { open, setOpen } = props;
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isForgot, setIsForgot] = useState(false);
  const [token, setToken] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [verifyToken, setVerifyToken] = useState(false);
  const tempToken = searchParams.get('token');
  const location = useLocation();
  const dispatch = useDispatch();
  async function getEntries(data: any) {
    delete data.tokens;
    dispatch(commonDetailSlice.actions.removecommonDetails());
    dispatch(commonDetailSlice.actions.setcommonDetails(data));
  }
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    unregister,
    formState: { errors }
  } = useForm();
  // const handleReset = async () => {
  //   try {
  //     const pass = watch('password');
  //     if (pass.length < 8 || !pass) {
  //       toast.error('please enter a password');
  //       return;
  //     }
  //     const databody = { password: pass, token: tempToken };
  //     const response = await HttpService.post(API_ROUTES.ResetPassword, databody);
  //     toast.success(response.data.message);
  //     handleClose();
  //   } catch (error: any) {
  //     toast.error(error?.response.data.message);
  //   }
  // };
  const onError = (data: any) => {
    console.log('data', data);
  };
  const onSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      if (!isForgot && !verifyToken) {
        const dataBody = { email: data.email, password: data.password };
        const res = await HttpService.post(API_ROUTES.Login, dataBody);
        if (res.data.data.tokens) {
          toast.success('Login Successful');
          setAuth(res.data.data.tokens);
          getEntries(res.data.data);

          navigate(APP_ROUTES.Onboard);
          setIsSkeleton(false);
        }
      } else if (!verifyToken && isForgot) {
        const databody = { email: data.email };
        const res = await HttpService.post(API_ROUTES.ForgotPassword, databody);
        toast.success(res.data?.message);
        handleClose();
      } else {
        const databody = { password: data.password, token: tempToken };
        const res = await HttpService.post(API_ROUTES.ResetPassword, databody);
        toast.success(res.data?.message);
        handleClose();
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleLoginGoogle = async (credentialResponse: any) => {
    setIsSkeleton(true);
    try {
      const data = {
        token: credentialResponse.credential
      };
      const res = await HttpService.post(API_ROUTES.GoogleAuth, data);
      if (res.data.data.tokens) {
        toast.success('Login Successful');
        setAuth(res.data.data.tokens);
        getEntries(res.data.data);
        navigate(APP_ROUTES.Onboard);
        setIsSkeleton(false);
      }
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };

  // const handleLogin = async () => {
  //   try {
  //     const dataBody = { email: userDetail.email, password: userDetail.password };
  //     const res = await HttpService.post(API_ROUTES.Login, dataBody);
  //     if (res.data.data.tokens) {
  //       toast.success('Login Successful');
  //       setAuth(res.data.data.tokens);

  //       getEntries(res.data.data.user);
  //       navigate(APP_ROUTES.Onboard);
  //       setIsSkeleton(false);
  //     }
  //     setIsSkeleton(false);
  //   } catch (error: any) {
  //     toast.error(error?.response.data.message);
  //   }
  // };
  const handleClose = () => {
    setOpen(false);
    setIsForgot(false);
    setVerifyToken(false);
    searchParams.delete('q');
    searchParams.delete('token');
    setSearchParams(searchParams);
    reset();
  };
  useEffect(() => {
    if (tempToken && location.pathname == '/') {
      setVerifyToken(true);
      setIsForgot(true);
    }
  }, [tempToken]);

  const handleLoginMicrosoft = async (err: any, credentialResponse: any) => {
    setIsSkeleton(true);
    try {
      const data = {
        token: credentialResponse.accessToken
      };
      const res = await HttpService.post(API_ROUTES.MsAuth, data);
      if (res.data.data.tokens) {
        toast.success('Login Successful');
        setAuth(res.data.data.tokens);
        getEntries(res.data.data);
        navigate(APP_ROUTES.Onboard);
        setIsSkeleton(false);
      }
      setIsSkeleton(false);
    } catch (error) {
      console.log(error);
      setIsSkeleton(false);
    }
  };

  // const loginwithmicrosoft = async (err: any, data: any, msal: any) => {
  //   await handleLoginMicrosoft(data);
  //   console.log(data);
  //   console.log('jiji');
  //   console.log(err);
  // };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: 'transparent', // Set the background color to transparent
          boxShadow: 'none', // Remove the box shadow
          borderRadius: '0' // Remove the border radius
        }
      }}>
      <Card
        sx={{
          p: { xs: 3, sm: 4 },
          px: { xs: 3, sm: 6 },
          maxWidth: '416',
          borderRadius: '20px'
        }}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Typography
            variant="h2"
            mb={1}
            fontWeight={700}
            fontSize={{ xs: '2.4rem', md: '2.8rem' }}>
            {!isForgot ? <>Log In</> : <>Forgot password</>}
          </Typography>
          {!verifyToken && (
            <>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  style: { color: 'black' }
                }}
                {...register('email', {
                  required: 'This field is required.',
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: 'Please enter valid email.'
                  }
                })}
                sx={{
                  mt: 2,
                  mb: 2,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'black'
                    }
                  },
                  '& .MuiFormLabel-root': {
                    color: 'black !important'
                  }
                }}
                error={!!errors.email}
                helperText={
                  errors.email && (
                    <Typography variant="caption" sx={{ color: 'red' }}>
                      {errors.email.message as any}
                    </Typography>
                  )
                }
              />
            </>
          )}
          {(!isForgot || verifyToken) && (
            <>
              <TextField
                fullWidth
                type="password"
                label="Password"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  style: { color: 'black' }
                }}
                {...register('password', {
                  required: 'This field is required.',
                  minLength: {
                    value: 8,
                    message: 'Password must be at least 8 characters long.'
                  }
                })}
                sx={{
                  mt: 2,
                  mb: 2,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'black'
                    },
                    '&:hover fieldset': {
                      // borderColor: 'black'
                    },
                    '&.Mui-focused fieldset': {
                      // borderColor: 'black'
                    }
                  },
                  '& .MuiFormLabel-root': {
                    color: `black !important`
                  }
                }}
                error={!!errors.password}
                helperText={
                  errors.password && (
                    <Typography variant="caption" sx={{ color: 'red' }}>
                      {errors?.password?.message as any}
                    </Typography>
                  )
                }
              />
            </>
          )}
          {!isForgot && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Typography
                variant="body1"
                mb={2}
                color={colors.secondary}
                onClick={() => setIsForgot(true)}
                fontSize={{ xs: '0.90rem', md: '1rem' }}
                sx={{ textAlign: 'right', cursor: 'pointer' }}>
                Forgot Password
              </Typography>
            </Box>
          )}

          {isForgot ? (
            verifyToken ? (
              <LoadingButton
                loading={isLoading}
                variant="contained"
                type="submit"
                onClick={() => unregister('email')}
                sx={{
                  fontWeight: '600',
                  fontSize: '1rem',
                  textTransform: 'none'
                }}>
                Reset
              </LoadingButton>
            ) : (
              <LoadingButton
                loading={isLoading}
                variant="contained"
                type="submit"
                sx={{
                  fontWeight: '600',
                  fontSize: '1rem',
                  textTransform: 'none'
                }}>
                Verify
              </LoadingButton>
            )
          ) : (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  type="submit"
                  sx={{
                    fontWeight: '600',
                    fontSize: '1rem',
                    textTransform: 'none'
                  }}>
                  LOG IN
                </LoadingButton>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '98%',
                  margin: '0 auto',
                  my: 2
                }}>
                <Divider sx={{ flexGrow: 1, border: '1px solid black' }} />
                <Typography
                  variant="h6"
                  fontWeight={700}
                  mx={1}
                  fontSize={{
                    xs: 16,
                    md: '1.25rem',
                    textAlign: 'center'
                  }}>
                  OR
                </Typography>
                <Divider sx={{ flexGrow: 1, border: '1px solid black' }} />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 1
                }}>
                <MicrosoftLogin
                  clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID as string}
                  authCallback={handleLoginMicrosoft}
                  // redirectUri="http://localhost:3000"
                  prompt="select_account"
                  className="microsoft-button"
                  useLocalStorageCache={false}
                  children={undefined}
                />
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    handleLoginGoogle(credentialResponse);
                  }}
                  useOneTap
                  size="large"
                  width="215px"
                  containerProps={{
                    style: {
                      border: 'none',
                      width: '100% !important'
                    }
                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Typography
                  variant="body1"
                  onClick={() => {
                    setOpen(false);
                    reset();
                    navigate(`${location.pathname}?q1=true`);
                  }}
                  color={colors.secondary}
                  fontSize="0.9rem"
                  sx={{ textAlign: 'right', mt: 2, cursor: 'pointer' }}>
                  Don’t have an account? Get Started Free
                </Typography>
              </Box>
            </>
          )}
        </form>
      </Card>
    </Dialog>
  );
};

export default LoginPop;
