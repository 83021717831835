import { Box, Divider, Drawer, IconButton, MenuItem, Switch, Typography } from '@mui/material';
import React, { useState } from 'react';
import logo from '../../../assets/image/icon_black.png';
import logo2 from '../../../assets/image/icon.png';
import MenuIcon from '@mui/icons-material/Menu';
import { APP_ROUTES } from '../../../utils/constants';
import { toast } from 'react-toastify';
import commonDetailSlice from '../../../store/reducers/commonReducer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PomorodoTimerPopUp from '../PomorodoTimerPopUp';
import colors from '../colorConfig/colors';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import ImportContactsRoundedIcon from '@mui/icons-material/ImportContactsRounded';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Brightness2Icon from '@mui/icons-material/Brightness2';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { useTimerContext } from '../../../context/timerValue';

const PrivateHeader = () => {
  const { timerValue, setTimerValue }: any = useTimerContext();
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState(null);
  const [pomodoroTimer, setPomodoroTimer] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    localStorage.clear();
    navigate(APP_ROUTES.Landing);
    toast.success('Logout successfully');
    dispatch(commonDetailSlice.actions.removecommonDetails());
  };

  const handleMenuItemClick = (index: any) => {
    setSelectedItem(selectedItem === index ? null : index);
  };
  return (
    <Box sx={{ position: 'sticky', top: 0, zIndex: 999, display: { xs: 'block', md: 'none' } }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 2,
          mb: -2,
          py: 1,
          backgroundColor: 'white',
          borderBottom: '1px solid gray'
        }}>
        <Box component={'img'} src={logo} width={150} />
        <Box>
          <IconButton onClick={handleOpenDrawer}>
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="right" // Position the drawer on the right side
            open={openDrawer}
            onClose={handleCloseDrawer}>
            <Box>
              <Box
                sx={{
                  height: '100vh',
                  width: '200px',
                  backgroundColor: colors.primary,
                  display: 'flex'
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    width: '80%',
                    justifyContent: 'space-between',
                    margin: '0 auto',
                    py: 2
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1
                    }}>
                    <Box>
                      <Box component="img" src={logo2} width={150} />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '1px solid #fff',
                        p: '10px 10px',
                        borderRadius: '5px',
                        mb: 1,
                        cursor: 'pointer'
                      }}
                      onClick={() => setPomodoroTimer(true)}>
                      <PauseCircleOutlineIcon style={{ color: colors.white }} />
                      <Typography color="white" variant="body2">
                        {timerValue}
                      </Typography>
                      <SkipNextIcon style={{ color: colors.white }} />
                    </Box>

                    <PomorodoTimerPopUp
                      pomodoroTimer={pomodoroTimer}
                      setPomodoroTimer={setPomodoroTimer}
                    />
                    <Link to={APP_ROUTES.ScheduleHome} style={{ borderRadius: '6px' }}>
                      <MenuItem
                        selected={location.pathname.includes(APP_ROUTES.ScheduleHome)}
                        sx={{
                          textAlign: 'center',
                          '&:hover': {
                            backgroundColor: `${colors.secondaryLight} !important`
                          },
                          '&.Mui-selected': { backgroundColor: colors.secondaryLight },
                          borderRadius: '6px'
                        }}>
                        <CalendarTodayRoundedIcon sx={{ color: 'white', mr: 1 }} />
                        <Typography color="white" variant="body2">
                          Schedule
                        </Typography>
                      </MenuItem>
                    </Link>
                    <MenuItem
                      selected={selectedItem === 2}
                      onClick={() => handleMenuItemClick(2)}
                      sx={{
                        textAlign: 'center',
                        '&:hover': {
                          backgroundColor: `${colors.secondaryLight} !important`
                        },
                        '&.Mui-selected': { backgroundColor: colors.secondaryLight },
                        borderRadius: '6px'
                      }}>
                      <ImportContactsRoundedIcon sx={{ color: 'white', mr: 1 }} />
                      <Typography color="white" variant="body2">
                        Learn
                      </Typography>
                    </MenuItem>

                    <MenuItem
                      selected={selectedItem === 3}
                      onClick={() => handleMenuItemClick(3)}
                      sx={{
                        textAlign: 'center',
                        '&:hover': {
                          backgroundColor: `${colors.secondaryLight} !important`
                        },
                        '&.Mui-selected': { backgroundColor: colors.secondaryLight },
                        borderRadius: '6px'
                      }}>
                      <FileCopyOutlinedIcon sx={{ color: 'white', mr: 1 }} />
                      <Typography color="white" variant="body2">
                        Retain
                      </Typography>
                    </MenuItem>

                    <MenuItem
                      selected={selectedItem === 4}
                      onClick={() => handleMenuItemClick(4)}
                      sx={{
                        textAlign: 'center',
                        '&:hover': {
                          backgroundColor: `${colors.secondaryLight} !important`
                        },
                        '&.Mui-selected': { backgroundColor: colors.secondaryLight },
                        borderRadius: '6px'
                      }}>
                      <UploadFileOutlinedIcon sx={{ color: 'white', mr: 1 }} />
                      <Typography color="white" variant="body2">
                        Practices
                      </Typography>
                    </MenuItem>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1
                    }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}>
                      <Switch
                        color="primary"
                        // checked={darkMode}
                        // onChange={handleModeChange}
                        icon={<Brightness2Icon />}
                        checkedIcon={<WbSunnyIcon />}
                      />
                    </Box>
                    <Divider color="white" />
                    <MenuItem
                      selected={selectedItem === 5}
                      onClick={() => {
                        handleMenuItemClick(5);
                        navigate(APP_ROUTES.MyProfile);
                      }}
                      sx={{
                        textAlign: 'center',
                        '&:hover': {
                          backgroundColor: `${colors.secondaryLight} !important`
                        },
                        '&.Mui-selected': { backgroundColor: colors.secondaryLight },
                        borderRadius: '6px'
                      }}>
                      <SettingsOutlinedIcon sx={{ color: 'white', mr: 1 }} />
                      <Typography color="white" variant="body2">
                        Setting
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      selected={selectedItem === 6}
                      onClick={handleLogout}
                      sx={{
                        textAlign: 'center',
                        '&:hover': {
                          backgroundColor: `${colors.secondaryLight} !important`
                        },
                        '&.Mui-selected': { backgroundColor: colors.secondaryLight },
                        borderRadius: '6px'
                      }}>
                      <LogoutOutlinedIcon sx={{ color: 'white', mr: 1 }} />
                      <Typography color="white" variant="body2">
                        Logout
                      </Typography>
                    </MenuItem>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Drawer>
        </Box>
      </Box>
    </Box>
  );
};

export default PrivateHeader;
