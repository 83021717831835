import { Box, Divider, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React from 'react';
import colors from '../../components/common/colorConfig/colors.js';
import MyProfile from '../../components/common/MyProfile';
import PlanNBilling from '../../components/common/PlanNBilling';
import CalendarNSchedules from '../../components/common/CalendarNSchedules';
import Pomodoro from '../../components/common/Pomodoro';
import Referrals from '../../components/common/Referrals';
import { APP_ROUTES } from '../../utils/constants.js';
import { useLocation, useNavigate } from 'react-router-dom';

function AccountSetting() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box sx={{ p: '40px 25px 0 25px' }}>
      <Typography
        variant="h4"
        component="h2"
        sx={{ fontWeight: 700, m: '0 0 20px 0', color: 'secondary.main' }}>
        Account Settings
      </Typography>

      <Box sx={{}}>
        <ToggleButtonGroup
          color="primary"
          value={location.pathname}
          exclusive
          size="small"
          aria-label="Platform"
          sx={{
            display: 'flex',
            width: '100%',
            flexWrap: 'wrap',
            gap: 1,
            whiteSpace: 'nowrap'
          }}>
          <ToggleButton
            value="Profile"
            onClick={() => {
              navigate(APP_ROUTES.MyProfile);
            }}
            className={location.pathname === APP_ROUTES.MyProfile ? 'activeButton' : ''}
            sx={{
              backgroundColor: 'transperant',
              '&:hover': {
                backgroundColor: 'transperant'
              },
              '&.activeButton': {
                backgroundColor: colors.secondary,
                color: 'white'
              },
              '&.activeButton:hover': {
                backgroundColor: colors.secondary,
                color: 'white'
              },
              border: 'none',
              mr: '20px',
              borderRadius: '10px !important'
            }}>
            <Typography variant="body2" sx={{ display: 'flex', textTransform: 'none' }}>
              My Profile
            </Typography>
          </ToggleButton>
          <ToggleButton
            value="PlanBill"
            onClick={() => {
              navigate(APP_ROUTES.PlansAndBilling);
            }}
            className={location.pathname === APP_ROUTES.PlansAndBilling ? 'activeButton' : ''}
            sx={{
              backgroundColor: 'transperant',
              '&:hover': {
                backgroundColor: 'transperant'
              },
              '&.activeButton': {
                backgroundColor: colors.secondary,
                color: 'white'
              },
              '&.activeButton:hover': {
                backgroundColor: colors.secondary,
                color: 'white'
              },
              border: 'none',
              mx: '20px',
              borderRadius: '10px !important'
            }}>
            <Typography variant="body2" sx={{ display: 'flex', textTransform: 'none' }}>
              Plan & Billing
            </Typography>
          </ToggleButton>
          <ToggleButton
            value="CalSchedule"
            onClick={() => {
              navigate(APP_ROUTES.CalendarAndSchedules);
            }}
            className={location.pathname === APP_ROUTES.CalendarAndSchedules ? 'activeButton' : ''}
            sx={{
              backgroundColor: 'transperant',
              '&:hover': {
                backgroundColor: 'transperant'
              },
              '&.activeButton': {
                backgroundColor: colors.secondary,
                color: 'white'
              },
              '&.activeButton:hover': {
                backgroundColor: colors.secondary,
                color: 'white'
              },
              border: 'none',
              mx: '20px',
              borderRadius: '10px !important'
            }}>
            <Typography variant="body2" sx={{ display: 'flex', textTransform: 'none' }}>
              Calendar & Schedules
            </Typography>
          </ToggleButton>
          <ToggleButton
            value="Pomodoro"
            onClick={() => {
              navigate(APP_ROUTES.Pomodoro);
            }}
            className={location.pathname === APP_ROUTES.Pomodoro ? 'activeButton' : ''}
            sx={{
              backgroundColor: 'transperant',
              '&:hover': {
                backgroundColor: 'transperant'
              },
              '&.activeButton': {
                backgroundColor: colors.secondary,
                color: 'white'
              },
              '&.activeButton:hover': {
                backgroundColor: colors.secondary,
                color: 'white'
              },
              border: 'none',
              mx: '20px',
              borderRadius: '10px !important'
            }}>
            <Typography variant="body2" sx={{ display: 'flex', textTransform: 'none' }}>
              Pomodoro
            </Typography>
          </ToggleButton>
          <ToggleButton
            value="Referrals"
            onClick={() => {
              navigate(APP_ROUTES.Referrals);
            }}
            className={location.pathname === APP_ROUTES.Referrals ? 'activeButton' : ''}
            sx={{
              backgroundColor: 'transperant',
              '&:hover': {
                backgroundColor: 'transperant'
              },
              '&.activeButton': {
                backgroundColor: colors.secondary,
                color: 'white'
              },
              '&.activeButton:hover': {
                backgroundColor: colors.secondary,
                color: 'white'
              },
              border: 'none',
              ml: '20px',
              borderRadius: '10px !important'
            }}>
            <Typography variant="body2" sx={{ display: 'flex', textTransform: 'none' }}>
              Referrals
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Divider sx={{ m: '15px 0 0 0' }} />
      <Box
        sx={{
          m: '20px 0 0 8px'
        }}>
        {location.pathname === APP_ROUTES.MyProfile ? (
          <MyProfile />
        ) : location.pathname === APP_ROUTES.PlansAndBilling ? (
          <PlanNBilling />
        ) : location.pathname === APP_ROUTES.CalendarAndSchedules ? (
          <CalendarNSchedules />
        ) : location.pathname === APP_ROUTES.Pomodoro ? (
          <Pomodoro />
        ) : location.pathname === APP_ROUTES.Referrals ? (
          <Referrals />
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
}

export default AccountSetting;
