import React, { useEffect, useState } from 'react';
import colors from '../colorConfig/colors.js';
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Checkbox,
  Skeleton
} from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import HttpService from '../../../hooks/Https-services';
import { API_ROUTES, TimeZoneData } from '../../../utils/constants';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import useCommonDetails from '../../../hooks/useCommonDetails';
import { LoadingButton } from '@mui/lab';

const MyProfile = () => {
  const [duration, setDuration] = useState('GMT+05:30');
  const [editPersonal, setEditPersonal] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    formState: { errors }
  } = useForm();
  const handleChangeDuration = (event: SelectChangeEvent) => {
    setDuration(event.target.value as string);
  };
  const getUser = async () => {
    try {
      const res = await HttpService.get(API_ROUTES.GetUserProfile);
      const data = res.data.data;
      console.log('data ::', data);
      setValue('firstName', data?.first_name);
      setValue('lastName', data?.last_name);
      setValue('email', data?.email);
      setValue('phone', data?.phone);
      setDuration(data?.timezone || 'GMT+05:30');
      if (data?.address) {
        setValue('address', data?.address?.address);
        setValue('city', data?.address?.city);
        setValue('pincode', data?.address?.pincode);
        setValue('state', data?.address?.state);
      }
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const onSubmit = async (data: any) => {
    try {
      const dataBody = {
        first_name: data.firstName,
        last_name: data?.lastName,
        address: {
          address: data?.address,
          city: data?.city,
          pincode: data?.pincode,
          state: data?.state
        },
        phone: data?.phone || '',
        timezone: duration
      };
      setIsLoading2(true);
      const respone = await HttpService.patch(API_ROUTES.UpdateUserProfile, dataBody);
      setEditAddress(false);
      setEditPersonal(false);
      toast.success('Profile updated successfully');
      setIsLoading2(false);
    } catch (error: any) {
      setIsLoading2(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getUser();
  }, []);
  return (
    <Box sx={{ maxWidth: '600px', whiteSpace: 'wrap' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {editPersonal && (
          <Grid
            container
            sx={{
              mb: 2,
              border: `1px solid ${colors.darkGrey}`,
              borderRadius: '10px',
              padding: '10px 25px'
            }}>
            <Box
              sx={{
                borderRadius: '10px',
                padding: '10px 25px',
                width: '100%'
              }}>
              <Grid container spacing={4}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                  }}>
                  <Typography variant="h6" sx={{ fontWeight: 400 }}>
                    Personal Information
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                  <Typography variant="body2" sx={{ fontWeight: 400, color: colors.darkGrey }}>
                    First Name
                  </Typography>
                  <TextField
                    sx={{ fontWeight: 500, mt: '6px' }}
                    size="small"
                    id="outlined-basic"
                    fullWidth
                    variant="outlined"
                    placeholder="First Name"
                    {...register('firstName', {
                      required: 'This field is required'
                    })}
                    error={!!errors.firstName}
                    helperText={
                      errors.firstName && (
                        <Typography variant="caption" sx={{ color: 'red' }}>
                          {errors.firstName.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                  <Typography variant="body2" sx={{ fontWeight: 400, color: colors.darkGrey }}>
                    Last Name
                  </Typography>
                  <TextField
                    sx={{ fontWeight: 500, mt: '6px' }}
                    size="small"
                    id="outlined-basic"
                    fullWidth
                    variant="outlined"
                    placeholder="Last Name"
                    {...register('lastName', {
                      required: false
                    })}
                    // error={!!errors.lastName}
                    // helperText={
                    //   errors.lastName && (
                    //     <Typography variant="caption" sx={{ color: 'red' }}>
                    //       {errors.lastName.message as any}
                    //     </Typography>
                    //   )
                    // }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                  <Typography variant="body2" sx={{ fontWeight: 400, color: colors.darkGrey }}>
                    Email address
                  </Typography>
                  <TextField
                    sx={{ fontWeight: 500, mt: '6px' }}
                    size="small"
                    id="outlined-basic"
                    fullWidth
                    variant="outlined"
                    disabled
                    placeholder="Email address"
                    {...register('email')}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                  <Typography variant="body2" sx={{ fontWeight: 400, color: colors.darkGrey }}>
                    Phone
                  </Typography>
                  <TextField
                    type="number"
                    sx={{ fontWeight: 500, mt: '6px' }}
                    size="small"
                    id="outlined-basic"
                    fullWidth
                    variant="outlined"
                    placeholder="Phone"
                    {...register('phone', {
                      maxLength: {
                        value: 15,
                        message: 'Please enter a valid phone number'
                      },
                      minLength: {
                        value: 7,
                        message: 'Please enter a valid phone number'
                      }
                    })}
                    error={!!errors.phone}
                    helperText={
                      errors.phone && (
                        <Typography variant="caption" sx={{ color: 'red' }}>
                          {errors.phone.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                  <LoadingButton
                    loading={isLoading2}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    size="small"
                    sx={{ borderRadius: '7px' }}>
                    Save
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )}
        {editAddress && (
          <Grid
            container
            sx={{
              mb: '40px',
              mt: 3,
              border: `1px solid ${colors.darkGrey}`,
              borderRadius: '10px',
              padding: '10px 25px'
            }}>
            <Box
              sx={{
                borderRadius: '10px',
                padding: '10px 25px',
                width: '100%'
              }}>
              <Grid container spacing={4}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                  }}>
                  <Typography variant="h6" sx={{ fontWeight: 400 }}>
                    Address
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                  <Typography variant="body2" sx={{ fontWeight: 400, color: colors.darkGrey }}>
                    Street Address
                  </Typography>
                  <TextField
                    sx={{ fontWeight: 500, mt: '6px' }}
                    size="small"
                    id="outlined-basic"
                    fullWidth
                    variant="outlined"
                    placeholder="Street Address"
                    {...register('address')}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                  <Typography variant="body2" sx={{ fontWeight: 400, color: colors.darkGrey }}>
                    City
                  </Typography>
                  <TextField
                    sx={{ fontWeight: 500, mt: '6px' }}
                    size="small"
                    id="outlined-basic"
                    fullWidth
                    variant="outlined"
                    placeholder="City"
                    {...register('city')}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                  <Typography variant="body2" sx={{ fontWeight: 400, color: colors.darkGrey }}>
                    Province/ State
                  </Typography>
                  <TextField
                    sx={{ fontWeight: 500, mt: '6px' }}
                    size="small"
                    id="outlined-basic"
                    fullWidth
                    variant="outlined"
                    placeholder="Province/ State"
                    {...register('state')}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                  <Typography variant="body2" sx={{ fontWeight: 400, color: colors.darkGrey }}>
                    Postal/ Zip Code
                  </Typography>
                  <TextField
                    sx={{ fontWeight: 500, mt: '6px' }}
                    size="small"
                    id="outlined-basic"
                    fullWidth
                    variant="outlined"
                    placeholder="Postal/ Zip Code"
                    {...register('pincode')}
                  />
                </Grid>
                <Grid item xs={12} sm={12} sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                  <LoadingButton
                    loading={isLoading2}
                    type="submit"
                    color="secondary"
                    variant="contained"
                    size="small"
                    sx={{ borderRadius: '7px' }}>
                    Save
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )}
        {!editPersonal && (
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}>
              <Typography variant="h6" sx={{ fontWeight: 400 }}>
                Personal Information
              </Typography>
              <Button
                variant="outlined"
                size="small"
                sx={{ borderRadius: '7px' }}
                onClick={() => {
                  setEditPersonal(true);
                  setEditAddress(false);
                }}>
                Edit <BorderColorIcon fontSize="small" sx={{ ml: '5px' }} />
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
              <Typography variant="body2" sx={{ fontWeight: 400, color: colors.darkGrey }}>
                First Name
              </Typography>
              {!isLoading ? (
                <Typography variant="body1" sx={{ fontWeight: 500, mt: '6px' }}>
                  {watch('firstName') || '-'}
                </Typography>
              ) : (
                <Skeleton variant="text" width={'100%'} height={40} />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
              <Typography variant="body2" sx={{ fontWeight: 400, color: colors.darkGrey }}>
                Last Name
              </Typography>
              {!isLoading ? (
                <Typography variant="body1" sx={{ fontWeight: 500, mt: '6px' }}>
                  {watch('lastName') || '-'}
                </Typography>
              ) : (
                <Skeleton variant="text" width={'100%'} height={40} />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
              <Typography variant="body2" sx={{ fontWeight: 400, color: colors.darkGrey }}>
                Email address
              </Typography>
              {!isLoading ? (
                <Typography variant="body1" sx={{ fontWeight: 500, mt: '6px' }}>
                  {watch('email') || '-'}
                </Typography>
              ) : (
                <Skeleton variant="text" width={'100%'} height={40} />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
              <Typography variant="body2" sx={{ fontWeight: 400, color: colors.darkGrey }}>
                Phone
              </Typography>
              {!isLoading ? (
                <Typography variant="body1" sx={{ fontWeight: 500, mt: '6px' }}>
                  {watch('phone') || '-'}
                </Typography>
              ) : (
                <Skeleton variant="text" width={'100%'} height={40} />
              )}
            </Grid>
          </Grid>
        )}

        {!editAddress && (
          <Grid container spacing={4} sx={{ mt: '30px' }}>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}>
              <Typography variant="h6" sx={{ fontWeight: 400 }}>
                Address
              </Typography>
              <Button
                variant="outlined"
                size="small"
                sx={{ borderRadius: '7px' }}
                onClick={() => {
                  setEditPersonal(false);
                  setEditAddress(true);
                }}>
                Edit <BorderColorIcon fontSize="small" sx={{ ml: '5px' }} />
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
              <Typography variant="body2" sx={{ fontWeight: 400, color: colors.darkGrey }}>
                Street Address
              </Typography>
              {!isLoading ? (
                <Typography variant="body1" sx={{ fontWeight: 500, mt: '6px' }}>
                  {watch('address') || '-'}
                </Typography>
              ) : (
                <Skeleton variant="text" width={'100%'} height={40} />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
              <Typography variant="body2" sx={{ fontWeight: 400, color: colors.darkGrey }}>
                City
              </Typography>
              {!isLoading ? (
                <Typography variant="body1" sx={{ fontWeight: 500, mt: '6px' }}>
                  {watch('city') || '-'}
                </Typography>
              ) : (
                <Skeleton variant="text" width={'100%'} height={40} />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
              <Typography variant="body2" sx={{ fontWeight: 400, color: colors.darkGrey }}>
                Province/ State
              </Typography>
              {!isLoading ? (
                <Typography variant="body1" sx={{ fontWeight: 500, mt: '6px' }}>
                  {watch('state') || '-'}
                </Typography>
              ) : (
                <Skeleton variant="text" width={'100%'} height={40} />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
              <Typography variant="body2" sx={{ fontWeight: 400, color: colors.darkGrey }}>
                Postal/ Zip Code
              </Typography>
              {!isLoading ? (
                <Typography variant="body1" sx={{ fontWeight: 500, mt: '6px' }}>
                  {watch('pincode') || '-'}
                </Typography>
              ) : (
                <Skeleton variant="text" width={'100%'} height={40} />
              )}
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2} sx={{ mt: '30px' }}>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}>
            <Typography variant="h6" sx={{ fontWeight: 400 }}>
              Time Zone
            </Typography>
            <LoadingButton
              loading={isLoading2}
              type="submit"
              color="secondary"
              variant="contained"
              size="small"
              sx={{ borderRadius: '7px' }}>
              Save
            </LoadingButton>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <Typography variant="body2" sx={{ fontWeight: 400, color: colors.darkGrey, mb: '6px' }}>
              Primary Time Zone
            </Typography>
            <FormControl>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                value={duration}
                onChange={handleChangeDuration}>
                {TimeZoneData.map((ele) => (
                  <MenuItem value={ele.offset} key={ele.offset}>
                    {ele.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Checkbox />
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                Ask to update time zone to current location
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default MyProfile;
