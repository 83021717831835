import { createTheme } from '@mui/material';
import { red } from '@mui/material/colors';

// const colors = {
//   primary: '#06C7BB',
//   secondary: '#06C7BB',
//   light: '#484848',
//   lighter: '#F6F6F6',
//   white: '#464646',
//   black: '#000000'
// };
// Create a theme instance.
let theme = createTheme({
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 567,
  //     md: 768,
  //     lg: 992,
  //     xl: 1440
  //   }
  // },
  palette: {
    common: {
      black: '#000000',
      white: '#464646',
      grey: '#D9D9D9',
      darkBlue: '#003260',
      lightBlue: '#D9EDFF',
      pageBackground: '#FBFBFF',
      green: '#479706',
      orange: '#FBBF24',
      borderColor: '#2f2f2f'
    },

    primary: {
      light: '#4A3AFF',
      main: '#06C7BB',
      dark: '#06C7BB',
      contrastText: '#fff'
    },
    secondary: {
      light: '#067ae6',
      main: '#08283D',
      dark: '#08283D',
      contrastText: '#fff'
    },
    text: {
      primary: '#464646'
    },
    error: {
      main: red.A700
    }
  },
  typography: {
    fontFamily: 'Inter',
    color: '#464646',
    lineHeight: 1.3,
    fontWeightRegular: '400',
    fontWeightMedium: '500',
    fontWeightSemibold: '600',
    fontWeightBold: '700'
  }
});

theme = createTheme({
  ...theme,

  typography: {
    h1: {
      fontFamily: 'Inter',
      fontWeight: '600',
      fontSize: '32px',
      lineHeight: '48px',
      letterSpacing: 'normal',
      color: '#464646'
    },
    h2: {
      fontFamily: 'Inter',
      fontWeight: '500',
      fontSize: '24px',
      color: '#464646',
      lineHeight: '65px',
      letterSpacing: 'normal'
    },
    h3: {
      fontFamily: 'Inter',
      fontWeight: '500',
      fontSize: '22px',
      lineHeight: '55px',
      letterSpacing: 'normal',
      color: '#464646',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px',
        lineHeight: '48px'
      }
    },
    subtitle1: {
      fontFamily: 'Inter',
      fontWeight: '500',
      fontSize: '24px',
      lineHeight: '28px',
      letterSpacing: 'normal',
      color: '#464646'
    },
    subtitle2: {
      fontFamily: 'Inter',
      fontWeight: '400',
      fontSize: '18px',
      lineHeight: '22px',
      letterSpacing: 'normal',
      color: '#464646'
    },
    body1: {
      fontFamily: 'Inter',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#464646'
    },
    body2: {
      fontFamily: 'Inter',
      fontWeight: '400',
      fontSize: '14px',
      color: '#464646'
    },
    button: {
      fontFamily: 'Inter',
      fontWeight: '400'
    },

    caption: {
      fontFamily: 'Inter',
      fontWeight: '400',
      fontSize: '12px',
      color: '#464646'
    },
    overline: {
      fontFamily: 'Inter',
      fontWeight: '400',
      color: '#464646'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          borderRadius: '2px'
        }
      }
    },

    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none'
        }
      }
    }
    // MuiToggleButton: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: '#D9D9D9',
    //       '&:hover': {
    //         backgroundColor: '#D9D9D9'
    //       },
    //       '&.activeButton': {
    //         backgroundColor: 'primary.main',
    //         color: 'white',
    //         '& .MuiTypography-root': {
    //           color: 'white' // Apply white color to the Typography component inside ToggleButton
    //         }
    //       },
    //       '&.activeButton:hover': {
    //         backgroundColor: 'primary.main',
    //         color: 'white'
    //       },
    //       border: 'none',
    //       mr: '5px',
    //       borderRadius: '10px !important'
    //     }
    //   }
    // }
  }
});

export default theme;
