import {
  Box,
  Button,
  Card,
  Dialog,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import colors from '../colorConfig/colors.js';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CachedIcon from '@mui/icons-material/Cached';
import TourIcon from '@mui/icons-material/Tour';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useForm } from 'react-hook-form';
import HttpService from '../../../hooks/Https-services';
import { API_ROUTES, PriorityColor } from '../../../utils/constants';
import moment from 'moment';
import { toast } from 'react-toastify';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import AddIcon from '@mui/icons-material/Add';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSearchParams } from 'react-router-dom';
import './style.css';

const AddTaskPopUp = (props: any) => {
  const {
    isEditTask,
    datas,
    getPlans,
    getAllEvents,
    selectedDateTime,
    isOtherComponentVisible,
    setIsOtherComponentVisible
  } = props;

  const [showDialog, setShowDialog] = useState(false);
  const [alignment, setAlignment] = React.useState('One-Time');

  const [startTimes, setStartTimes] = React.useState<any>(dayjs('2022-04-17T00:00:00'));
  const [endTimes, setEndTimes] = React.useState<any>(dayjs('2022-04-17T00:00:00'));
  const [recStartTimes, setRecStartTimes] = React.useState<any>(dayjs('2022-04-17T00:00:00'));
  const [recEndTimes, setRecEndTimes] = React.useState<any>(dayjs('2022-04-17T00:00:00'));

  const [duration, setDuration] = React.useState('1');
  const [chunks, setChunks] = React.useState('15');
  const [blocker, setBlocker] = useState<any>([]);
  const [taskPlan, setTaskPlan] = useState<any>([]);
  const [taskGroup, setTaskGroup] = useState<any>([]);
  const [taskSubGroup, setTaskSubGroup] = useState<any>([]);
  const [daysOffPlan, setDaysOffPlan] = useState<any>(['mon']);
  const [checkedList, setCheckedList] = React.useState(true);
  const [checkedChunk, setCheckedChunk] = React.useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [planPriority, setPlanPriority] = useState<any>(null);
  const [schedule, setSchedule] = React.useState('');
  const [scheduler, setScheduler] = useState<any>(null);
  const [allPlan, setAllPlan] = useState<any>(null);
  const [allGroup, setAllGroup] = useState<any>(null);
  const [allSubGroup, setAllSubGroup] = useState<any>(null);
  const [selPlanPriority, setSelPlanPriority] = useState('Low');
  const [deadline, setDeadline] = useState<any>(null);
  const [selDeadline, setSelDeadline] = useState('Hard deadline');
  const [selDays, setSelDays] = useState('daily');
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    unregister,
    formState: { errors }
  } = useForm();

  const handleChangeLink = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedList(event.target.checked);
  };

  const handleChangeChunk = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedChunk(event.target.checked);
  };

  const handleChangeDuration = (event: SelectChangeEvent) => {
    setDuration(event.target.value as string);
  };
  const handleChangeChunks = (event: SelectChangeEvent) => {
    setChunks(event.target.value as string);
  };

  const handleChangeBlocker = (event: SelectChangeEvent) => {
    const {
      target: { value }
    } = event;
    setBlocker(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChangeSchedule = (event: SelectChangeEvent) => {
    // const {
    //   target: { value }
    // } = event;
    // setSchedule(typeof value === 'string' ? value.split(',') : value);
    setSchedule(event.target.value as string);
  };

  const handleTaskPlan = (event: SelectChangeEvent) => {
    // const {
    //   target: { value }
    // } = event;
    // setTaskPlan(typeof value === 'string' ? value.split(',') : value);
    setTaskPlan(event.target.value as string);
    setTaskGroup(null);
    setAllGroup(null);
    getAllGroup(event.target.value as any);
  };

  const handleTaskGroup = (event: SelectChangeEvent) => {
    // const {
    //   target: { value }
    // } = event;
    // setTaskGroup(typeof value === 'string' ? value.split(',') : value);
    setTaskGroup(event.target.value as string);
    setTaskSubGroup(null);
    setAllSubGroup(null);
    getAllSubGroup(event.target.value as any);
  };

  const handleTaskSubGroup = (event: SelectChangeEvent) => {
    setTaskSubGroup(event.target.value as string);
  };

  const [params, setParams] = useSearchParams();

  useEffect(() => {
    if (params.get('id')) {
      if (datas?.id == params.get('id')) {
        setShowDialog(true);
      } else {
        params.delete('id');
        setParams(params);
      }
    }
  }, [params]);

  const handleChangeDaysOffPlan = (event: SelectChangeEvent) => {
    const {
      target: { value }
    } = event;
    setDaysOffPlan(typeof value === 'string' ? value.split(',') : value);
  };
  const handleChangeFrequency = (event: SelectChangeEvent) => {
    const {
      target: { value }
    } = event;
    setSelDays(value);
  };

  const getAllRes = async () => {
    try {
      setIsLoading(true);

      const AllSchedules = await HttpService.get(API_ROUTES.GetAllSchedules);
      setScheduler(AllSchedules.data.data);

      const AllPlan = await HttpService.get(API_ROUTES.GetAllPlans);
      setAllPlan(AllPlan.data.data);

      const PriorityID = 1;
      const Priority = await HttpService.get(
        API_ROUTES.ApplicationObject.replace(':id', `${PriorityID}`)
      );
      setPlanPriority(Priority.data.data);

      const DeadlineID = 2;
      const Deadline = await HttpService.get(
        API_ROUTES.ApplicationObject.replace(':id', `${DeadlineID}`)
      );
      setDeadline(Deadline.data.data);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getAllGroup = async (planId: number) => {
    try {
      const AllGroup = await HttpService.get(API_ROUTES.GetAllGroups.replace(':id', `${planId}`));
      setAllGroup(AllGroup.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllSubGroup = async (groupId: number) => {
    try {
      const AllSubGroup = await HttpService.get(
        API_ROUTES.GetAllSubGroups.replace(':id', `${groupId}`)
      );

      setAllSubGroup(AllSubGroup.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitTask = async (data: any) => {
    try {
      let dataBody: any = {
        task_type: alignment,
        task_title: data.title,
        task_description: data.desc,
        duration: duration,
        link_type: checkedList,
        web_link: data.link,
        priority: selPlanPriority,
        allow_split: checkedChunk,
        chunk_duration: chunks,
        start_date: data.startDate,
        plan_id: taskPlan > 0 ? String(taskPlan) : null,
        group_id: taskGroup > 0 ? String(taskGroup) : null,
        subgroup_id: taskSubGroup > 0 ? String(taskSubGroup) : null
      };
      if (alignment == 'One-Time') {
        dataBody = {
          ...dataBody,
          blocker: [],
          deadline_type: selDeadline,
          schedule_id: String(schedule),
          // deadline_time: data.time,
          // one_time_task_start_time: data.startTime,
          // one_time_task_start_time: `${startTimes.$H}:${startTimes.$m}`,
          // one_time_task_end_time: data.endTime,
          // one_time_task_end_time: `${endTimes.$H}:${endTimes.$m}`,
          deadline_date: data.endDate,
          specific_days: daysOffPlan
        };
      } else if (alignment == 'Recurring') {
        dataBody = {
          ...dataBody,
          specific_days: daysOffPlan,
          // recurring_initial_time: data.recInitTime,
          // recurring_end_time: data.recEndTime
          recurring_initial_time: `${recStartTimes.$H}:${recStartTimes.$m}`,
          recurring_end_time: `${recEndTimes.$H}:${recEndTimes.$m}`,
          frequency: selDays
        };
        if (selDays != 'daily') {
          dataBody.frequency = selDays;
        }
      }
      await HttpService.post(API_ROUTES.CreateTask, dataBody);
      reset();
      getAllEvents();
      toast.success('Task created successfully');
      setAllPlan(null);
      setAllGroup(null);
      setAllSubGroup(null);
      setShowDialog(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onEditTask = async (data: any) => {
    try {
      let dataBody: any = {
        task_type: alignment,
        task_title: data.title,
        task_description: data.desc,
        duration: duration,
        link_type: checkedList,
        web_link: data.link,
        priority: selPlanPriority,
        allow_split: checkedChunk,
        chunk_duration: chunks,
        start_date: data.startDate,
        plan_id: taskPlan > 0 ? String(taskPlan) : null,
        group_id: taskGroup > 0 ? String(taskGroup) : null,
        subgroup_id: taskSubGroup > 0 ? String(taskSubGroup) : null,
        schedule_id: String(schedule)
      };

      if (alignment == 'One-Time') {
        dataBody = {
          ...dataBody,
          blocker: [],
          deadline_type: selDeadline,
          // deadline_time: data.time,
          // one_time_task_start_time: data.startTime,
          // one_time_task_start_time: `${startTimes.$H}:${startTimes.$m}`,
          // one_time_task_end_time: data.endTime,
          // one_time_task_end_time: `${endTimes.$H}:${endTimes.$m}`,
          deadline_date: data.endDate,
          specific_days: daysOffPlan
        };
      } else if (alignment == 'Recurring') {
        dataBody = {
          ...dataBody,
          specific_days: daysOffPlan,
          blocker: [],
          // recurring_initial_time: data.recInitTime,
          // recurring_end_time: data.recEndTime
          recurring_initial_time: `${recStartTimes.$H}:${recStartTimes.$m}`,
          recurring_end_time: `${recEndTimes.$H}:${recEndTimes.$m}`
        };
      }
      await HttpService.patch(
        API_ROUTES.UpdateTask.replace(':id', `${datas.user_task_sid}`),
        dataBody
      );
      reset();
      toast.success('Task updated successfully');
      setAllPlan(null);
      setAllGroup(null);
      setAllSubGroup(null);
      setShowDialog(false);
      getPlans();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (showDialog) {
      getAllRes();

      if (isEditTask) {
        setAlignment(datas?.task_type);
        setValue('title', datas?.task_title);
        setValue('desc', datas?.task_description);
        setValue('desc', datas?.task_description);
        setDuration(datas?.duration);
        setCheckedList(datas?.link_type == 1 ? true : false);
        setValue('link', datas?.web_link);
        setCheckedChunk(datas?.allow_split == 1 ? true : false);
        setChunks(datas?.chunk_duration);
        setSelPlanPriority(datas?.priority);
        setValue('startDate', datas?.start_date);
        setSchedule(datas?.schedule_id);
        setBlocker(datas?.blocker);
        setSelDeadline(datas?.deadline_type);
        // setValue('time', moment(datas?.deadline_time, 'HH:mm:ss').format('HH:mm'));
        // setValue('startTime', moment(datas?.one_time_task_start_time, 'HH:mm:ss').format('HH:mm'));
        // setStartTimes(dayjs(`2022-04-17T${datas?.one_time_task_start_time}`));
        // setValue('endTime', moment(datas?.one_time_task_end_time, 'HH:mm:ss').format('HH:mm'));
        // setEndTimes(dayjs(`2022-04-17T${datas?.one_time_task_end_time}`));
        setValue('endDate', moment(datas?.deadline_date).format('YYYY-MM-DD'));
        setDaysOffPlan(datas?.specific_days);
        // setValue('recInitTime', moment(datas?.recurring_initial_time, 'HH:mm:ss').format('HH:mm'));
        // setValue('recEndTime', moment(datas?.recurring_end_time, 'HH:mm:ss').format('HH:mm'));

        setRecStartTimes(dayjs(`2022-04-17T${datas?.recurring_initial_time}`));
        setRecEndTimes(dayjs(`2022-04-17T${datas?.recurring_end_time}`));

        setTaskPlan(datas?.plan_id);
        setTaskGroup(datas?.group_id);
        setTaskSubGroup(datas?.subgroup_id);
        getAllGroup(datas?.plan_id);
        getAllSubGroup(datas?.group_id);
      }
    }
  }, [showDialog]);

  useEffect(() => {
    if (isOtherComponentVisible && selectedDateTime) {
      setShowDialog(true);

      // Convert the selected start time to UTC
      const startTime = moment(selectedDateTime.startTime).utc();

      // Convert the selected end time to UTC
      const endTime = moment(selectedDateTime.endTime).utc();

      // Format the converted start and end times as ISO 8601 strings
      const convertedStart = startTime.toISOString();
      const convertedEnd = endTime.toISOString();

      setStartTimes(dayjs(convertedStart));
      setRecStartTimes(dayjs(convertedStart));
      setEndTimes(dayjs(convertedEnd));
      setRecEndTimes(dayjs(convertedEnd));

      setValue('startDate', moment(convertedStart).format('YYYY-MM-DD'));
    } else {
      if (!params.get('id')) setShowDialog(false);
    }
  }, [isOtherComponentVisible, selectedDateTime]);

  return (
    <>
      {isEditTask ? (
        <ModeEditOutlineIcon
          fontSize="small"
          sx={{ m: '0 10px 0 20px' }}
          onClick={() => setShowDialog(true)}
        />
      ) : isOtherComponentVisible ? (
        <></>
      ) : (
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          sx={{ borderRadius: '7px' }}
          onClick={() => {
            setShowDialog(true);
          }}>
          <AddIcon fontSize="small" sx={{ mr: '5px' }} />
          Add Task
        </Button>
      )}

      {!isEditTask ? (
        <Dialog
          onClose={() => {
            reset();
            setAllPlan(null);
            setAllGroup(null);
            setAllSubGroup(null);
            setShowDialog(false);
            // setIsOtherComponentVisible(false);
          }}
          open={showDialog}
          maxWidth={'md'}
          fullWidth>
          <form onSubmit={handleSubmit(onSubmitTask)}>
            <Card sx={{ padding: '1.5rem 2rem !important', overflowY: 'scroll' }}>
              <Grid container spacing={1.5}>
                <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    aria-label="Platform">
                    <ToggleButton
                      value="One-Time"
                      onClick={() => setAlignment('One-Time')}
                      className={alignment === 'One-Time' ? 'activeButton' : ''}
                      sx={{
                        '&.activeButton': {
                          backgroundColor: colors.primary,
                          color: 'white',
                          '& .MuiTypography-root': {
                            color: 'white'
                          }
                        },
                        '&.activeButton:hover': {
                          backgroundColor: colors.primary,
                          color: 'white'
                        },
                        borderRadius: '10px'
                      }}>
                      <Typography variant="body2" sx={{ display: 'flex', textTransform: 'none' }}>
                        <TaskAltIcon fontSize="small" sx={{ mr: 1 }} /> One-Time
                      </Typography>
                    </ToggleButton>
                    <ToggleButton
                      value="Recurring"
                      onClick={() => setAlignment('Recurring')}
                      className={alignment === 'Recurring' ? 'activeButton' : ''}
                      sx={{
                        '&.activeButton': {
                          backgroundColor: colors.primary,
                          color: 'white',
                          '& .MuiTypography-root': {
                            color: 'white'
                          }
                        },
                        '&.activeButton:hover': {
                          backgroundColor: colors.primary,
                          color: 'white'
                        },
                        borderRadius: '10px'
                      }}>
                      <Typography variant="body2" sx={{ display: 'flex', textTransform: 'none' }}>
                        <CachedIcon fontSize="small" sx={{ mr: 1 }} /> Recurring
                      </Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body1" sx={{ mb: '7px 0' }}>
                    Task Title
                  </Typography>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    fullWidth
                    variant="outlined"
                    placeholder="Amino acids introduction and further studies"
                    {...register('title', {
                      required: 'This field is required.'
                    })}
                    helperText={
                      errors.title && (
                        <Typography variant="caption" sx={{ color: 'red' }}>
                          {errors?.title?.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body1" sx={{ mb: '7px 0' }}>
                    Task Description
                  </Typography>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    fullWidth
                    variant="outlined"
                    placeholder="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eveniet ex voluptatibus, corporis optio nam repellendus?"
                    {...register('desc', {
                      required: 'This field is required.'
                    })}
                    helperText={
                      errors.desc && (
                        <Typography variant="caption" sx={{ color: 'red' }}>
                          {errors?.desc?.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" mb={0.5}>
                    Duration
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      fullWidth
                      value={duration}
                      onChange={handleChangeDuration}>
                      <MenuItem value={'1'}>1 Hour</MenuItem>
                      <MenuItem value={'2'}>2 Hour</MenuItem>
                      <MenuItem value={'3'}>3 Hour</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      variant="body1"
                      sx={{ color: !checkedList ? colors.secondary : '#000' }}>
                      External Link
                    </Typography>
                    <Switch
                      size="small"
                      checked={checkedList}
                      onChange={handleChangeLink}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography
                      variant="body1"
                      sx={{ color: !checkedList ? '#000' : colors.secondary }}>
                      Internal Link
                    </Typography>
                  </Box>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    fullWidth
                    variant="outlined"
                    placeholder="https://AAMCmaterial.com"
                    {...register('link', {
                      required: 'This field is required.'
                    })}
                    helperText={
                      errors.link && (
                        <Typography variant="caption" sx={{ color: 'red' }}>
                          {errors?.link?.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={10} sx={{ display: 'flex' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      backgroundColor: '#D9D9D9',
                      padding: '8px',
                      borderRadius: '10px'
                    }}>
                    <Typography variant="body1">Allow split into chunks</Typography>
                    <Switch
                      // size="small"
                      checked={checkedChunk}
                      onChange={handleChangeChunk}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography variant="body1">Minimum chunk duration</Typography>
                    <FormControl>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={chunks}
                        onChange={handleChangeChunks}
                        sx={{ width: '100px', ml: '10px', background: 'white' }}>
                        <MenuItem value={'15'}>15 min</MenuItem>
                        <MenuItem value={'25'}>25 min</MenuItem>
                        <MenuItem value={'35'}>35 min</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} sx={{ overflowX: 'auto' }}>
                  <Typography variant="body1" sx={{ mb: '7px 0' }}>
                    Priority
                  </Typography>
                  <ToggleButtonGroup
                    color="primary"
                    value={planPriority}
                    exclusive
                    fullWidth
                    aria-label="Platform"
                    sx={{ display: 'flex' }}>
                    {planPriority?.map((ele: any, index: number) => (
                      <ToggleButton
                        key={index}
                        value={ele.name}
                        onClick={() => setSelPlanPriority(ele.name)}
                        className={selPlanPriority === ele.name ? 'activeButton' : ''}
                        sx={{
                          backgroundColor: '#D9D9D9',
                          '&:hover': {
                            backgroundColor: '#D9D9D9'
                          },
                          '&.activeButton': {
                            backgroundColor: colors.primary,
                            color: 'white',
                            '& .MuiTypography-root': {
                              color: 'white'
                            }
                          },
                          '&.activeButton:hover': {
                            backgroundColor: colors.primary,
                            color: 'white'
                          },
                          border: 'none',
                          mr: '5px',
                          borderRadius: '10px !important'
                        }}>
                        <Typography variant="body2" sx={{ display: 'flex', textTransform: 'none' }}>
                          <TourIcon
                            fontSize="small"
                            sx={{ mr: 1, color: PriorityColor(ele.name) }}
                          />{' '}
                          {ele.name}
                        </Typography>
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} />
                {alignment === 'One-Time' ? (
                  <>
                    <Grid item xs={6}>
                      <Typography variant="body1">Start Date</Typography>
                      <TextField
                        type="date"
                        variant="outlined"
                        fullWidth
                        size="small"
                        {...register('startDate', {
                          required: 'This field is required.'
                        })}
                        onKeyDown={(event: any) => {
                          event.preventDefault();
                        }}
                        helperText={
                          errors.startDate && (
                            <Typography variant="caption" sx={{ color: 'red' }}>
                              {errors?.startDate?.message as any}
                            </Typography>
                          )
                        }
                        inputProps={{
                          min: moment().format('YYYY-MM-DD')
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Schedule</Typography>
                      {!isLoading ? (
                        <FormControl fullWidth>
                          <Select
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            fullWidth
                            value={schedule}
                            onChange={handleChangeSchedule}>
                            {scheduler?.map((ele: any) => (
                              <MenuItem value={ele.value} key={ele.value}>
                                {ele.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <Skeleton variant="rectangular" height={'60%'} />
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Blocker</Typography>
                      <FormControl fullWidth>
                        <Select
                          size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          fullWidth
                          value={blocker}
                          onChange={handleChangeBlocker}>
                          <MenuItem value={'T1'}>Task 1</MenuItem>
                          <MenuItem value={'T2'}>Task 2</MenuItem>
                          <MenuItem value={'T3'}>Task 3</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1" sx={{ mb: '7px 0' }}>
                        Deadline Type
                      </Typography>
                      <ToggleButtonGroup
                        color="primary"
                        value={deadline}
                        exclusive
                        fullWidth
                        aria-label="Platform"
                        sx={{ display: 'flex' }}>
                        {deadline?.map((ele: any, index: number) => (
                          <ToggleButton
                            key={index}
                            value={ele.name}
                            onClick={() => setSelDeadline(ele.name)}
                            className={selDeadline === ele.name ? 'activeButton' : ''}
                            sx={{
                              backgroundColor: '#D9D9D9',
                              '&:hover': {
                                backgroundColor: '#D9D9D9'
                              },
                              '&.activeButton': {
                                backgroundColor: colors.primary,
                                color: 'white',
                                '& .MuiTypography-root': {
                                  color: 'white'
                                }
                              },
                              '&.activeButton:hover': {
                                backgroundColor: colors.primary,
                                color: 'white'
                              },
                              border: 'none',
                              mr: '8px',
                              borderRadius: '10px !important'
                            }}>
                            <Typography
                              variant="body2"
                              sx={{ display: 'flex', textTransform: 'none' }}>
                              {ele.name}
                            </Typography>
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                      <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                        Deadline
                        {/* <Box sx={{ display: 'flex', alignItems: 'center' }}> */}
                        {/* <TextField
                            type="time"
                            variant="outlined"
                            size="small"
                            {...register('startTime', {
                              required: 'This field is required.'
                            })}
                            onKeyDown={(event: any) => {
                              event.preventDefault();
                            }}
                            helperText={
                              errors.startTime && (
                                <Typography variant="caption" sx={{ color: 'red' }}>
                                  {errors?.startTime?.message as any}
                                </Typography>
                              )
                            }
                            sx={{ margin: '0px 8px' }}
                          /> */}
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              className="small-time-picker"
                              label="Start Time"
                              value={startTimes}
                              onChange={(newValue) => setStartTimes(newValue)}
                            />
                          </LocalizationProvider> */}
                        {/* <Typography sx={{ mx: '5px' }}>-</Typography> */}
                        {/* <TextField
                            type="time"
                            variant="outlined"
                            size="small"
                            {...register('endTime', {
                              required: 'This field is required.'
                            })}
                            onKeyDown={(event: any) => {
                              event.preventDefault();
                            }}
                            helperText={
                              errors.endTime && (
                                <Typography variant="caption" sx={{ color: 'red' }}>
                                  {errors?.endTime?.message as any}
                                </Typography>
                              )
                            }
                            sx={{ margin: '0px 8px' }}
                          /> */}
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              className="small-time-picker"
                              label="End Time"
                              value={endTimes}
                              onChange={(newValue) => setEndTimes(newValue)}
                            />
                          </LocalizationProvider> */}
                        {/* </Box> */}
                        {/* <TextField
                          type="time"
                          variant="outlined"
                          size="small"
                          {...register('time', {
                            required: 'This field is required.'
                          })}
                          onKeyDown={(event: any) => {
                            event.preventDefault();
                          }}
                          helperText={
                            errors.time && (
                              <Typography variant="caption" sx={{ color: 'red' }}>
                                {errors?.time?.message as any}
                              </Typography>
                            )
                          }
                          sx={{ margin: '0px 8px' }}
                        /> */}{' '}
                        on
                      </Typography>
                      <TextField
                        type="date"
                        variant="outlined"
                        size="small"
                        {...register('endDate', {
                          required: 'This field is required.'
                        })}
                        onKeyDown={(event: any) => {
                          event.preventDefault();
                        }}
                        helperText={
                          errors.endDate && (
                            <Typography variant="caption" sx={{ color: 'red' }}>
                              {errors?.endDate?.message as any}
                            </Typography>
                          )
                        }
                        disabled={!watch('startDate')}
                        inputProps={{
                          min: moment(watch('startDate')).format('YYYY-MM-DD')
                        }}
                        sx={{ margin: '0px 8px' }}
                      />
                    </Grid>
                  </>
                ) : alignment === 'Recurring' ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1" sx={{ mb: 0.5 }}>
                        Frequency
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          fullWidth
                          value={selDays}
                          onChange={handleChangeFrequency}>
                          <MenuItem value={'daily'}>Daily</MenuItem>
                          <MenuItem value={'specific_days'}>Specific day(s)</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">Start Date</Typography>
                      <TextField
                        type="date"
                        variant="outlined"
                        fullWidth
                        size="small"
                        {...register('startDate', {
                          required: 'This field is required.'
                        })}
                        onKeyDown={(event: any) => {
                          event.preventDefault();
                        }}
                        helperText={
                          errors.startDate && (
                            <Typography variant="caption" sx={{ color: 'red' }}>
                              {errors?.startDate?.message as any}
                            </Typography>
                          )
                        }
                        inputProps={{
                          min: moment().format('YYYY-MM-DD')
                        }}
                      />
                    </Grid>
                    {selDays != 'daily' && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="body1">Days</Typography>
                          <FormControl fullWidth>
                            <Select
                              size="small"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              fullWidth
                              multiple
                              value={daysOffPlan}
                              onChange={handleChangeDaysOffPlan}>
                              <MenuItem value={'mon'}>Monday</MenuItem>
                              <MenuItem value={'tue'}>Tuesday</MenuItem>
                              <MenuItem value={'wen'}>Wednesday</MenuItem>
                              <MenuItem value={'thu'}>Thursday</MenuItem>
                              <MenuItem value={'fri'}>Friday</MenuItem>
                              <MenuItem value={'sat'}>Saturday</MenuItem>
                              <MenuItem value={'sun'}>Sunday</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} />
                      </>
                    )}
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">Recurring Time</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* <TextField
                          type="time"
                          variant="outlined"
                          size="small"
                          {...register('recInitTime', {
                            required: 'This field is required.'
                          })}
                          onKeyDown={(event: any) => {
                            event.preventDefault();
                          }}
                          helperText={
                            errors.recInitTime && (
                              <Typography variant="caption" sx={{ color: 'red' }}>
                                {errors?.recInitTime?.message as any}
                              </Typography>
                            )
                          }
                          sx={{ margin: '0px 8px' }}
                        /> */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            className="small-time-picker"
                            // label="Start Time"
                            value={recStartTimes}
                            onChange={(newValue) => setRecStartTimes(newValue)}
                          />
                        </LocalizationProvider>
                        <Typography sx={{ mx: '5px' }}>-</Typography>
                        {/* <TextField
                          type="time"
                          variant="outlined"
                          size="small"
                          {...register('recEndTime', {
                            required: 'This field is required.'
                          })}
                          onKeyDown={(event: any) => {
                            event.preventDefault();
                          }}
                          helperText={
                            errors.recEndTime && (
                              <Typography variant="caption" sx={{ color: 'red' }}>
                                {errors?.recEndTime?.message as any}
                              </Typography>
                            )
                          }
                          sx={{ margin: '0px 8px' }}
                        /> */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            className="small-time-picker"
                            // label="End Time"
                            value={recEndTimes}
                            onChange={(newValue) => setRecEndTimes(newValue)}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                {allPlan && (
                  <Grid item xs={6}>
                    <Typography variant="body1">Task Plan</Typography>
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        fullWidth
                        value={taskPlan}
                        onChange={handleTaskPlan}>
                        {allPlan?.map((ele: any) => (
                          <MenuItem value={ele.value} key={ele.value}>
                            {ele.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {allPlan && allGroup && (
                  <Grid item xs={6}>
                    <Typography variant="body1">Task Group</Typography>
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        fullWidth
                        value={taskGroup}
                        onChange={handleTaskGroup}>
                        {allGroup?.map((ele: any) => (
                          <MenuItem value={ele.value} key={ele.value}>
                            {ele.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {allPlan && allGroup && allSubGroup && (
                  <Grid item xs={6}>
                    <Typography variant="body1">Task SubGroup</Typography>
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        fullWidth
                        value={taskSubGroup}
                        onChange={handleTaskSubGroup}>
                        {allSubGroup?.map((ele: any) => (
                          <MenuItem value={ele.value} key={ele.value}>
                            {ele.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    justifyContent: { xs: 'unset', sm: 'flex-end' },
                    marginTop: '20px'
                  }}>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => {
                        reset();
                        setAllPlan(null);
                        setAllGroup(null);
                        setAllSubGroup(null);
                        setShowDialog(false);
                        setIsOtherComponentVisible(false);
                      }}
                      sx={{
                        color: colors.primary,
                        borderColor: colors.primary,
                        fontWeight: 'bold',
                        '&:hover': { borderColor: `${colors.primary} !important` }
                      }}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      fullWidth
                      type="submit"
                      sx={{
                        backgroundColor: colors.primary,
                        fontWeight: 'bold',
                        '&:hover': { backgroundColor: `${colors.primary} !important` }
                      }}>
                      Save
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </form>
        </Dialog>
      ) : (
        <Dialog
          onClose={() => {
            reset();
            setAllPlan(null);
            setAllGroup(null);
            setAllSubGroup(null);
            setShowDialog(false);
            if (setIsOtherComponentVisible) setIsOtherComponentVisible(false);
          }}
          open={showDialog}
          maxWidth={'md'}
          fullWidth>
          <form onSubmit={handleSubmit(onEditTask)}>
            <Card sx={{ padding: '1.5rem 2rem !important', overflowY: 'scroll' }}>
              <Grid container spacing={1.5}>
                <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    aria-label="Platform">
                    <ToggleButton
                      value="One-Time"
                      onClick={() => setAlignment('One-Time')}
                      className={alignment === 'One-Time' ? 'activeButton' : ''}
                      sx={{
                        '&.activeButton': {
                          backgroundColor: colors.primary,
                          color: 'white',
                          '& .MuiTypography-root': {
                            color: 'white'
                          }
                        },
                        '&.activeButton:hover': {
                          backgroundColor: colors.primary,
                          color: 'white'
                        },
                        borderRadius: '10px'
                      }}>
                      <Typography variant="body2" sx={{ display: 'flex', textTransform: 'none' }}>
                        <TaskAltIcon fontSize="small" sx={{ mr: 1 }} /> One-Time
                      </Typography>
                    </ToggleButton>
                    <ToggleButton
                      value="Recurring"
                      onClick={() => setAlignment('Recurring')}
                      className={alignment === 'Recurring' ? 'activeButton' : ''}
                      sx={{
                        '&.activeButton': {
                          backgroundColor: colors.primary,
                          color: 'white',
                          '& .MuiTypography-root': {
                            color: 'white'
                          }
                        },
                        '&.activeButton:hover': {
                          backgroundColor: colors.primary,
                          color: 'white'
                        },
                        borderRadius: '10px'
                      }}>
                      <Typography variant="body2" sx={{ display: 'flex', textTransform: 'none' }}>
                        <CachedIcon fontSize="small" sx={{ mr: 1 }} /> Recurring
                      </Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body1" sx={{ mb: '7px 0' }}>
                    Task Title
                  </Typography>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    fullWidth
                    variant="outlined"
                    placeholder="Amino acids introduction and further studies"
                    {...register('title', {
                      required: 'This field is required.'
                    })}
                    helperText={
                      errors.title && (
                        <Typography variant="caption" sx={{ color: 'red' }}>
                          {errors?.title?.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body1" sx={{ mb: '7px 0' }}>
                    Task Description
                  </Typography>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    fullWidth
                    variant="outlined"
                    placeholder="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eveniet ex voluptatibus, corporis optio nam repellendus?"
                    {...register('desc', {
                      required: 'This field is required.'
                    })}
                    helperText={
                      errors.desc && (
                        <Typography variant="caption" sx={{ color: 'red' }}>
                          {errors?.desc?.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" mb={0.5}>
                    Duration
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      size="small"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      fullWidth
                      value={duration}
                      onChange={handleChangeDuration}>
                      <MenuItem value={'1'}>1 Hour</MenuItem>
                      <MenuItem value={'2'}>2 Hour</MenuItem>
                      <MenuItem value={'3'}>3 Hour</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      variant="body1"
                      sx={{ color: !checkedList ? colors.secondary : '#000' }}>
                      External Link
                    </Typography>
                    <Switch
                      size="small"
                      checked={checkedList}
                      onChange={handleChangeLink}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography
                      variant="body1"
                      sx={{ color: !checkedList ? '#000' : colors.secondary }}>
                      Internal Link
                    </Typography>
                  </Box>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    fullWidth
                    variant="outlined"
                    placeholder="https://AAMCmaterial.com"
                    {...register('link', {
                      required: 'This field is required.'
                    })}
                    helperText={
                      errors.link && (
                        <Typography variant="caption" sx={{ color: 'red' }}>
                          {errors?.link?.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={8} sx={{ display: 'flex' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      backgroundColor: '#D9D9D9',
                      padding: '8px',
                      borderRadius: '10px'
                    }}>
                    <Typography variant="body1">Allow split into chunks</Typography>
                    <Switch
                      // size="small"
                      checked={checkedChunk}
                      onChange={handleChangeChunk}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography variant="body1">Minimum chunk duration</Typography>
                    <FormControl>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={chunks}
                        onChange={handleChangeChunks}
                        sx={{ width: '100px', ml: '10px' }}>
                        <MenuItem value={'15'}>15 min</MenuItem>
                        <MenuItem value={'25'}>25 min</MenuItem>
                        <MenuItem value={'35'}>35 min</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} sx={{ overflowX: 'auto' }}>
                  <Typography variant="body1" sx={{ mb: '7px 0' }}>
                    Priority
                  </Typography>
                  <ToggleButtonGroup
                    color="primary"
                    value={planPriority}
                    exclusive
                    fullWidth
                    aria-label="Platform"
                    sx={{ display: 'flex' }}>
                    {planPriority?.map((ele: any, index: number) => (
                      <ToggleButton
                        key={index}
                        value={ele.name}
                        onClick={() => setSelPlanPriority(ele.name)}
                        className={selPlanPriority === ele.name ? 'activeButton' : ''}
                        sx={{
                          backgroundColor: '#D9D9D9',
                          '&:hover': {
                            backgroundColor: '#D9D9D9'
                          },
                          '&.activeButton': {
                            backgroundColor: colors.primary,
                            color: 'white',
                            '& .MuiTypography-root': {
                              color: 'white'
                            }
                          },
                          '&.activeButton:hover': {
                            backgroundColor: colors.primary,
                            color: 'white'
                          },
                          border: 'none',
                          mr: '5px',
                          borderRadius: '10px !important'
                        }}>
                        <Typography variant="body2" sx={{ display: 'flex', textTransform: 'none' }}>
                          <TourIcon
                            fontSize="small"
                            sx={{ mr: 1, color: PriorityColor(ele.name) }}
                          />{' '}
                          {ele.name}
                        </Typography>
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Start Date</Typography>
                  <TextField
                    type="date"
                    variant="outlined"
                    fullWidth
                    size="small"
                    {...register('startDate', {
                      required: 'This field is required.'
                    })}
                    onKeyDown={(event: any) => {
                      event.preventDefault();
                    }}
                    helperText={
                      errors.startDate && (
                        <Typography variant="caption" sx={{ color: 'red' }}>
                          {errors?.startDate?.message as any}
                        </Typography>
                      )
                    }
                    inputProps={{
                      min: moment().format('YYYY-MM-DD')
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Schedule</Typography>
                  {!isLoading ? (
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        fullWidth
                        value={schedule}
                        onChange={handleChangeSchedule}>
                        {scheduler?.map((ele: any) => (
                          <MenuItem value={ele.value} key={ele.value}>
                            {ele.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <Skeleton variant="rectangular" height={'60%'} />
                  )}
                </Grid>

                {alignment === 'One-Time' ? (
                  <>
                    <Grid item xs={6}>
                      <Typography variant="body1">Blocker</Typography>
                      <FormControl fullWidth>
                        <Select
                          size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          fullWidth
                          value={blocker}
                          onChange={handleChangeBlocker}>
                          <MenuItem value={'T1'}>Task 1</MenuItem>
                          <MenuItem value={'T2'}>Task 2</MenuItem>
                          <MenuItem value={'T3'}>Task 3</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={8}>
                      <Typography variant="body1" sx={{ mb: '7px 0' }}>
                        Deadline Type
                      </Typography>
                      <ToggleButtonGroup
                        color="primary"
                        value={deadline}
                        exclusive
                        fullWidth
                        aria-label="Platform"
                        sx={{ display: 'flex' }}>
                        {deadline?.map((ele: any, index: number) => (
                          <ToggleButton
                            key={index}
                            value={ele.name}
                            onClick={() => setSelDeadline(ele.name)}
                            className={selDeadline === ele.name ? 'activeButton' : ''}
                            sx={{
                              backgroundColor: '#D9D9D9',
                              '&:hover': {
                                backgroundColor: '#D9D9D9'
                              },
                              '&.activeButton': {
                                backgroundColor: colors.primary,
                                color: 'white',
                                '& .MuiTypography-root': {
                                  color: 'white'
                                }
                              },
                              '&.activeButton:hover': {
                                backgroundColor: colors.primary,
                                color: 'white'
                              },
                              border: 'none',
                              mr: '8px',
                              borderRadius: '10px !important'
                            }}>
                            <Typography
                              variant="body2"
                              sx={{ display: 'flex', textTransform: 'none' }}>
                              {ele.name}
                            </Typography>
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    </Grid>
                    {/* <Grid item xs={12} sx={{ display: 'flex' }}>
                      <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                        Deadline
                        <TextField
                          type="time"
                          variant="outlined"
                          size="small"
                          {...register('time', {
                            required: 'This field is required.'
                          })}
                          onKeyDown={(event: any) => {
                            event.preventDefault();
                          }}
                          helperText={
                            errors.time && (
                              <Typography variant="caption" sx={{ color: 'red' }}>
                                {errors?.time?.message as any}
                              </Typography>
                            )
                          }
                          sx={{ margin: '0px 8px' }}
                        />
                        
                        on
                      </Typography>
                      <TextField
                        type="date"
                        variant="outlined"
                        size="small"
                        {...register('endDate', {
                          required: 'This field is required.'
                        })}
                        onKeyDown={(event: any) => {
                          event.preventDefault();
                        }}
                        helperText={
                          errors.endDate && (
                            <Typography variant="caption" sx={{ color: 'red' }}>
                              {errors?.endDate?.message as any}
                            </Typography>
                          )
                        }
                        disabled={!watch('startDate')}
                        inputProps={{
                          min: moment(watch('startDate')).format('YYYY-MM-DD')
                        }}
                        sx={{ margin: '0px 8px' }}
                      />
                    </Grid> */}
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                      <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                        Deadline
                        {/* <Box sx={{ display: 'flex', alignItems: 'center' }}> */}
                        {/* <TextField
                            type="time"
                            variant="outlined"
                            size="small"
                            {...register('startTime', {
                              required: 'This field is required.'
                            })}
                            onKeyDown={(event: any) => {
                              event.preventDefault();
                            }}
                            helperText={
                              errors.startTime && (
                                <Typography variant="caption" sx={{ color: 'red' }}>
                                  {errors?.startTime?.message as any}
                                </Typography>
                              )
                            }
                            sx={{ margin: '0px 8px' }}
                          /> */}
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              className="small-time-picker"
                              label="Start Time"
                              value={startTimes}
                              onChange={(newValue) => setStartTimes(newValue)}
                            />
                          </LocalizationProvider> */}
                        {/* <Typography sx={{ mx: '5px' }}>-</Typography> */}
                        {/* <TextField
                            type="time"
                            variant="outlined"
                            size="small"
                            {...register('endTime', {
                              required: 'This field is required.'
                            })}
                            onKeyDown={(event: any) => {
                              event.preventDefault();
                            }}
                            helperText={
                              errors.endTime && (
                                <Typography variant="caption" sx={{ color: 'red' }}>
                                  {errors?.endTime?.message as any}
                                </Typography>
                              )
                            }
                            sx={{ margin: '0px 8px' }}
                          /> */}
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              className="small-time-picker"
                              label="End Time"
                              value={endTimes}
                              onChange={(newValue) => setEndTimes(newValue)}
                            />
                          </LocalizationProvider> */}
                        {/* </Box> */}
                        {/* <TextField
                          type="time"
                          variant="outlined"
                          size="small"
                          {...register('time', {
                            required: 'This field is required.'
                          })}
                          onKeyDown={(event: any) => {
                            event.preventDefault();
                          }}
                          helperText={
                            errors.time && (
                              <Typography variant="caption" sx={{ color: 'red' }}>
                                {errors?.time?.message as any}
                              </Typography>
                            )
                          }
                          sx={{ margin: '0px 8px' }}
                        /> */}{' '}
                        on
                      </Typography>
                      <TextField
                        type="date"
                        variant="outlined"
                        size="small"
                        {...register('endDate', {
                          required: 'This field is required.'
                        })}
                        onKeyDown={(event: any) => {
                          event.preventDefault();
                        }}
                        helperText={
                          errors.endDate && (
                            <Typography variant="caption" sx={{ color: 'red' }}>
                              {errors?.endDate?.message as any}
                            </Typography>
                          )
                        }
                        disabled={!watch('startDate')}
                        inputProps={{
                          min: moment(watch('startDate')).format('YYYY-MM-DD')
                        }}
                        sx={{ margin: '0px 8px' }}
                      />
                    </Grid>
                  </>
                ) : alignment === 'Recurring' ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">Days</Typography>
                      <FormControl fullWidth>
                        <Select
                          size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          fullWidth
                          multiple
                          value={daysOffPlan}
                          onChange={handleChangeDaysOffPlan}>
                          <MenuItem value={'mon'}>Monday</MenuItem>
                          <MenuItem value={'tue'}>Tuesday</MenuItem>
                          <MenuItem value={'wen'}>Wednesday</MenuItem>
                          <MenuItem value={'thu'}>Thursday</MenuItem>
                          <MenuItem value={'fri'}>Friday</MenuItem>
                          <MenuItem value={'sat'}>Saturday</MenuItem>
                          <MenuItem value={'sun'}>Sunday</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body1">Recurring Time</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* <TextField
                          type="time"
                          variant="outlined"
                          size="small"
                          {...register('recInitTime', {
                            required: 'This field is required.'
                          })}
                          onKeyDown={(event: any) => {
                            event.preventDefault();
                          }}
                          helperText={
                            errors.recInitTime && (
                              <Typography variant="caption" sx={{ color: 'red' }}>
                                {errors?.recInitTime?.message as any}
                              </Typography>
                            )
                          }
                          sx={{ margin: '0px 8px' }}
                        /> */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            className="small-time-picker"
                            label="Start Time"
                            value={recStartTimes}
                            onChange={(newValue) => setRecStartTimes(newValue)}
                          />
                        </LocalizationProvider>
                        <Typography sx={{ mx: '5px' }}>-</Typography>
                        {/* <TextField
                          type="time"
                          variant="outlined"
                          size="small"
                          {...register('recEndTime', {
                            required: 'This field is required.'
                          })}
                          onKeyDown={(event: any) => {
                            event.preventDefault();
                          }}
                          helperText={
                            errors.recEndTime && (
                              <Typography variant="caption" sx={{ color: 'red' }}>
                                {errors?.recEndTime?.message as any}
                              </Typography>
                            )
                          }
                          sx={{ margin: '0px 8px' }}
                        /> */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            className="small-time-picker"
                            label="End Time"
                            value={recEndTimes}
                            onChange={(newValue) => setRecEndTimes(newValue)}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                {allPlan && (
                  <Grid item xs={6}>
                    <Typography variant="body1">Task Plan</Typography>
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        fullWidth
                        value={taskPlan}
                        onChange={handleTaskPlan}>
                        {allPlan?.map((ele: any) => (
                          <MenuItem value={ele.value} key={ele.value}>
                            {ele.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {allPlan && allGroup && (
                  <Grid item xs={6}>
                    <Typography variant="body1">Task Group</Typography>
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        fullWidth
                        value={taskGroup}
                        onChange={handleTaskGroup}>
                        {allGroup?.map((ele: any) => (
                          <MenuItem value={ele.value} key={ele.value}>
                            {ele.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {allPlan && allGroup && allSubGroup && (
                  <Grid item xs={6}>
                    <Typography variant="body1">Task SubGroup</Typography>
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        fullWidth
                        value={taskSubGroup}
                        onChange={handleTaskSubGroup}>
                        {allSubGroup?.map((ele: any) => (
                          <MenuItem value={ele.value} key={ele.value}>
                            {ele.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    justifyContent: { xs: 'unset', sm: 'flex-end' },
                    marginTop: '20px'
                  }}>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => {
                        reset();
                        setAllPlan(null);
                        setAllGroup(null);
                        setAllSubGroup(null);
                        setShowDialog(false);
                        if (setIsOtherComponentVisible) setIsOtherComponentVisible(false);
                      }}
                      sx={{
                        color: colors.primary,
                        borderColor: colors.primary,
                        fontWeight: 'bold',
                        '&:hover': { borderColor: `${colors.primary} !important` }
                      }}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      fullWidth
                      type="submit"
                      sx={{
                        backgroundColor: colors.primary,
                        fontWeight: 'bold',
                        '&:hover': { backgroundColor: `${colors.primary} !important` }
                      }}>
                      Save
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </form>
        </Dialog>
      )}
    </>
  );
};

export default AddTaskPopUp;
