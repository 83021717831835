import {
  Box,
  Button,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import AddIcon from '@mui/icons-material/Add';
import InventoryIcon from '@mui/icons-material/Inventory';

import colors from '../colorConfig/colors.js';
import CreatePlanPopUp from '../CreatePlanPopUp';
import HttpService from '../../../hooks/Https-services';
import { API_ROUTES, PriorityColor } from '../../../utils/constants.js';
import TourIcon from '@mui/icons-material/Tour';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { toast } from 'react-toastify';
import CreateGroupPopUp from '../CreateGroupPopUp';
import CreateSubGroupPopUp from '../CreateSubgroupPopUp';
import AddTaskPopUp from '../AddTaskPopUp';
import planTag from '../../../assets/image/Plan-Tag.png';
import unPlanTag from '../../../assets/image/UnPlan-Tag.png';
import groupTag from '../../../assets/image/Group-Tag.png';
import subGroupTag from '../../../assets/image/SubGroup-Tag.png';

function SchedulePlans() {
  const [isLoading, setIsLoading] = useState(false);
  const [allPlans, setAllPlans] = useState<any>([]);
  const [allUnGroupTask, setAllUnGroupTask] = useState<any>([]);

  // Expand less/more
  const [openPlans, setOpenPlans] = React.useState(new Array(allPlans?.length).fill(false));
  const [openGroups, setOpenGroups] = React.useState<boolean[][]>(
    Array.from({ length: allPlans?.length }, () => [])
  );
  const [openSubGroups, setOpenSubGroups] = React.useState<boolean[][][]>(
    Array.from({ length: allPlans?.length }, () => [])
  );

  const handleClickPlans = (index: number) => {
    const updatedOpenPlans = [...openPlans];
    updatedOpenPlans[index] = !updatedOpenPlans[index];
    setOpenPlans(updatedOpenPlans);
  };

  const handleClickGroups = (planIndex: number, groupIndex: number) => {
    setOpenGroups((prevOpenGroups) => {
      const updatedOpenGroups = [...prevOpenGroups];
      if (!updatedOpenGroups[planIndex]) {
        updatedOpenGroups[planIndex] = [];
      }
      updatedOpenGroups[planIndex] = [...updatedOpenGroups[planIndex]];
      if (updatedOpenGroups[planIndex][groupIndex] === undefined) {
        updatedOpenGroups[planIndex][groupIndex] = false;
      }
      updatedOpenGroups[planIndex][groupIndex] = !updatedOpenGroups[planIndex][groupIndex];
      return updatedOpenGroups;
    });
  };

  const handleClickSubGroups = (planIndex: number, groupIndex: number, subGroupIndex: number) => {
    setOpenSubGroups((prevOpenSubGroups) => {
      const updatedOpenSubGroups = [...prevOpenSubGroups];
      if (!updatedOpenSubGroups[planIndex]?.[groupIndex]) {
        updatedOpenSubGroups[planIndex] = updatedOpenSubGroups[planIndex] || [];
        updatedOpenSubGroups[planIndex][groupIndex] = [];
      }
      updatedOpenSubGroups[planIndex][groupIndex] = [
        ...updatedOpenSubGroups[planIndex][groupIndex]
      ];
      updatedOpenSubGroups[planIndex][groupIndex][subGroupIndex] =
        !updatedOpenSubGroups[planIndex][groupIndex][subGroupIndex];
      return updatedOpenSubGroups;
    });
  };

  const dateFormat = (date: string) => {
    return moment(date).format('MMM DD, YYYY');
  };

  const getPlans = async () => {
    try {
      setIsLoading(true);
      const AllGroups = await HttpService.get(API_ROUTES.GetUserPlans);
      setAllPlans(AllGroups.data.data.data.plan_data);
      setAllUnGroupTask(AllGroups.data.data.data.ungroup_tasks);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const deletePlan = async (id: number) => {
    try {
      await HttpService.del(API_ROUTES.DeletePlan.replace(':id', `${id}`));
      toast.success('Plan deleted successfully');
      getPlans();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteGroup = async (id: number) => {
    try {
      await HttpService.del(API_ROUTES.DeleteGroup.replace(':id', `${id}`));
      toast.success('Group deleted successfully');
      getPlans();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteSubGroup = async (id: number) => {
    try {
      await HttpService.del(API_ROUTES.DeleteSubGroup.replace(':id', `${id}`));
      toast.success('SubGroup deleted successfully');
      getPlans();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteTask = async (id: number) => {
    try {
      await HttpService.del(API_ROUTES.DeleteTask.replace(':id', `${id}`));
      toast.success('Task deleted successfully');
      getPlans();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <Box>
      {/* <CreatePlanPopUp
        openCreatePlan={openCreatePlan}
        setOpenCreatePlan={setOpenCreatePlan}
        getPlans={getPlans}
      /> */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}>
          {/* <Button
            color="secondary"
            variant="outlined"
            size="small"
            sx={{ borderRadius: '7px' }}
            onClick={() => setOpenCreatePlan(true)}>
            <AddIcon fontSize="small" sx={{ mr: '5px' }} />
            Create Plan
          </Button> */}
          <CreatePlanPopUp isEdit={false} getPlans={getPlans} />
          <Button color="secondary" variant="outlined" size="small" sx={{ borderRadius: '7px' }}>
            <InventoryIcon fontSize="small" sx={{ mr: '5px' }} />
            Plan Archives
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: colors.white,
          minHeight: 'calc(100vh - 253px)',
          borderRadius: '10px'
        }}>
        {!isLoading ? (
          <>
            {allPlans?.length > 0 &&
              allPlans?.map((ele: any, index: number) => (
                <List
                  key={index}
                  sx={{
                    width: '100%',
                    mt: '20px'
                  }}
                  disablePadding
                  component="nav"
                  aria-labelledby="nested-list-subheader">
                  <ListItemButton
                    disableRipple
                    sx={{
                      pl: 1,
                      backgroundColor: '#F6F6F6',
                      borderBottom: '1px solid #000',
                      borderLeft: '15px solid #4E5FFF'
                    }}>
                    <ListItemText>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Typography variant="body1" sx={{ fontWeight: 400, mr: 1 }}>
                            {ele?.plan_title}
                          </Typography>
                          <img src={planTag} width={'70px'} />
                        </Box>
                        <Box>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: 400, display: 'flex', alignItems: 'center' }}>
                            <TourIcon
                              fontSize="small"
                              sx={{ mr: 2, color: PriorityColor(ele?.priority) }}
                            />
                            <CalendarMonthIcon fontSize="small" sx={{ mr: '5px' }} />
                            {dateFormat(ele?.start_date)}
                            {/* <ModeEditOutlineIcon fontSize="small" sx={{ m: '0 10px 0 20px' }} /> */}
                            <CreatePlanPopUp isEdit getPlans={getPlans} datas={ele} />
                            <DeleteIcon
                              fontSize="small"
                              sx={{ m: '0 10px 0 5px' }}
                              onClick={() => deletePlan(ele?.user_plan_sid)}
                            />
                            <CreateGroupPopUp
                              getPlans={getPlans}
                              isEditGroup={false}
                              planData={ele}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    </ListItemText>
                    {openPlans[index] ? (
                      <ExpandLess onClick={() => handleClickPlans(index)} />
                    ) : (
                      <ExpandMore onClick={() => handleClickPlans(index)} />
                    )}
                  </ListItemButton>
                  <Collapse in={openPlans[index]} timeout="auto" unmountOnExit>
                    {/* GROUPS LOOP */}
                    {ele?.plan_groups &&
                      ele?.plan_groups.map((grpele: any, groupIndex: number) => (
                        <List component="div" disablePadding key={groupIndex}>
                          <ListItemButton
                            // onClick={handleClickTask}
                            disableRipple
                            sx={{
                              pl: 3,
                              '&:hover': {
                                backgroundColor: 'transparent'
                              },
                              borderBottom: '1px solid #000',
                              borderLeft: '10px solid #4E5FFF'
                            }}>
                            <ListItemText>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center'
                                }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                  <Typography variant="body1" sx={{ fontWeight: 400, mr: 1 }}>
                                    {grpele?.group_title}
                                  </Typography>
                                  <img src={groupTag} width={'70px'} />
                                </Box>
                                <Box>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontWeight: 400, display: 'flex', alignItems: 'center' }}>
                                    <TourIcon
                                      fontSize="small"
                                      sx={{ mr: 2, color: PriorityColor(grpele?.priority) }}
                                    />
                                    {/* <CalendarMonthIcon fontSize="small" sx={{ mr: '5px' }} />
                                {dateFormat(grpele?.created_at)} */}
                                    <CreateGroupPopUp
                                      getPlans={getPlans}
                                      isEditGroup
                                      planData={ele}
                                      datas={grpele}
                                    />
                                    <DeleteIcon
                                      fontSize="small"
                                      sx={{ m: '0 10px 0 5px' }}
                                      onClick={() => deleteGroup(grpele?.user_plan_group_sid)}
                                    />
                                    <CreateSubGroupPopUp
                                      getPlans={getPlans}
                                      isEditSubGroup={false}
                                      groupData={grpele}
                                    />
                                  </Typography>
                                </Box>
                              </Box>
                            </ListItemText>
                            {openGroups[index] && openGroups[index][groupIndex] ? (
                              <ExpandLess onClick={() => handleClickGroups(index, groupIndex)} />
                            ) : (
                              <ExpandMore onClick={() => handleClickGroups(index, groupIndex)} />
                            )}
                            {/* <ExpandLess /> */}
                          </ListItemButton>
                          <Collapse
                            in={openGroups[index]?.[groupIndex]}
                            timeout="auto"
                            unmountOnExit>
                            {grpele &&
                              grpele?.sub_groups &&
                              grpele?.sub_groups?.map((subgrpele: any, subGrpIndex: number) => (
                                <List component="div" disablePadding key={subGrpIndex}>
                                  <ListItemButton
                                    // onClick={handleClickTask}
                                    disableRipple
                                    sx={{
                                      pl: 5,
                                      '&:hover': {
                                        backgroundColor: 'transparent'
                                      },
                                      borderBottom: '1px solid #000',
                                      borderLeft: '5px solid #4E5FFF'
                                    }}>
                                    <ListItemText>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center'
                                        }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                          <Typography
                                            variant="body1"
                                            sx={{ fontWeight: 400, mr: 1 }}>
                                            {subgrpele?.subgroup_title}
                                          </Typography>
                                          <img src={subGroupTag} width={'70px'} />
                                        </Box>

                                        <Box>
                                          <Typography
                                            variant="body1"
                                            sx={{
                                              fontWeight: 400,
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}>
                                            <TourIcon
                                              fontSize="small"
                                              sx={{
                                                mr: 2,
                                                color: PriorityColor(subgrpele?.priority)
                                              }}
                                            />
                                            {/* <CalendarMonthIcon fontSize="small" sx={{ mr: '5px' }} />
                                  {dateFormat(grpele?.created_at)} */}
                                            <CreateSubGroupPopUp
                                              getPlans={getPlans}
                                              isEditSubGroup
                                              groupData={grpele}
                                              datas={subgrpele}
                                            />
                                            <DeleteIcon
                                              fontSize="small"
                                              sx={{ m: '0 10px 0 5px' }}
                                              onClick={() =>
                                                deleteSubGroup(subgrpele?.user_plan_sub_group_sid)
                                              }
                                            />
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </ListItemText>
                                    {openSubGroups[index] &&
                                    openSubGroups[index]?.[groupIndex] &&
                                    openSubGroups[index]?.[groupIndex]?.[subGrpIndex] ? (
                                      <ExpandLess
                                        onClick={() =>
                                          handleClickSubGroups(index, groupIndex, subGrpIndex)
                                        }
                                      />
                                    ) : (
                                      <ExpandMore
                                        onClick={() =>
                                          handleClickSubGroups(index, groupIndex, subGrpIndex)
                                        }
                                      />
                                    )}
                                    {/* <ExpandLess /> */}
                                  </ListItemButton>
                                  <Collapse
                                    in={openSubGroups[index]?.[groupIndex]?.[subGrpIndex]}
                                    timeout="auto"
                                    unmountOnExit>
                                    {/* <List component="div" disablePadding>
                                <TableContainer
                                  component={Paper}
                                  sx={{ backgroundColor: 'transparent' }}>
                                  <Table sx={{ width: 1, pl: 4 }} aria-label="simple table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell align="right">Priority</TableCell>
                                        <TableCell align="right">Deadline</TableCell>
                                        <TableCell align="right">Blockers</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      <TableRow
                                        sx={{
                                          '&:last-child td, &:last-child th': { border: 0 }
                                        }}>
                                        <TableCell component="th" scope="row">
                                          Task NAME
                                        </TableCell>
                                        <TableCell align="right">Task Priority</TableCell>
                                        <TableCell align="right">Task Deadline</TableCell>
                                        <TableCell align="right">Task Blockers</TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </List> */}
                                    {subgrpele?.sub_group_tasks !== null && (
                                      <TableContainer
                                        component={Paper}
                                        sx={{ backgroundColor: 'transparent' }}>
                                        <Table sx={{ width: 1, pl: 4 }} aria-label="simple table">
                                          <TableHead sx={{ borderLeft: '5px solid #06C7BB' }}>
                                            <TableRow>
                                              <TableCell>Name</TableCell>
                                              <TableCell align="right">Priority</TableCell>
                                              <TableCell align="right">Deadline</TableCell>
                                              <TableCell align="right">Action</TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {subgrpele?.sub_group_tasks?.map(
                                              (ele: any, index: number) => (
                                                <TableRow
                                                  sx={{
                                                    '&:last-child td, &:last-child th': {
                                                      border: 0
                                                    },
                                                    borderLeft: '3px solid #06C7BB'
                                                  }}
                                                  key={index}>
                                                  <TableCell component="th" scope="row">
                                                    {ele?.task_title}
                                                  </TableCell>
                                                  <TableCell align="right">
                                                    <Box
                                                      sx={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end'
                                                      }}>
                                                      <TourIcon
                                                        fontSize="small"
                                                        sx={{
                                                          mr: 2,
                                                          color: PriorityColor(ele?.priority)
                                                        }}
                                                      />{' '}
                                                      {ele?.priority}
                                                    </Box>
                                                  </TableCell>
                                                  <TableCell align="right">
                                                    {dateFormat(ele?.deadline_date)}
                                                  </TableCell>
                                                  <TableCell align="right">
                                                    <AddTaskPopUp
                                                      getPlans={getPlans}
                                                      isEditTask
                                                      datas={ele}
                                                    />
                                                    <DeleteIcon
                                                      fontSize="small"
                                                      sx={{ m: '0 10px 0 5px' }}
                                                      onClick={() => deleteTask(ele?.user_task_sid)}
                                                    />
                                                  </TableCell>
                                                </TableRow>
                                              )
                                            )}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    )}
                                  </Collapse>
                                </List>
                              ))}
                            {/* <Box sx={{ margin: '5px' }}>
                          <CreateSubGroupPopUp
                            getPlans={getPlans}
                            isEditSubGroup={false}
                            groupData={grpele}
                          />
                        </Box> */}
                            {grpele?.group_tasks !== null && (
                              <TableContainer
                                component={Paper}
                                sx={{ backgroundColor: 'transparent' }}>
                                <Table sx={{ width: 1, pl: 4 }} aria-label="simple table">
                                  <TableHead sx={{ borderLeft: '10px solid #06C7BB' }}>
                                    <TableRow>
                                      <TableCell>Name</TableCell>
                                      <TableCell align="right">Priority</TableCell>
                                      <TableCell align="right">Deadline</TableCell>
                                      <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {grpele?.group_tasks?.map((ele: any, index: number) => (
                                      <TableRow
                                        sx={{
                                          '&:last-child td, &:last-child th': { border: 0 },
                                          borderLeft: '3px solid #06C7BB'
                                        }}
                                        key={index}>
                                        <TableCell component="th" scope="row">
                                          {ele?.task_title}
                                        </TableCell>
                                        <TableCell align="right">
                                          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <TourIcon
                                              fontSize="small"
                                              sx={{ mr: 2, color: PriorityColor(ele?.priority) }}
                                            />{' '}
                                            {ele?.priority}
                                          </Box>
                                        </TableCell>
                                        <TableCell align="right">
                                          {dateFormat(ele?.deadline_date)}
                                        </TableCell>
                                        <TableCell align="right">
                                          <AddTaskPopUp
                                            getPlans={getPlans}
                                            isEditTask
                                            datas={ele}
                                          />
                                          <DeleteIcon
                                            fontSize="small"
                                            sx={{ m: '0 10px 0 5px' }}
                                            onClick={() => deleteTask(ele?.user_task_sid)}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            )}
                          </Collapse>
                        </List>
                      ))}

                    {/* <Box sx={{ margin: '5px' }}>
                    <CreateGroupPopUp getPlans={getPlans} isEditGroup={false} planData={ele} />
                  </Box> */}

                    {ele?.plan_tasks !== null && (
                      <TableContainer component={Paper} sx={{ backgroundColor: 'transparent' }}>
                        <Table sx={{ width: 1, pl: 4 }} aria-label="simple table">
                          <TableHead sx={{ borderLeft: '15px solid #06C7BB' }}>
                            <TableRow>
                              <TableCell>Name</TableCell>
                              <TableCell align="right">Priority</TableCell>
                              <TableCell align="right">Deadline</TableCell>
                              <TableCell align="right">Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {ele?.plan_tasks?.map((ele: any, index: number) => (
                              <TableRow
                                sx={{
                                  '&:last-child td, &:last-child th': { border: 0 },
                                  borderLeft: '3px solid #06C7BB'
                                }}
                                key={index}>
                                <TableCell component="th" scope="row">
                                  {ele?.task_title}
                                </TableCell>
                                <TableCell align="right">
                                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <TourIcon
                                      fontSize="small"
                                      sx={{ mr: 2, color: PriorityColor(ele?.priority) }}
                                    />{' '}
                                    {ele?.priority}
                                  </Box>
                                </TableCell>
                                <TableCell align="right">
                                  {dateFormat(ele?.deadline_date)}
                                </TableCell>
                                <TableCell align="right">
                                  <AddTaskPopUp getPlans={getPlans} isEditTask datas={ele} />
                                  <DeleteIcon
                                    fontSize="small"
                                    sx={{ m: '0 10px 0 5px' }}
                                    onClick={() => deleteTask(ele?.user_task_sid)}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Collapse>
                </List>
              ))}

            {allUnGroupTask?.length > 0 && (
              <TableContainer component={Paper} sx={{ backgroundColor: 'transparent', mt: '20px' }}>
                <Table sx={{ width: 1, pl: 4 }} aria-label="simple table">
                  <TableHead sx={{ borderLeft: '20px solid #06C7BB' }}>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Priority</TableCell>
                      <TableCell align="right">Deadline</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allUnGroupTask?.map((ele: any, index: number) => (
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          borderLeft: '3px solid #06C7BB'
                        }}
                        key={index}>
                        <TableCell component="th" scope="row">
                          {ele?.task_title}
                        </TableCell>
                        <TableCell align="right">
                          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <TourIcon
                              fontSize="small"
                              sx={{ mr: 2, color: PriorityColor(ele?.priority) }}
                            />{' '}
                            {ele?.priority}
                          </Box>
                        </TableCell>
                        <TableCell align="right">{dateFormat(ele?.deadline_date)}</TableCell>
                        <TableCell align="right">
                          <AddTaskPopUp getPlans={getPlans} isEditTask datas={ele} />
                          <DeleteIcon
                            fontSize="small"
                            sx={{ m: '0 10px 0 5px' }}
                            onClick={() => deleteTask(ele?.user_task_sid)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            {/* {allPlans?.length <= 0 && allUnGroupTask?.length <= 0 && (
              <>
                <Typography sx={{ fontSize: '20px', color: 'black' }}>No data found</Typography>
              </>
            )} */}
          </>
        ) : (
          <Skeleton variant="rectangular" height={'2.5rem'} sx={{ mb: 1 }} />
        )}
      </Box>
    </Box>
  );
}

export default SchedulePlans;
