import {
  Box,
  Button,
  Divider,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import logo from '../../../assets/image/icon.png';
import colors from '../colorConfig/colors.js';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { APP_ROUTES } from '../../../utils/constants';
import LoginPop from '../LoginPop';
import SignUpPop from '../SignUpPop';
import { GoogleOAuthProvider } from '@react-oauth/google';
import MenuIcon from '@mui/icons-material/Menu';

const Header = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const q = searchParams.get('q');
  const q1 = searchParams.get('q1');
  const referral_code = searchParams.get('referral_code');
  const token = searchParams.get('token');
  const location = useLocation();
  useEffect(() => {
    if (q == 'true') {
      setOpen(true);
      searchParams.delete('q');
      setSearchParams(searchParams);
    }
    if (q1 == 'true') {
      setOpen2(true);
      searchParams.delete('q1');
      setSearchParams(searchParams);
    }
    if (referral_code) {
      setOpen2(true);
    }

    if (token && location.pathname == '/') {
      setOpen(true);
    }
  }, [q, q1, token]);

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
          px: { xs: 1, sm: 3 },
          alignItems: 'center',
          backgroundColor: colors.primary,
          gap: 1
        }}>
        <Box>
          <Box
            component="img"
            src={logo}
            sx={{
              cursor: 'pointer',
              '&:hover': { backgroundColor: colors.primary }
            }}
            onClick={() => navigate(APP_ROUTES.Landing)}
            width={{ xs: '150px', md: '200px' }}
          />
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            gap: { xs: 0, sm: 2 },
            alignItems: 'center'
          }}>
          <Typography
            variant="body1"
            color="white"
            onClick={() => navigate(APP_ROUTES.Mcat)}
            sx={{ cursor: 'pointer', '&:hover': { color: colors.secondary } }}>
            MCAT
          </Typography>
          <Typography
            variant="body1"
            color="white"
            onClick={() => navigate(APP_ROUTES.Mcat)}
            sx={{ cursor: 'pointer', '&:hover': { color: colors.secondary } }}>
            FAQs
          </Typography>
          <Typography
            variant="body1"
            color="white"
            sx={{ cursor: 'pointer', '&:hover': { color: colors.secondary } }}
            onClick={() => navigate(APP_ROUTES.Blog)}>
            Resources
          </Typography>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            alignItems: { xs: 'normal', sm: 'center' },
            gap: 1.5
          }}>
          <Button
            variant="outlined"
            sx={{
              fontWeight: '700'
            }}
            onClick={() => setOpen(true)}>
            LOG IN
          </Button>
          <Button
            variant="contained"
            sx={{
              fontWeight: '700'
            }}
            onClick={() => setOpen2(true)}>
            GET STARTED FREE
          </Button>
        </Box>
        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
          <Button onClick={handleOpenDrawer}>
            <MenuIcon fontSize="large" />
          </Button>
          <Drawer
            anchor="right" // Position the drawer on the right side
            open={openDrawer}
            onClose={handleCloseDrawer}>
            <Box
              sx={{
                width: '200px',
                background: colors.primary,
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                p: 3,
                py: 5,
                boxSizing: 'border-box',
                gap: 3
              }}>
              <Typography
                variant="body1"
                color="white"
                onClick={() => {
                  navigate(APP_ROUTES.Mcat);
                  handleCloseDrawer();
                }}
                sx={{ cursor: 'pointer', '&:hover': { color: colors.secondary } }}>
                MCAT
              </Typography>
              <Typography
                variant="body1"
                color="white"
                onClick={() => {
                  navigate(APP_ROUTES.Mcat);
                  handleCloseDrawer();
                }}
                sx={{ cursor: 'pointer', '&:hover': { color: colors.secondary } }}>
                FAQs
              </Typography>
              <Typography
                variant="body1"
                color="white"
                sx={{ cursor: 'pointer', '&:hover': { color: colors.secondary } }}
                onClick={() => {
                  navigate(APP_ROUTES.Blog);
                  handleCloseDrawer();
                }}>
                RESOURCES
              </Typography>
              <Divider variant="fullWidth" sx={{ bgcolor: 'lightgray', my: 1 }} />
              <Typography
                variant="body1"
                color="white"
                sx={{ cursor: 'pointer', '&:hover': { color: colors.secondary } }}
                onClick={() => {
                  setOpen(true);
                  handleCloseDrawer();
                }}>
                LOG IN
              </Typography>
              <Typography
                variant="body1"
                color="white"
                sx={{ cursor: 'pointer', '&:hover': { color: colors.secondary } }}
                onClick={() => {
                  setOpen2(true);
                  handleCloseDrawer();
                }}>
                GET STARTED FREE
              </Typography>
            </Box>
          </Drawer>
        </Box>
      </Box>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
        <LoginPop open={open} setOpen={setOpen} />
        <SignUpPop open={open2} setOpen={setOpen2} />
      </GoogleOAuthProvider>
    </Box>
  );
};

export default Header;
