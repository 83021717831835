import {
  Box,
  Typography,
  Button,
  Card,
  CardMedia,
  Container,
  CardContent,
  Stack
} from '@mui/material';
import React, { useEffect } from 'react';
import colors from '../../components/common/colorConfig/colors';
import icon1 from '../../assets/image/creditcard.png';
import icon2 from '../../assets/image/users.png';
import icon3 from '../../assets/image/reward.png';
import reward from '../../assets/image/Pricing.png';
import arrow from '../../assets/image/arrow.png';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../utils/constants';
import tik from '../../assets/image/tick_circle.png';

const Partner = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <Box>
      {' '}
      <Box
        sx={{
          // minHeight: { xs: "72vh", sm: "60vh" },
          backgroundColor: colors.primary,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
          // resize: "both",
        }}>
        <Box
          height="auto"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            py: { xs: 5, sm: 10 }
          }}>
          <Typography
            variant="h2"
            color="white"
            sx={{
              background: 'linear-gradient(90deg, #06C7BB 0.21%, #FFF 39.69%)',
              backgroundClip: 'text',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent',
              textAlign: 'center'
            }}
            mx={2.5}
            fontSize={{ xs: 42, md: '3.75rem' }}>
            Join Our Partnership Program
          </Typography>
          <Typography
            variant="h6"
            color="white"
            my={4}
            fontWeight={400}
            mx={2}
            fontSize={{ xs: 16, md: '1.25rem', textAlign: 'center' }}>
            Join our partnership program and become part of a community that values education,
            equity, and empowering future
            <br />
            medical professionals.
          </Typography>
          <Button
            onClick={() => navigate(`${APP_ROUTES.Partner}?q=true`)}
            variant="contained"
            sx={{
              mb: 4,
              fontWeight: '700'
            }}>
            BECOME A PARTNER
          </Button>
        </Box>
      </Box>
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            p: 2,
            backgroundColor: 'white',
            borderRadius: '60px'
          }}>
          <Box
            component="img"
            src={arrow}
            height={30}
            width={17}
            sx={{
              px: 3.1,
              py: 2.3,
              border: '1px solid gray',
              borderRadius: '60px',
              backgroundColor: '#fff'
            }}
          />
        </Box>
      </Box>
      <Typography
        variant="h6"
        fontWeight={600}
        color={colors.secondary}
        my={4}
        py={4}
        fontSize={{ xs: 16, md: '1.25rem', textAlign: 'center' }}>
        HOW IT WORKS
      </Typography>
      <Box mt={4} pb={5}>
        <Container>
          <Box
            sx={{
              display: 'flex',
              justifyContent: {
                xs: 'center',
                lg: 'space-between'
              },
              flexWrap: { xs: 'wrap', lg: 'nowrap' },
              mt: 2,
              pb: 4,
              gap: 3
            }}>
            <Card
              sx={{
                width: { xs: '80%', sm: '446px' },
                // width: "564px",
                borderRadius: '20px ',
                backgroundColor: colors.lighter
              }}>
              <CardMedia
                component="img"
                image={icon1}
                sx={{
                  height: { xs: 90, sm: 140 },
                  width: { xs: 120, sm: 170 },
                  margin: '0 auto',
                  mt: 3
                }}
              />
              <CardContent>
                <Typography
                  variant="h6"
                  fontWeight={600}
                  mx={1}
                  fontSize={{ xs: 16, md: '1.25rem', textAlign: 'center' }}>
                  Sign up as an influencer partner and receive your unique referral link and code.
                </Typography>
              </CardContent>
            </Card>
            <Card
              sx={{
                width: { xs: '80%', sm: '446px' },
                // width: "564px",
                borderRadius: '20px ',
                backgroundColor: colors.lighter
              }}>
              <CardMedia
                component="img"
                image={icon2}
                sx={{
                  height: { xs: 100, sm: 140 },
                  width: { xs: 120, sm: 170 },
                  margin: '0 auto',
                  mt: 3
                }}
              />
              <CardContent>
                <Typography
                  variant="h6"
                  fontWeight={600}
                  mx={1}
                  fontSize={{ xs: 16, md: '1.25rem', textAlign: 'center' }}>
                  Share your link or code with your followers and encourage them to sign up for Arc.
                </Typography>
              </CardContent>
            </Card>
            <Card
              sx={{
                width: { xs: '80%', sm: '446px' },
                // width: "564px",
                borderRadius: '20px ',
                backgroundColor: colors.lighter
              }}>
              <CardMedia
                component="img"
                image={icon3}
                sx={{
                  height: { xs: 90, sm: 140 },
                  width: { xs: 120, sm: 170 },
                  margin: '0 auto',
                  mt: 3
                }}
              />
              <CardContent>
                <Typography
                  variant="h6"
                  fontWeight={600}
                  mx={1}
                  fontSize={{ xs: 16, md: '1.25rem', textAlign: 'center' }}>
                  For every person who signs up using your link or code, they will get $10 off their
                  first month, and you&apos;ll earn a $10 commission for every month users remain
                  active!
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          // minHeight: { xs: "72vh", sm: "60vh" },
          backgroundColor: colors.primary,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
          // resize: "both",
        }}>
        <Box
          height="auto"
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(auto-fit,1fr)',
              sm: '1fr 1fr'
            },
            alignItems: 'center',
            // justifyContent: "space-between",
            gap: 3,
            mx: 2,
            // alignItems: "center",
            py: { xs: 5, sm: 10 }
          }}>
          <Box
            // width={{ xs: "70%", md: "80%", lg: "60%" }}
            width="90%"
            sx={{ justifySelf: 'center' }}>
            <Typography variant="h2" color="white" mx={1} fontSize={{ xs: 42, md: '3.75rem' }}>
              Why partner with Arc?
            </Typography>
            <Typography
              variant="h6"
              color="white"
              my={4}
              fontWeight={400}
              mx={1}
              fontSize={{ xs: 16, md: '1.25rem' }}>
              Partnering with Arc offers a unique opportunity to make a positive impact, earn
              commissions, and provide your followers with valuable MCAT preparation resources. Join
              us and together, let&apos;s empower future medical professionals and make a difference
              in their academic success.
            </Typography>
            <Button
              onClick={() => navigate(`${APP_ROUTES.Partner}?q=true`)}
              variant="contained"
              sx={{
                mb: 4,
                fontWeight: '700'
              }}>
              BECOME A PARTNER
            </Button>
          </Box>
          <Box margin="0 auto" width={'90%'}>
            <Box mx={1}>
              <Stack direction="row" alignItems="center" gap={1} mb={2}>
                <Box component="img" src={tik} width={25} sx={{ mr: 1 }} />
                <Typography variant="h6" component="span" color="white">
                  Empower your followers
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" gap={1} mb={2}>
                <Box component="img" src={tik} width={25} sx={{ mr: 1 }} />
                <Typography variant="h6" component="span" color="white">
                  Lucrative Commission Structure
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" gap={1} mb={2}>
                <Box component="img" src={tik} width={25} sx={{ mr: 1 }} />
                <Typography variant="h6" component="span" color="white">
                  Value and Quality
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" gap={1} mb={2}>
                <Box component="img" src={tik} width={25} sx={{ mr: 1 }} />
                <Typography variant="h6" component="span" color="white">
                  Dedicated Support
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" gap={1} mb={2}>
                <Box component="img" src={tik} width={25} sx={{ mr: 1 }} />
                <Typography variant="h6" component="span" color="white">
                  Join a Purpose-Driven Community
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Partner;
