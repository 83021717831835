import { Box, Card, CardMedia, Typography } from '@mui/material';
import React from 'react';
import colors from '../colorConfig/colors';
import circle from '../../../assets/image/circles.png';
import { wrap } from 'module';

const ImageCards = (props: any) => {
  const { left, title1, title2, text } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        gap: { xs: 5, md: 15 },
        flexWrap: 'wrap',
        flexDirection: left ? 'row' : 'row-reverse',
        justifyContent: 'center'
      }}>
      <Card
        sx={{
          width: { xs: '80%', sm: '546px' },
          // width: "564px",
          borderRadius: '20px '
        }}>
        <CardMedia
          sx={{ height: { xs: 230, sm: 350 }, backgroundColor: colors.lighter }}
          // image="/static/images/cards/contemplative-reptile.jpg"
        />
      </Card>
      <Box
        sx={{
          width: { xs: '80%', sm: '546px' },
          flexWrap: 'wrap',
          margin: '0 auto'
        }}>
        <Typography
          variant="body1"
          fontWeight={700}
          fontSize={18}
          sx={{
            background: 'linear-gradient(90deg, #1D217D 11.57%, #06C7BB 87.74%)',
            backgroundClip: 'text',
            '-webkit-background-clip': ' text',
            '-webkit-text-fill-color': 'transparent',
            display: 'inline-block'
          }}>
          {title1}
        </Typography>
        <Typography variant="h3" fontWeight={600} mt={3} fontSize={{ xs: 28, md: '3rem' }}>
          {title2}
        </Typography>
        <Typography variant="body1" color={colors.light} mt={3}>
          {text}
        </Typography>
        <Box component="img" src={circle} mt={3} />
      </Box>
    </Box>
  );
};

export default ImageCards;
