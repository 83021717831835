import {
  Box,
  Button,
  Card,
  CardActions,
  CssBaseline,
  Dialog,
  Divider,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import colors from '../../components/common/colorConfig/colors';
import google from '../../assets/image/google.png';
import microsoft from '../../assets/image/microsoft.png';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import HttpService from '../../hooks/Https-services';
import axios from 'axios';
import GoogleCalendar from './Calendar';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { API_ROUTES, APP_ROUTES } from '../../utils/constants';
import { toast } from 'react-toastify';
import useCommonDetails from '../../hooks/useCommonDetails';
import commonDetailSlice from '../../store/reducers/commonReducer';
import { useDispatch } from 'react-redux';
import moment from 'moment';
// import { GoogleAuth } from '@react-oauth/google';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid'; // a plugin!
import { LoadingButton } from '@mui/lab';
const Onboard = () => {
  const [open, setOpen] = useState(false);
  const [stapper, setStapper] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [gacc, setGacc] = useState(false);
  const [macc, setMacc] = useState(false);
  const [email, setEmail] = useState([]);
  const userData = useCommonDetails();
  const navigate = useNavigate();
  const [calendarId, setCalendarId] = useState('');
  const dispatch = useDispatch();
  const [searchParams, setSearcParams] = useSearchParams();
  const code = searchParams.get('code');
  const [refferal, setRefferal] = useState('');
  const [events, setEvents] = useState<any>([]);
  const formateDate = (date: any) => {
    const timeDate = moment(date);

    // Convert to the user's current timezone and format

    const formattedDate = timeDate.utc().format('YYYY-MM-DDTHH:mm:ss');
    console.log(formattedDate);
    return formattedDate;
  };
  async function getEntries(data: any) {
    delete data.tokens;
    dispatch(commonDetailSlice.actions.removecommonDetails());
    dispatch(commonDetailSlice.actions.setcommonDetails(data));
  }
  console.log('email ::', email);
  useEffect(() => {
    userData?.is_onboarded ? setOpen(false) : setOpen(true);
    // setOpen(true);
    if (code) {
      setStapper(1);
      console.log(code);
      setMsCalendar(code);
      searchParams.delete('session_state');
      searchParams.delete('code');
      setSearcParams(searchParams);
    }
    getCalendar();
  }, []);
  useEffect(() => {
    if (userData?.is_onboarded) {
      navigate(APP_ROUTES.ScheduleHome);
    }
    createSevenDaysEvent();
  }, [userData]);
  console.log(isLoading);
  const sendReferral = async () => {
    try {
      const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!pattern.test(refferal)) {
        return toast.error('Invalid email');
      }
      setIsLoading(true);
      const res = await HttpService.post(API_ROUTES.SendReferralEmail, { email: refferal });
      toast.success('Email sent successfully');
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const createSevenDaysEvent = () => {
    // Get the current date and time
    const now = moment();

    // Calculate the start of the week (Monday) at 11 AM
    const startOfWeek = now.clone().startOf('isoWeek').hour(11).minute(0).second(0);

    // Create an array to store the schedule for 7 days
    const weekSchedule = [];

    for (let i = 0; i < 6; i++) {
      const dayStart = startOfWeek.clone();
      const dayEnd = startOfWeek.clone().hour(15);

      weekSchedule.push({
        id: i + 1,
        title: ``,
        start: dayStart.format('YYYY-MM-DDTHH:mm:ss'),
        end: dayEnd.format('YYYY-MM-DDTHH:mm:ss')
      });

      // Move to the next day
      startOfWeek.add(1, 'days');
    }
    setEvents(weekSchedule);
  };
  const setCalendar = async (id: any) => {
    try {
      setIsLoading(true);
      const data = {
        prefered_time: events,
        notification_setting: false
      };
      const res = await HttpService.post(API_ROUTES.SetPreference.replace(':id', id), data);
      toast.success('Onboarding successfull');
      getEntries(res.data.data);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getCalendar = async () => {
    try {
      const res = await HttpService.get(API_ROUTES.GetGoogleCalendar);
      const tempEmail: any = [];
      res.data.data.map((ele: any) => {
        const id = ele.user_calendar_sid;
        const email = ele.user_email;
        tempEmail.push({ id: id, email: email });
      });
      setEmail(tempEmail);
    } catch (error: any) {
      console.log(error);
    }
  };
  const setMsCalendar = async (code: any) => {
    try {
      setIsLoading2(true);
      const res = await HttpService.post(API_ROUTES.RegisterMsCalendar, { code: code });
      toast.success('Calendar added successfully');
      getCalendar();
      setIsLoading2(false);
    } catch (error: any) {
      setIsLoading2(false);
      console.log(error);
    }
  };
  const getmsaltokens = () => {
    window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_MICROSOFT_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_REDIRECT_URL_ONBOARD}&response_mode=query&scope=offline_access user.read calendars.read Calendars.ReadWrite&code_challenge=${process.env.REACT_APP_MICROSOFT_CODECALLANGE}&code_challenge_method=S256&prompt=consent`;
  };
  const delEvent = (info: any) => {
    const newEvent: any = events.filter((event: any) => event.id != info.event.id);
    setEvents(newEvent);
  };
  return (
    <Dialog
      open={open}
      maxWidth="md"
      //   onClose={() => setOpen(false)}
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: 'transparent', // Set the background color to transparent
          boxShadow: 'none', // Remove the box shadow
          borderRadius: '0' // Remove the border radius
        }
      }}>
      {stapper == 0 && (
        <Card
          sx={{
            p: { xs: 3, sm: 4 },
            px: { xs: 3, sm: 8 },
            maxWidth: '500px',
            borderRadius: '20px',
            textAlign: { xs: 'center', sm: 'left' }
          }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="h5" fontWeight={700}>
              Hi User!
            </Typography>
            <Typography variant="body1" color="gray">
              Welcome to Arc. Your one-stop shop to optimize your study routine and student life. To
              get started, let’s create your custom plan of action!
            </Typography>
            <Typography variant="h6" fontWeight={700}>
              Connect all your calendars to Arc
            </Typography>
            <Typography variant="body1" color="gray">
              View and manage all your calendars from one place. Help Arc make sense of your time.
              We’ll never sell or share your data.
            </Typography>
            <Typography
              variant="body1"
              color={colors.secondary}
              onClick={() => navigate(APP_ROUTES.Privacy)}
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
              We’ll never sell or share your data.
            </Typography>
          </Box>
          <CardActions sx={{ flexDirection: 'column', gap: 1.5 }}>
            <Button
              variant="contained"
              size="large"
              sx={{ margin: '0 auto', mt: 2 }}
              onClick={() => setStapper(1)}>
              + Connect Account
            </Button>

            <Typography variant="body2" color={colors.secondary} textAlign="center">
              I’ve connected all calendars I use
            </Typography>
          </CardActions>
        </Card>
      )}

      {stapper == 1 && (
        <Card
          sx={{
            p: { xs: 3, sm: 4 },
            px: { xs: 3, sm: 8 },
            maxWidth: '500px',
            borderRadius: '20px'
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: { xs: 'center', md: 'baseline' },
              gap: email.length > 0 ? 2 : 0,
              justifyContent: 'center',
              flexDirection: { xs: 'column-reverse', md: 'row' }
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1
              }}>
              {/* <GoogleCalendar /> */}
              <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CALENDAR_ID || ''}>
                <GoogleCalendar getCalendar={getCalendar} />
              </GoogleOAuthProvider>

              <LoadingButton
                loading={isLoading2}
                variant="outlined"
                color="secondary"
                sx={{
                  border: '1px solid',
                  fontWeight: 700,
                  whiteSpace: 'nowrap'
                }}
                onClick={getmsaltokens}>
                <Box component="img" src={microsoft} width={16} mr={2} />
                Continue with Microsoft
              </LoadingButton>
            </Box>
            {email.length > 0 && (
              <Divider
                orientation="vertical"
                flexItem
                sx={{ height: '150px', display: { xs: 'none', md: 'unset' } }}
              />
            )}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {email.length > 0 &&
                email.map((ele: any, idx) => (
                  <Typography
                    key={idx}
                    variant="body1"
                    sx={{
                      backgroundColor: colors.secondary,
                      color: 'white',
                      fontWeight: 600,
                      px: 2,
                      py: 0.5,
                      borderRadius: '10px'
                    }}>
                    {ele.email}
                  </Typography>
                ))}
              {/* {macc && (
                <Typography
                  variant="h6"
                  sx={{
                    backgroundColor: colors.secondary,
                    color: 'white',
                    fontWeight: 700,
                    px: 2,
                    borderRadius: '10px'
                  }}>
                  kathietest2@testuser.com
                </Typography>
              )} */}
            </Box>
          </Box>

          <CardActions sx={{ flexDirection: 'column', gap: 1.5 }}>
            {(email.length > 0 || macc) && (
              <Button
                variant="contained"
                sx={{ margin: '0 auto', mt: 2 }}
                onClick={() => setStapper(2)}>
                Continue
              </Button>
            )}
            <Box textAlign="center">
              <Typography variant="body1" color={colors.secondary}>
                I’ve connected all calendars I use
              </Typography>
              <Typography variant="caption" mt={1.5}>
                *Please connect at least one calendar
              </Typography>
            </Box>
          </CardActions>
        </Card>
      )}

      {stapper == 2 && (
        <Card
          sx={{
            p: { xs: 3, sm: 4 },
            px: { xs: 3, sm: 8 },
            maxWidth: '500px',
            borderRadius: '20px'
          }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="h5" fontWeight={700}>
              Select main calendar
            </Typography>
            <Typography variant="body1" color="gray">
              Your main calendar is used as the default when creating events.
            </Typography>
            {email.length > 0 &&
              email.map((ele: any) => (
                <Button
                  key={ele}
                  variant="contained"
                  size="large"
                  onClick={() => {
                    setStapper(3);
                    setCalendarId(ele.id);
                  }}
                  sx={{ textTransform: 'none' }}>
                  {ele.email}
                  <ArrowForwardIosIcon
                    fontSize="small"
                    sx={{ marginLeft: 'auto', fontSize: '16px' }}
                  />
                </Button>
              ))}
          </Box>
          <CardActions sx={{ flexDirection: 'column', gap: 1.5 }}>
            <Button sx={{ margin: '0 auto', mt: 2 }} onClick={() => setStapper(1)}>
              Back
            </Button>
          </CardActions>
        </Card>
      )}

      {stapper == 3 && (
        <Card
          sx={{
            p: { xs: 3, sm: 4 },
            px: { xs: 3, sm: 8 },
            maxWidth: '500px',
            borderRadius: '20px'
          }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="h5" fontWeight={700}>
              Invite your friends!{' '}
              <Typography variant="body2" component="span" color="gray">
                (Optional)
              </Typography>
            </Typography>
            <Typography variant="body1" color="gray">
              Start referring and saving today! Share Arc with your friends and give them $10 off
              their first month &apos;s subscription. For every friend who signs up and becomes an
              active member, you&apos;ll unlock $10 off your monthly subscription. Keep referring
              friends, and when enough of them join, your membership will be free!
            </Typography>

            <TextField
              variant="outlined"
              placeholder="Add email"
              InputProps={{
                endAdornment: (
                  <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    size="small"
                    onClick={sendReferral}
                    sx={{ textTransform: 'none', px: 5, fontSize: '15px' }}>
                    Send
                  </LoadingButton>
                )
              }}
              onChange={(e) => setRefferal(e.target.value)}
            />
          </Box>
          <CardActions sx={{ flexDirection: 'column', gap: 1.5 }}>
            <Button sx={{ margin: '0 auto', mt: 2 }} onClick={() => setStapper(4)}>
              Next
            </Button>
          </CardActions>
        </Card>
      )}

      {stapper == 4 && (
        <Card
          sx={{
            p: { xs: 3, sm: 4 },
            px: { xs: 3, sm: 8 },
            borderRadius: '20px'
          }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="h5" fontWeight={700}>
              Select your study hours
            </Typography>
            <Typography variant="body1" color="gray">
              Drag or click to change your study hours. Your tasks wll be scheduled inside study
              hours by default. These hours can be configured in Settings.
            </Typography>
          </Box>
          <Box>
            <FullCalendar
              eventContent={(arg) => {
                // Custom event rendering logic here

                return (
                  <Box sx={{ textAlign: 'right', m: 0.5 }} onClick={() => delEvent(arg)}>
                    ❌
                  </Box>
                );
              }}
              headerToolbar={false}
              dayHeaderFormat={{ weekday: 'short' }}
              displayEventTime={false}
              allDaySlot={false}
              events={events}
              hiddenDays={[0]}
              height={'400px'}
              editable={true}
              scrollTime={'11:00:00'}
              droppable={false}
              // eventClick={(info) => {
              //   console.log('info', events);
              //   console.log('info-id', info.event.id);
              //   const newEvent: any = events.filter((event: any) => event.id != info.event.id);
              //   setEvents(newEvent);
              // }}
              eventChange={(info: any) => {
                console.log(info.event.id);
                console.log(events);
                const findIndex = events.findIndex((el: any) => info.event.id == el?.id);
                console.log(info.event.start);
                events[findIndex].start = formateDate(info.event.start);
                events[findIndex].end = formateDate(info.event.end);
                setEvents(events);
              }}
              selectable={true}
              select={(e) => {
                setEvents([
                  ...events,
                  {
                    id: events[events.length - 1].id + 1,
                    start: formateDate(e.start),
                    end: formateDate(e.end)
                  }
                ]);
              }}
              eventOverlap={false}
              eventColor={'#06C7BB'}
              timeZone={'UTC'}
              plugins={[interactionPlugin, timeGridPlugin]}
              initialView="timeGridWeek"
            />
          </Box>

          <CardActions sx={{ flexDirection: 'column', gap: 1.5 }}>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              sx={{ margin: '0 auto', mt: 2 }}
              onClick={() => {
                // setStapper(5);
                setCalendar(calendarId);
              }}>
              Next
            </LoadingButton>
          </CardActions>
        </Card>
      )}

      {/* {stapper == 5 && (
        <Card
          sx={{
            p: { xs: 3, sm: 4 },
            px: { xs: 3, sm: 8 },
            width: '500px',
            borderRadius: '20px'
          }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="h5" fontWeight={700}>
              Start your first plan
            </Typography>
            <Typography variant="body1" color="gray">
              Create a project that you will begin working on.
            </Typography>
            <Button
              variant="contained"
              size="large"
              onClick={() => setOpen(false)}
              sx={{ width: '500px' }}>
              Arc Academic MCAT Prep
              <ArrowForwardIosIcon sx={{ marginLeft: 'auto', fontSize: '16px' }} />
            </Button>
          </Box>
          <CardActions sx={{ flexDirection: 'column', gap: 1.5 }}>
            <Button sx={{ margin: '0 auto', mt: 2 }} onClick={() => setStapper(4)}>
              Back
            </Button>
          </CardActions>
        </Card>
      )} */}
    </Dialog>
  );
};

export default Onboard;
