import React, { useEffect } from 'react';
import colors from '../../components/common/colorConfig/colors';
import { Box, Typography, Card, IconButton } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router-dom';

const Privacy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  const navigate = useNavigate();
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: colors.primary,
          pb: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'baseline'
        }}>
        <Box
          sx={{
            width: '80%',
            margin: '0 auto'
          }}>
          <Typography
            variant="h2"
            color="white"
            mx={{ xs: 0, sm: 1 }}
            my={{ xs: 3, sm: 5 }}
            fontSize={{ xs: 42, md: '3.75rem' }}
            // textAlign={{ xs: 'center', sm: 'left' }}
          >
            {/* <IconButton onClick={() => navigate(-1)}>
              <KeyboardBackspaceIcon sx={{ fontSize: { xs: 42, md: '3.75rem' }, color: 'white' }} />
            </IconButton> */}
            Privacy
          </Typography>
        </Box>
        <Card
          sx={{
            width: '80%',
            margin: '0 auto',
            // height: "364px",
            // width: "564px",
            backgroundColor: colors.lighter,
            borderRadius: '20px '
            // backgroundColor: "red",
          }}>
          <Box
            sx={{
              margin: 'auto',
              my: 3.5,
              mx: { xs: 2, sm: 4, md: 6 },
              maxHeight: '100vh',
              overflowY: 'auto'
            }}>
            <Typography variant="h6" fontSize={{ xs: 16, md: '1.25rem' }} mx={1}>
              <Typography variant="h6" fontWeight={700} fontSize={{ xs: 16, md: '1.25rem' }}>
                Terms and Conditions of Use
              </Typography>
              These terms and conditions of use are a legal agreement (the “Agreement”) between you
              and Blueprint Test Preparation, a subsidiary of Blueprint Education Holdings, LLC
              (“Blueprint”, “we”, “us”, or “our”). Your use of or participation in certain
              additional services may be subject to additional terms, and such terms will be
              presented to you when you sign up to use such services. Blueprint’s Service consist of
              the following, without limitation: an online resource for test preparation, including
              all website(s) and features, functionalities (collectively, the “Site”), and user
              interfaces, as well as all content and software associated with our Service. In
              addition, Blueprint frequently tests various aspects of the Service, including the
              Site, user interfaces, course offerings, delivery, and pricing. Blueprint reserves the
              right to include you or exclude you from these tests, with or without your explicit
              consent. If you are a resident of the United States (including its possessions and
              territories), you agree to the Arbitration Agreement and class action waiver described
              below to resolve any disputes with Blueprint (except for matters that may be taken to
              small claims court). By accessing, using, visiting, or browsing the Blueprint Service,
              you accept and agree to this Agreement. If you do not agree to this Agreement, do not
              use the Blueprint Service. This Agreement is subject to change by Blueprint in its
              sole discretion at any time, with or without notice. Your continued use of the Service
              after the posting of revisions to this Agreement constitutes your acceptance of such
              revisions. Please consult the end of this Agreement to determine when the Agreement
              was last revised.
              <br />
              <br />
              <Typography variant="h6" fontWeight={700} fontSize={{ xs: 16, md: '1.25rem' }}>
                ARTICLE I: USE OF ARCACADEMIC TEST PREPARATION
              </Typography>
              Minimum Age: You must be at least 18 years old to access and register for the Service.
              The Service is open to United States and international residents. Terms and
              Termination: Subject to this section, this Agreement will remain in full force and
              effect while you use the Service and in perpetuity unless terminated in accordance
              with this Agreement. Upon termination of this Agreement, your right to access and use
              the Service and any User Content (as defined below) you created will terminate
              immediately. Either you or Blueprint may terminate this Agreement at any time, for any
              reason or no reason, without explanation, effective upon sending written notice to the
              other party. Blueprint may also immediately suspend your access to the Service,
              without notice or explanation, for any reason or no reason. We also reserve the right
              to remove your account information or data from the Service and any other records at
              any time at our sole discretion.
              <br />
              <br />
              Exclusive Use: Your account is for your personal use only. You agree not to allow
              others to access your Blueprint online account for any reason. You may not authorize
              others to use your account, and you may not assign or otherwise transfer your account
              to any other person or entity. You acknowledge that Blueprint is not responsible for
              third-party access to your account that results from theft or misappropriation of your
              mobile devices, usernames, or passwords. Geographic Limitations: Blueprint’s Service
              is intended for use in the United States and internationally. You will only use the
              Service in a manner consistent with this Agreement and any and all applicable local,
              state, national and international laws and regulations, including, but not limited to,
              United States export control laws. You shall not be, and agree that you are not
              located in, under the control of, or a national or resident of any country which the
              United States has (i) embargoed goods, (ii) identified as a Specially Designated
              National, or (iii) placed on the Commerce Department’s Table of Deny Orders. To the
              extent that the Service is not legal in your jurisdiction, you may not use the
              Service. Information Submitted: You are solely responsible for, and assume all
              liability regarding, (i) the information and content you contribute to the Service and
              (ii) the information and content you post, transmit, publish, or otherwise make
              available (hereinafter post) through the Service, if applicable.
            </Typography>
          </Box>
          {/* <Typography
            color="primary.main"
            textAlign="center"
            pb={4}
            mt={-2}
            onClick={() => navigate(-1)}
            sx={{ textDecoration: 'underline', textAlign: 'center', cursor: 'pointer' }}>
            Back
          </Typography> */}
        </Card>
      </Box>
    </Box>
  );
};

export default Privacy;
