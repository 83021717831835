import {
  Button,
  Card,
  Dialog,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import colors from '../colorConfig/colors.js';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../../utils/constants.js';
import HttpService from '../../../hooks/Https-services';
import { API_ROUTES } from '../../../utils/constants';
import { toast } from 'react-toastify';
import { useTimerContext } from '../../../context/timerValue';
function PomorodoTimerPopUp(props: any) {
  const { pomodoroTimer, setPomodoroTimer } = props;

  const {
    timerValue,
    setTimerValue,
    handleDeadlineChange,
    pauseTimer,
    resetTimer,
    startTimer,
    getUserTimer,
    long,
    short,
    focus,
    timerInterval,
    deadline
  }: any = useTimerContext(); // State to hold timer value

  useEffect(() => {
    getUserTimer();
  }, []);

  return (
    <Dialog onClose={() => setPomodoroTimer(false)} open={pomodoroTimer} maxWidth={'xs'} fullWidth>
      <Card
        sx={{
          padding: '1.5rem 2rem !important',

          backgroundColor:
            deadline === 'Focus'
              ? `${colors.secondaryLighter}`
              : deadline === 'Short'
              ? `${colors.redLighter}`
              : `${colors.white}`
        }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} style={{ display: 'flex' }}>
            <ToggleButtonGroup
              color="primary"
              value={deadline}
              exclusive
              fullWidth
              aria-label="Platform"
              // onChange={(event, value) => handleDeadlineChange(value)}
              sx={{
                display: 'flex',
                '.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
                  borderLeft: '0px',
                  border: `1px solid ${colors.primary}`
                },
                '.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
                  borderLeft: '0px',
                  border: `1px solid ${colors.primary}`
                },
                gap: 1
              }}>
              <ToggleButton
                value="Focus"
                onClick={(event, value) => handleDeadlineChange(value)}
                className={deadline === 'Focus' ? 'activeButton' : ''}
                size="small"
                sx={{
                  backgroundColor: 'transparent',
                  color: colors.primary,
                  '&:hover': {
                    backgroundColor: 'transparent'
                  },
                  '&.activeButton': {
                    backgroundColor: colors.primary
                  },
                  '&.activeButton:hover': {
                    backgroundColor: colors.primary
                  },
                  borderRadius: '10px !important'
                }}>
                <Typography
                  variant="body2"
                  sx={{
                    display: 'flex',
                    textTransform: 'none',
                    color: deadline === 'Focus' ? 'white' : 'inherit'
                  }}>
                  FOCUS
                </Typography>
              </ToggleButton>
              <ToggleButton
                value="Short"
                onClick={(event, value) => handleDeadlineChange(value)}
                className={deadline === 'Short' ? 'activeButton' : ''}
                size="small"
                sx={{
                  backgroundColor: 'transparent',
                  color: colors.primary,
                  '&:hover': {
                    backgroundColor: 'transparent'
                  },
                  '&.activeButton': {
                    backgroundColor: colors.primary
                  },
                  '&.activeButton:hover': {
                    backgroundColor: colors.primary
                  },
                  borderRadius: '10px !important'
                }}>
                <Typography
                  variant="body2"
                  sx={{
                    display: 'flex',
                    textTransform: 'none',
                    color: deadline === 'Short' ? 'white' : 'inherit'
                  }}>
                  SHORT BREAK
                </Typography>
              </ToggleButton>
              <ToggleButton
                value="Long"
                onClick={(event, value) => handleDeadlineChange(value)}
                className={deadline === 'Long' ? 'activeButton' : ''}
                size="small"
                sx={{
                  backgroundColor: 'transparent',
                  color: colors.primary,
                  '&:hover': {
                    backgroundColor: 'transparent'
                  },
                  '&.activeButton': {
                    backgroundColor: colors.primary
                  },
                  '&.activeButton:hover': {
                    backgroundColor: colors.primary
                  },
                  borderRadius: '10px !important'
                }}>
                <Typography
                  variant="body2"
                  sx={{
                    display: 'flex',
                    textTransform: 'none',
                    color: deadline === 'Long' ? 'white' : 'inherit'
                  }}>
                  LONG BREAK
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ display: 'flex', justifyContent: 'center' }}
            sx={{ my: '20px' }}>
            <Typography variant="h1" sx={{ fontWeight: 400, fontSize: '80px' }}>
              {timerValue}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Link to={APP_ROUTES.Pomodoro} onClick={() => setPomodoroTimer(false)}>
              <SettingsOutlinedIcon />
            </Link>

            {deadline === 'Focus' ? (
              <Button
                color="primary"
                onClick={() => {
                  timerInterval === null ? startTimer(deadline) : pauseTimer();
                }}
                sx={{ padding: '8px 35px', mx: '10px', borderRadius: '10px' }}
                variant="contained">
                {timerInterval === null ? 'Start' : 'Pause'}
              </Button>
            ) : (
              <Button
                color="primary"
                onClick={() => {
                  timerInterval === null ? startTimer(deadline) : pauseTimer();
                }}
                sx={{ padding: '8px 35px', mx: '10px', borderRadius: '10px' }}
                variant="contained">
                {timerInterval === null ? 'Start' : 'Pause'}
              </Button>
            )}

            <SkipNextIcon onClick={resetTimer} style={{ cursor: 'pointer' }} />
          </Grid>
        </Grid>
      </Card>
    </Dialog>
  );
}

export default PomorodoTimerPopUp;
