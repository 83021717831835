import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Subscription from './pages/Subscription';
import BlogPostPage from './pages/BlogPostPage';
import { APP_ROUTES } from './utils/constants';
import PostPage from './pages/PostPage';
import ContactUs from './pages/ContactUs';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Refer from './pages/Refer';
import NoPageFound from './pages/NoPageFound';
import PublicRoute from './RouteAccessor/PublicRoute';
import Onboard from './pages/Onboard';
import PrivateRoute from './RouteAccessor/PrivateRoute';
import AccountSetting from './pages/AccountSetting';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VerifyEmail from './pages/VerifyEmail';
import Schedule from './pages/Schedule';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Partner from './pages/Partners';

function App() {
  // window.addEventListener('popstate', (event) => {
  //   if (event.state && event.state.param) {
  //     var currentURL = window.location.href;
  //     var index = currentURL.lastIndexOf('&');
  //     var newURL = currentURL.substring(0, index !== -1 ? index : currentURL.indexOf('?'));
  //     window.history.replaceState({}, '', newURL);
  //   } else {
  //     window.history.back();
  //   }
  // });
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        limit={2}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        icon={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
      <Routes>
        <Route
          path={APP_ROUTES.Landing}
          element={
            <PublicRoute>
              <LandingPage />
            </PublicRoute>
          }
        />
        <Route
          path={APP_ROUTES.Mcat}
          element={
            <PublicRoute>
              <Subscription />
            </PublicRoute>
          }
        />
        <Route
          path={APP_ROUTES.Blog}
          element={
            <PublicRoute>
              <BlogPostPage />
            </PublicRoute>
          }
        />
        <Route
          path={APP_ROUTES.Post}
          element={
            <PublicRoute>
              <PostPage />
            </PublicRoute>
          }
        />
        <Route
          path={APP_ROUTES.ContactUs}
          element={
            <PublicRoute>
              <ContactUs />
            </PublicRoute>
          }
        />
        <Route
          path={APP_ROUTES.Terms}
          element={
            <PublicRoute>
              <Terms />
            </PublicRoute>
          }
        />
        <Route
          path={APP_ROUTES.Privacy}
          element={
            <PublicRoute>
              <Privacy />
            </PublicRoute>
          }
        />
        <Route
          path={APP_ROUTES.Refer}
          element={
            <PublicRoute>
              <Refer />
            </PublicRoute>
          }
        />
        <Route
          path={APP_ROUTES.Partner}
          element={
            <PublicRoute>
              <Partner />
            </PublicRoute>
          }
        />
        <Route
          path={APP_ROUTES.Any}
          element={
            <PublicRoute>
              <NoPageFound />
            </PublicRoute>
          }
        />
        <Route
          path={APP_ROUTES.Onboard}
          element={
            <PrivateRoute>
              <Onboard />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.VerifyEmail}
          element={
            <PublicRoute>
              <VerifyEmail />
            </PublicRoute>
          }
        />
        <Route
          path={APP_ROUTES.MyProfile}
          element={
            <PrivateRoute>
              <AccountSetting />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.PlansAndBilling}
          element={
            <PrivateRoute>
              <AccountSetting />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.CalendarAndSchedules}
          element={
            <PrivateRoute>
              <AccountSetting />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.Pomodoro}
          element={
            <PrivateRoute>
              <AccountSetting />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.Referrals}
          element={
            <PrivateRoute>
              <AccountSetting />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.ScheduleHome}
          element={
            <PrivateRoute>
              <Schedule />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.SchedulePlans}
          element={
            <PrivateRoute>
              <Schedule />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
