import { Box } from '@mui/material';
import React from 'react';

import { APP_ROUTES } from '../utils/constants';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import { getCurrentAuth } from '../hooks/useAxiosPrivate';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import useCommonDetails from '../hooks/useCommonDetails';

const PublicRoute = ({ children }: { children: React.ReactElement }) => {
  const isAuthenticated = getCurrentAuth();
  const userDetail = useCommonDetails();
  let location = useLocation();
  if (isAuthenticated.access) {
    if (userDetail && !userDetail?.is_onboarded) {
      return <Navigate to={APP_ROUTES.Onboard} state={{ from: location }} replace />;
    } else {
      return <Navigate to={APP_ROUTES.ScheduleHome} state={{ from: location }} replace />;
    }
  }
  return (
    <Box
      sx={{
        flexGrow: 1,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column'
        // justifyContent: 'space-between'
      }}>
      <Header />
      <Box>{children}</Box>
      <Footer />
    </Box>
  );
};

export default PublicRoute;
