import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { APP_ROUTES } from '../utils/constants';
import MainContainer from '../components/common/MainContainer';
import { getCurrentAuth } from '../hooks/useAxiosPrivate';
import useCommonDetails from '../hooks/useCommonDetails';
import Onboard from '../pages/Onboard';

const PrivateRoute = ({ children }: { children: React.ReactElement }) => {
  const isAuthenticated = getCurrentAuth();
  const userData = useCommonDetails();

  let location = useLocation();
  if (!isAuthenticated.access) {
    return (
      <Navigate
        to={APP_ROUTES.Landing}
        state={{ from: location.pathname, search: location.search }}
        replace
      />
    );
  }

  if (!userData.is_onboarded) {
    return (
      <MainContainer>
        <Onboard />
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <>{children} </>
    </MainContainer>
  );
};

export default PrivateRoute;
