import { Dialog, Card, Typography, TextField, Box, Button, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import colors from '../colorConfig/colors';
import google from '../../../assets/image/google.png';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import axios from '../../../utils/axios';
import { API_ROUTES, APP_ROUTES } from '../../../utils/constants';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import HttpService from '../../../hooks/Https-services';
import { GoogleLogin } from '@react-oauth/google';
import { setAuth } from '../../../hooks/useRefreshToken';
import { useDispatch } from 'react-redux';
import commonDetailSlice from '../../../store/reducers/commonReducer';
import { LoadingButton } from '@mui/lab';

const SignUpPop = (props: any) => {
  const { open, setOpen } = props;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const referral = searchParams.get('referral_code');
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    formState: { errors }
  } = useForm();
  const dispatch = useDispatch();
  async function getEntries(data: any) {
    delete data.tokens;
    dispatch(commonDetailSlice.actions.removecommonDetails());
    dispatch(commonDetailSlice.actions.setcommonDetails(data));
  }
  useEffect(() => {
    setValue('code', referral);
  }, [referral]);

  const onSubmit = async (data: any) => {
    try {
      if (data.password !== data.confirmPassword) {
        toast.error('password does not match!');
        return;
      }
      setIsLoading(true);
      const dataBody: any = {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        password: data.password
      };
      if (referral) {
        dataBody['referral_code'] = referral;
      }
      const response: any = await HttpService.post(API_ROUTES.Register, dataBody);
      setIsLoading(false);
      setOpen(false);
      toast.success(response.data?.message);
      toast.success('Verfiy Email sent you successfully.');
      setOpen(false);
      reset();
      navigate(`${APP_ROUTES.Landing}?q=true`);
      reset();
    } catch (err: any) {
      setIsLoading(false);
      console.log(err);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        reset();
      }}
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: 'transparent', // Set the background color to transparent
          boxShadow: 'none', // Remove the box shadow
          borderRadius: '0' // Remove the border radius
        }
      }}>
      <Card
        sx={{ p: { xs: 3, sm: 4 }, px: { xs: 3, sm: 6 }, maxWidth: '416', borderRadius: '20px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography
            variant="h2"
            mb={1}
            fontWeight={700}
            fontSize={{ xs: '2.4rem', md: '2.8rem' }}>
            Sign Up
          </Typography>
          <TextField
            fullWidth
            label="First Name"
            variant="outlined"
            size="small"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              style: { color: 'black' }
            }}
            {...register('firstName', {
              required: 'This field is required.'
            })}
            sx={{
              mt: 2,
              mb: 1,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'black'
                }
              },
              '& .MuiFormLabel-root': {
                color: 'black !important'
              }
            }}
            error={!!errors.firstName}
            helperText={
              errors.firstName && (
                <Typography variant="caption" sx={{ color: 'red' }}>
                  {errors.firstName.message as any}
                </Typography>
              )
            }
          />
          <TextField
            fullWidth
            label="Last Name"
            variant="outlined"
            size="small"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              style: { color: 'black' }
            }}
            {...register('lastName', {
              required: 'This field is required.'
            })}
            sx={{
              mt: 2,
              mb: 1,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'black'
                }
              },
              '& .MuiFormLabel-root': {
                color: 'black'
              }
            }}
            error={!!errors.lastName}
            helperText={
              errors.lastName && (
                <Typography variant="caption" sx={{ color: 'red' }}>
                  {errors.lastName.message as any}
                </Typography>
              )
            }
          />
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            size="small"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              style: { color: 'black' }
            }}
            {...register('email', {
              required: 'This field is required.',
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: 'Please enter valid email.'
              }
            })}
            sx={{
              mt: 2,
              mb: 1,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'black'
                }
              },
              '& .MuiFormLabel-root': {
                color: 'black'
              }
            }}
            error={!!errors.email}
            helperText={
              errors.email && (
                <Typography variant="caption" sx={{ color: 'red' }}>
                  {errors.email.message as any}
                </Typography>
              )
            }
          />
          <TextField
            type="password"
            fullWidth
            label="Password"
            variant="outlined"
            size="small"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              style: { color: 'black' }
            }}
            {...register('password', {
              required: 'This field is required.',
              minLength: {
                value: 8,
                message: 'Password must be at least 8 characters long.'
              }
            })}
            sx={{
              mt: 2,
              mb: 1,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'black'
                }
              },
              '& .MuiFormLabel-root': {
                color: 'black'
              }
            }}
            error={!!errors.password}
            helperText={
              errors.password && (
                <Typography variant="caption" sx={{ color: 'red' }}>
                  {errors.password.message as any}
                </Typography>
              )
            }
          />
          <TextField
            type="password"
            fullWidth
            label="Confirm Password"
            variant="outlined"
            size="small"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              style: { color: 'black' }
            }}
            {...register('confirmPassword', {
              required: 'This field is required.'
            })}
            sx={{
              mt: 2,
              mb: 2,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'black'
                }
              },
              '& .MuiFormLabel-root': {
                color: 'black'
              }
            }}
            error={!!errors.confirmPassword}
            helperText={
              errors.confirmPassword && (
                <Typography variant="caption" sx={{ color: 'red' }}>
                  {errors.confirmPassword.message as any}
                </Typography>
              )
            }
          />
          {watch('code') && (
            <TextField
              fullWidth
              label="Referral Code"
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                style: { color: 'black' }
              }}
              {...register('code')}
              sx={{
                mt: 1,
                mb: 2,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'black'
                  }
                },
                '& .MuiFormLabel-root': {
                  color: 'black !important'
                }
              }}
            />
          )}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              type="submit"
              // onClick={handleSingup}
              sx={{
                fontWeight: '600',
                fontSize: '1rem',
                textTransform: 'none'
              }}>
              SIGN UP
            </LoadingButton>
          </Box>
          {/* <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '98%',
              margin: '0 auto',
              my: 1
            }}>
            <Divider sx={{ flexGrow: 1, border: '1px solid black' }} />
            <Typography
              variant="h6"
              fontWeight={700}
              mx={1}
              fontSize={{
                xs: 16,
                md: '1.25rem'
              }}>
              OR
            </Typography>
            <Divider sx={{ flexGrow: 1, border: '1px solid black' }} />
          </Box> */}
          {/* <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                handleLoginGoogle(credentialResponse);
              }}
              useOneTap
              size="medium"
              width="100%"
              containerProps={{
                style: {
                  width: '100% !important'
                }
              }}
              onError={() => {
                console.log('Login Failed');
              }}
            />
          </Box> */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Typography
              variant="body1"
              color={colors.secondary}
              onClick={() => {
                setOpen(false);
                navigate(`${location.pathname}?q=true`);
              }}
              fontSize={{ xs: '0.90rem' }}
              sx={{ textAlign: 'right', mt: 2, cursor: 'pointer' }}>
              Already have an account? Log In
            </Typography>
          </Box>
        </form>
      </Card>
    </Dialog>
  );
};

export default SignUpPop;
