import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Divider,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import colors from '../colorConfig/colors';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const Faqs = () => {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionClick = () => {
    setExpanded(!expanded);
  };
  const question = [
    { id: 1, que: ' How does ArcAcademic Work?' },
    { id: 2, que: 'Which MCAT section are covered?' },
    { id: 3, que: ' Can I use ArcAcademic with my other resoruces?' },
    { id: 4, que: "How comprehensive is ArcAcademic's content?" }
  ];
  const [accordions, setAccordions] = useState(question);
  const toggleAccordion = (id: any) => () => {
    setAccordions((prevAccordions) =>
      prevAccordions.map((accordion: any) =>
        accordion.id === id ? { ...accordion, expanded: !accordion.expanded } : accordion
      )
    );
  };
  return (
    <Card
      sx={{
        borderRadius: '20px',
        // width: { xs: "80%", md: "750px", lg: "auto" },
        boxShadow: '0 8px 6px rgba(0, 0, 0, 0.3)'
      }}>
      <CardContent sx={{ p: '22px !important ', px: '30px !important' }}>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            // mt={2}
            color={colors.primary}
            fontSize={{ xs: "0.90rem", md: "1.1rem" }}
            fontWeight={700}
          >
            How does ArcAcademic Work?
          </Typography>
          <AddCircleOutlineIcon
            sx={{ fontSize: "30px" }}
            onClick={handleAccordionClick}
          />
        </Box> */}

        {accordions.map((accordion: any, idx: any) => (
          <>
            <Accordion
              key={accordion.id}
              expanded={accordion.expanded}
              onChange={toggleAccordion(accordion.id)}
              sx={{ boxShadow: 'none', border: 'none', pt: idx == 0 ? 0 : 2 }}>
              <AccordionSummary
                expandIcon={<AddCircleOutlineIcon sx={{ fontSize: '30px', color: 'black' }} />}>
                <Typography
                  variant="body1"
                  // mt={2}
                  color={colors.primary}
                  fontSize={{ xs: '0.95rem', md: '1.1rem' }}
                  fontWeight={700}>
                  {accordion.que}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body1"
                  // mt={2}
                  color={colors.primary}
                  fontSize={{ xs: '0.92rem', md: '1rem' }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla fringilla mi nec
                  lectus suscipit ultrices.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Divider sx={{ pt: 1, borderColor: colors.secondary }} />
          </>
        ))}
      </CardContent>
    </Card>
  );
};

export default Faqs;
