import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  Collapse,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  CircularProgress
} from '@mui/material';
import React, { useState } from 'react';
import HttpService from '../../../../hooks/Https-services';
import { API_ROUTES } from '../../../../utils/constants';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';

const Calendars = (props: any) => {
  const { email, id, type, getCalendar } = props;
  const [openPlan, setOpenPlan] = useState(false);
  const [eventName, setEventName] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const deleteCalendar = async () => {
    try {
      setIsLoading(true);
      const res = await HttpService.del(API_ROUTES.DeleteCalendar.replace(':id', id));
      toast.success('Calendar Deleted');
      getCalendar();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const getSubCalendar = async () => {
    try {
      setLoading(true);
      const res = await HttpService.get(API_ROUTES.GetSubCalendar.replace(':id', id));
      const temp: any = [];
      res.data.data.map((data: any) => {
        if (type == 'google') {
          const name = data.summary;
          temp.push({ name: name });
        } else {
          const name = data.name;
          temp.push({ name: name });
        }
      });
      setEventName(temp);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleClickPlan = () => {
    if (!openPlan) {
      getSubCalendar();
    }
    setOpenPlan(!openPlan);
  };
  return (
    <Box sx={{ border: '1px solid black', borderRadius: '10px' }}>
      <List
        sx={{
          width: '100%'
        }}
        disablePadding
        component="nav"
        aria-labelledby="nested-list-subheader">
        <ListItemButton
          onClick={handleClickPlan}
          disableRipple
          sx={{
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }}>
          <ListItemText>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 400 }}>
                  {email}
                </Typography>
              </Box>
            </Box>
          </ListItemText>
          {openPlan ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openPlan} timeout="auto" unmountOnExit>
          <ListItemText>
            {!loading ? (
              <Box
                sx={{
                  mx: 5,
                  pb: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  gap: 1,
                  maxHeight: '300px',
                  overflow: 'auto'
                }}>
                {eventName.map((ele: any) => (
                  <Box sx={{ display: 'flex', alignItems: 'center' }} key={ele.name}>
                    <Checkbox />
                    <Typography>{ele.name}</Typography>
                  </Box>
                ))}
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  color="error"
                  sx={{ alignSelf: 'flex-end', mr: 2 }}
                  onClick={deleteCalendar}>
                  Remove Account
                </LoadingButton>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
                <CircularProgress size={36} sx={{ margin: '0 auto' }} />
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  color="error"
                  sx={{ alignSelf: 'flex-end', mr: 2 }}
                  onClick={deleteCalendar}>
                  Remove Account
                </LoadingButton>
              </Box>
            )}
          </ListItemText>
        </Collapse>
      </List>
    </Box>
  );
};

export default Calendars;
