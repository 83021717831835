import { Box, Button, TextField, Typography } from '@mui/material';
import React from 'react';
import logo from '../../../assets/image/icon_black1.png';
import colors from '../colorConfig/colors';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../utils/constants';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Box>
      <Box
        sx={{
          mx: { xs: 1, sm: 4, md: 2 },
          my: { xs: 1, sm: 2, md: 4 },
          display: 'flex',
          justifyContent: 'space-around',
          gap: 2,
          // alignItems: "baseline",
          flexDirection: { xs: 'column', sm: 'row' }
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: { xs: 'center', sm: 'unset' },
            gap: 2
          }}>
          <Box component="img" src={logo} width={{ xs: 180, sm: 250 }} />
          <Typography variant="body1" mx={0} mt={1}>
            Stay informed and get exclusive updates by <br />
            signing up for our newsletter
          </Typography>
          <Box mx={0} mt={1}>
            <TextField variant="outlined" size="small" sx={{ mr: -1, maxWidth: '250px' }} />
            <Button
              onClick={() => navigate(`${location.pathname}?q1=true`)}
              variant="contained"
              sx={{
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: '700'
              }}>
              Subsribe
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            alignItems: { xs: 'center', sm: 'unset' }
          }}>
          <Box
            sx={{
              display: 'flex',
              gap: 4,
              flex: 1,
              mt: 1,
              textAlign: { xs: 'center', sm: 'left' },
              mx: 1
            }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography component="span" fontWeight={600} fontSize={18}>
                Support
              </Typography>
              <Typography
                component="span"
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(APP_ROUTES.ContactUs)}>
                Contact Us
              </Typography>
              <Typography
                component="span"
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(APP_ROUTES.Mcat)}>
                FAQs
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography component="span" fontWeight={600} fontSize={18}>
                Legal
              </Typography>
              <Typography
                component="span"
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(APP_ROUTES.Terms)}>
                Terms of Service
              </Typography>
              <Typography
                component="span"
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(APP_ROUTES.Privacy)}>
                Privacy
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography component="span" fontWeight={600} fontSize={18}>
                Company
              </Typography>
              <Typography
                component="span"
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(APP_ROUTES.Refer)}>
                Referral Program
              </Typography>
              <Typography
                component="span"
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(APP_ROUTES.Partner)}>
                Partners
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 3,
              justifyContent: 'space-between'
            }}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <MusicNoteIcon />
              <InstagramIcon />
              <YouTubeIcon />
              <TwitterIcon />
            </Box>
            <Typography
              sx={{
                color: '#000000a1',
                textAlign: 'center',
                fontFamily: 'monospace !important'
              }}>
              Copyright 2023
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
