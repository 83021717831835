import FullCalendar from '@fullcalendar/react';
import { Box, Button } from '@mui/material';
import moment from 'moment';
import React, { createRef, useEffect, useRef, useState } from 'react';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Calendar } from '@fullcalendar/core';
import { calendar } from 'googleapis/build/src/apis/calendar';

const CalendarComp = (props: any) => {
  const { events, setEvent } = props;
  // const [newEvent, setNewEvent] = useState<any>([]);
  // useEffect(() => {
  //   setNewEvent(events);
  // }, [events]);
  // console.log(events);
  const fakeEvents: any = events.map((ele: any) => ({
    endTime: moment(ele.end).format('HH:mm'),
    daysOfWeek: [moment(ele.start).day()],
    startTime: moment(ele.start).format('HH:mm'),
    id: ele.id,
    title: ele?.title
  }));
  console.log(fakeEvents);
  console.log(events);
  const calendarRef = createRef<any>();
  const formateDate = (date: any) => {
    const timeDate = moment(date);
    // Convert to the user's current timezone and format
    const formattedDate = timeDate.utc().format('YYYY-MM-DDTHH:mm:ss');
    console.log(formattedDate);
    return formattedDate;
  };
  return (
    <Box>
      <FullCalendar
        headerToolbar={false}
        dayHeaderFormat={{ weekday: 'short' }}
        displayEventTime={false}
        allDaySlot={false}
        events={fakeEvents}
        hiddenDays={[0]}
        forceEventDuration={true}
        height={'400px'}
        editable={true}
        scrollTime={'11:00:00'}
        droppable={false}
        eventChange={(info: any) => {
          console.log(info);
          const data = [...events];
          const findIndex = data.findIndex((el: any) => info.event.id == el?.id);
          const updateData = {
            ...data[findIndex],
            start: formateDate(info.event.start),
            end: formateDate(info.event.end)
          };
          data[findIndex] = updateData;
          setEvent(data);
        }}
        eventOverlap={false}
        eventColor={'#06C7BB'}
        timeZone={'UTC'}
        plugins={[interactionPlugin, timeGridPlugin]}
        initialView="timeGridWeek"
      />
    </Box>
  );
};

export default CalendarComp;
