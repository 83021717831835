import {
  Button,
  Card,
  Dialog,
  Grid,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import HttpService from '../../../hooks/Https-services';
import { API_ROUTES, PriorityColor } from '../../../utils/constants';
import { toast } from 'react-toastify';
import colors from '../colorConfig/colors';
import TourIcon from '@mui/icons-material/Tour';
import AddIcon from '@mui/icons-material/Add';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

function CreateGroupPopUp(props: any) {
  const { getPlans, isEditGroup, datas, planData } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [planPriority, setPlanPriority] = useState<any>(null);
  const [selPlanPriorityGroup, setSelPlanPriorityGroup] = useState('Low');

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    unregister,
    formState: { errors }
  } = useForm();

  const getPriority = async () => {
    try {
      setIsLoading(true);
      const PriorityID = 1;
      const Priority = await HttpService.get(
        API_ROUTES.ApplicationObject.replace(':id', `${PriorityID}`)
      );
      setPlanPriority(Priority.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const onEditGroup = async (data: any) => {
    try {
      const dataBody = {
        group_title: data.title,
        priority: selPlanPriorityGroup
      };
      await HttpService.patch(
        API_ROUTES.UpdateGroup.replace(':id', `${datas.user_plan_group_sid}`),
        dataBody
      );
      reset();
      toast.success('Group updated successfully');
      setShowDialog(false);
      getPlans();
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitGroup = async (data: any) => {
    try {
      const dataBody = {
        plan_id: planData.plan_id,
        group_title: data.title,
        priority: selPlanPriorityGroup
      };
      await HttpService.post(API_ROUTES.CreateGroup, dataBody);
      reset();
      toast.success('Group created successfully');
      setShowDialog(false);
      getPlans();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (showDialog) {
      getPriority();

      if (isEditGroup) {
        setValue('title', datas?.group_title);
        setSelPlanPriorityGroup(datas?.priority);
      }
    }
  }, [showDialog]);

  return (
    <>
      {isEditGroup ? (
        <ModeEditOutlineIcon
          fontSize="small"
          sx={{ m: '0 10px 0 20px' }}
          onClick={() => setShowDialog(true)}
        />
      ) : (
        // <Button
        //   variant="outlined"
        //   sx={{
        //     borderRadius: '10px',
        //     color: colors.primary,
        //     borderColor: colors.primary,
        //     fontWeight: 'bold',
        //     '&:hover': { borderColor: `${colors.primary} !important` }
        //   }}
        //   onClick={() => setShowDialog(true)}>
        //   <AddIcon fontSize="small" sx={{ mr: 1 }} /> Add Group
        // </Button>
        <AddIcon fontSize="small" sx={{ mr: 1 }} onClick={() => setShowDialog(true)} />
      )}

      {!isEditGroup ? (
        <Dialog
          onClose={() => {
            setShowDialog(false);
          }}
          open={showDialog}
          maxWidth={'md'}
          fullWidth>
          <Card sx={{ padding: '1.5rem 2rem !important', overflowY: 'scroll' }}>
            <form onSubmit={handleSubmit(onSubmitGroup)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body1" sx={{ mb: '7px 0' }}>
                    Group Title
                  </Typography>
                  <TextField
                    size="small"
                    id="outlined-basica"
                    fullWidth
                    variant="outlined"
                    placeholder="Group 1"
                    {...register('title', {
                      required: 'This field is required.'
                    })}
                    helperText={
                      errors.title && (
                        <Typography variant="caption" sx={{ color: 'red' }}>
                          {errors?.title?.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} sx={{ overflowX: 'auto' }}>
                  <Typography variant="body1" sx={{ mb: '7px 0' }}>
                    Priority
                  </Typography>
                  <ToggleButtonGroup
                    color="primary"
                    value={planPriority}
                    exclusive
                    fullWidth
                    aria-label="Platform"
                    sx={{ display: 'flex' }}>
                    {planPriority?.map((ele: any, index: number) => (
                      <ToggleButton
                        key={index}
                        value={ele.name}
                        onClick={() => setSelPlanPriorityGroup(ele.name)}
                        className={selPlanPriorityGroup === ele.name ? 'activeButton' : ''}
                        sx={{
                          backgroundColor: '#D9D9D9',
                          '&:hover': {
                            backgroundColor: '#D9D9D9'
                          },
                          '&.activeButton': {
                            backgroundColor: colors.primary,
                            color: 'white',
                            '& .MuiTypography-root': {
                              color: 'white'
                            }
                          },
                          '&.activeButton:hover': {
                            backgroundColor: colors.primary,
                            color: 'white'
                          },
                          border: 'none',
                          mr: '5px',
                          borderRadius: '10px !important'
                        }}>
                        <Typography variant="body2" sx={{ display: 'flex', textTransform: 'none' }}>
                          <TourIcon
                            fontSize="small"
                            sx={{ mr: 1, color: PriorityColor(ele?.name) }}
                          />{' '}
                          {ele.name}
                        </Typography>
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    justifyContent: { xs: 'unset', sm: 'flex-end' },
                    marginTop: '20px'
                  }}>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      fullWidth
                      sx={{
                        color: colors.primary,
                        borderColor: colors.primary,
                        fontWeight: 'bold',
                        '&:hover': { borderColor: `${colors.primary} !important` }
                      }}
                      onClick={() => setShowDialog(false)}>
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      fullWidth
                      type="submit"
                      sx={{
                        backgroundColor: colors.primary,
                        fontWeight: 'bold',
                        '&:hover': { backgroundColor: `${colors.primary} !important` }
                      }}>
                      Save
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Dialog>
      ) : (
        <Dialog
          onClose={() => {
            setShowDialog(false);
          }}
          open={showDialog}
          maxWidth={'md'}
          fullWidth>
          <Card sx={{ padding: '1.5rem 2rem !important', overflowY: 'scroll' }}>
            <form onSubmit={handleSubmit(onEditGroup)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body1" sx={{ mb: '7px 0' }}>
                    Group Title
                  </Typography>
                  <TextField
                    size="small"
                    id="outlined-basica"
                    fullWidth
                    variant="outlined"
                    placeholder="Group 1"
                    {...register('title', {
                      required: 'This field is required.'
                    })}
                    helperText={
                      errors.title && (
                        <Typography variant="caption" sx={{ color: 'red' }}>
                          {errors?.title?.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} sx={{ overflowX: 'auto' }}>
                  <Typography variant="body1" sx={{ mb: '7px 0' }}>
                    Priority
                  </Typography>
                  <ToggleButtonGroup
                    color="primary"
                    value={planPriority}
                    exclusive
                    fullWidth
                    aria-label="Platform"
                    sx={{ display: 'flex' }}>
                    {planPriority?.map((ele: any, index: number) => (
                      <ToggleButton
                        key={index}
                        value={ele.name}
                        onClick={() => setSelPlanPriorityGroup(ele.name)}
                        className={selPlanPriorityGroup === ele.name ? 'activeButton' : ''}
                        sx={{
                          backgroundColor: '#D9D9D9',
                          '&:hover': {
                            backgroundColor: '#D9D9D9'
                          },
                          '&.activeButton': {
                            backgroundColor: colors.primary,
                            color: 'white',
                            '& .MuiTypography-root': {
                              color: 'white'
                            }
                          },
                          '&.activeButton:hover': {
                            backgroundColor: colors.primary,
                            color: 'white'
                          },
                          border: 'none',
                          mr: '5px',
                          borderRadius: '10px !important'
                        }}>
                        <Typography variant="body2" sx={{ display: 'flex', textTransform: 'none' }}>
                          <TourIcon
                            fontSize="small"
                            sx={{ mr: 1, color: PriorityColor(ele?.name) }}
                          />{' '}
                          {ele.name}
                        </Typography>
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    justifyContent: { xs: 'unset', sm: 'flex-end' },
                    marginTop: '20px'
                  }}>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant="outlined"
                      fullWidth
                      sx={{
                        color: colors.primary,
                        borderColor: colors.primary,
                        fontWeight: 'bold',
                        '&:hover': { borderColor: `${colors.primary} !important` }
                      }}
                      onClick={() => setShowDialog(false)}>
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      fullWidth
                      type="submit"
                      sx={{
                        backgroundColor: colors.primary,
                        fontWeight: 'bold',
                        '&:hover': { backgroundColor: `${colors.primary} !important` }
                      }}>
                      Save
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Dialog>
      )}
    </>
  );
}

export default CreateGroupPopUp;
