import { Box, Divider, IconButton, MenuItem, Switch, Typography } from '@mui/material';
import React, { useState } from 'react';
import logo from '../../../assets/image/icon.png';
import colors from '../colorConfig/colors';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import ImportContactsRoundedIcon from '@mui/icons-material/ImportContactsRounded';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Brightness2Icon from '@mui/icons-material/Brightness2';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { Logout } from '../../../hooks/useAxiosPrivate';
import { useNavigate } from 'react-router';
import { API_ROUTES, APP_ROUTES } from '../../../utils/constants';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import commonDetailSlice from '../../../store/reducers/commonReducer';
import PomorodoTimerPopUp from '../PomorodoTimerPopUp';
import HttpService from '../../../hooks/Https-services';
import { Link, useLocation } from 'react-router-dom';
import { useTimerContext } from '../../../context/timerValue';

const Sidebar = () => {
  const { timerValue, setTimerValue, pauseTimer, resetTimer, startTimer }: any = useTimerContext();
  const [selectedItem, setSelectedItem] = useState(null);
  const [pomodoroTimer, setPomodoroTimer] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const handleLogout = () => {
    Logout();
    localStorage.clear();
    navigate(APP_ROUTES.Landing);
    toast.success('Logout successfully');
    dispatch(commonDetailSlice.actions.removecommonDetails());
  };
  const handleDelete = async () => {
    try {
      const res = HttpService.get(API_ROUTES.DeleteCalendar);
    } catch (error) {
      console.log(error);
    }
  };
  const handleMenuItemClick = (index: any) => {
    setSelectedItem(selectedItem === index ? null : index);
  };
  return (
    <Box>
      <Box
        sx={{
          height: '100vh',
          width: '250px',
          backgroundColor: colors.primary,
          display: { xs: 'none', md: 'flex' }
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: '80%',
            justifyContent: 'space-between',
            margin: '0 auto',
            py: 2
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2
            }}>
            <Box>
              <Box component="img" src={logo} width={200} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #fff',
                p: '15px 15px',
                mb: 1,
                borderRadius: '5px',
                cursor: 'pointer'
              }}
              onClick={() => setPomodoroTimer(true)}>
              <Box>
                <AccessTimeIcon style={{ color: colors.white }} />
              </Box>
              <Box>
                <Typography color="white">{timerValue}</Typography>
              </Box>
              <Box>
                <SkipNextIcon style={{ color: colors.white }} onClick={resetTimer} />
              </Box>
            </Box>

            <PomorodoTimerPopUp pomodoroTimer={pomodoroTimer} setPomodoroTimer={setPomodoroTimer} />
            <Link to={APP_ROUTES.ScheduleHome}>
              <MenuItem
                selected={location.pathname.includes(APP_ROUTES.ScheduleHome)}
                sx={{
                  textAlign: 'center',
                  '&:hover': {
                    backgroundColor: `${colors.secondaryLight} !important`
                  },
                  '&.Mui-selected': { backgroundColor: colors.secondaryLight },
                  borderRadius: '6px'
                }}>
                <CalendarTodayRoundedIcon sx={{ color: 'white', mr: 1 }} />
                <Typography color="white">Schedule</Typography>
              </MenuItem>
            </Link>

            <MenuItem
              selected={selectedItem === 2}
              sx={{
                textAlign: 'center',
                '&:hover': {
                  backgroundColor: `${colors.secondaryLight} !important`
                },
                '&.Mui-selected': { backgroundColor: colors.secondaryLight },
                borderRadius: '6px'
              }}>
              <ImportContactsRoundedIcon sx={{ color: 'white', mr: 1 }} />
              <Typography color="white">Learn</Typography>
            </MenuItem>

            <MenuItem
              selected={selectedItem === 3}
              sx={{
                textAlign: 'center',
                '&:hover': {
                  backgroundColor: `${colors.secondaryLight} !important`
                },
                '&.Mui-selected': { backgroundColor: colors.secondaryLight },
                borderRadius: '6px'
              }}>
              <FileCopyOutlinedIcon sx={{ color: 'white', mr: 1 }} />
              <Typography color="white">Retain</Typography>
            </MenuItem>

            <MenuItem
              selected={selectedItem === 4}
              sx={{
                textAlign: 'center',
                '&:hover': {
                  backgroundColor: `${colors.secondaryLight} !important`
                },
                '&.Mui-selected': { backgroundColor: colors.secondaryLight },
                borderRadius: '6px'
              }}>
              <UploadFileOutlinedIcon sx={{ color: 'white', mr: 1 }} />
              <Typography color="white">Practices</Typography>
            </MenuItem>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}>
              <Switch
                color="primary"
                // checked={darkMode}
                // onChange={handleModeChange}
                icon={<Brightness2Icon />}
                checkedIcon={<WbSunnyIcon />}
              />
            </Box>
            <Divider color="white" />
            <Link to={APP_ROUTES.MyProfile}>
              <MenuItem
                selected={location.pathname.includes(APP_ROUTES.AccountSetting)}
                sx={{
                  textAlign: 'center',
                  '&:hover': {
                    backgroundColor: `${colors.secondaryLight} !important`
                  },
                  '&.Mui-selected': { backgroundColor: colors.secondaryLight },
                  borderRadius: '6px'
                }}>
                <SettingsOutlinedIcon sx={{ color: 'white', mr: 1 }} />
                <Typography color="white">Setting</Typography>
              </MenuItem>
            </Link>
            <MenuItem
              selected={selectedItem === 6}
              onClick={handleLogout}
              sx={{
                textAlign: 'center',
                '&:hover': {
                  backgroundColor: `${colors.secondaryLight} !important`
                },
                '&.Mui-selected': { backgroundColor: colors.secondaryLight },
                borderRadius: '6px'
              }}>
              <LogoutOutlinedIcon sx={{ color: 'white', mr: 1 }} />
              <Typography color="white">Logout</Typography>
            </MenuItem>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
