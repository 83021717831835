import React, { useEffect, useState } from 'react';
import { google } from 'googleapis';
import { gapi } from 'gapi-script';
import { useGoogleLogin } from '@react-oauth/google';
import { Box, Button } from '@mui/material';
import googleIcon from '../../../assets/image/google.png';
import HttpService from '../../../hooks/Https-services';
import { API_ROUTES } from '../../../utils/constants';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
const GoogleCalendar = (props: any) => {
  const { getCalendar, setOpenAdd } = props;
  const [isLoading, setIsLoading] = useState(false);
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      postCalendar(codeResponse);
    },
    flow: 'auth-code',
    scope:
      'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar openid'
  });
  const postCalendar = async (code: any) => {
    try {
      console.log('res::', code);
      setIsLoading(true);
      const res = await HttpService.post(API_ROUTES.RegisterGoogleCalendar, { code: code.code });
      getCalendar();
      toast.success('Calendar added successfully');
      setOpenAdd(false);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      console.log(error);
    }
  };
  return (
    <LoadingButton
      loading={isLoading}
      variant="outlined"
      color="secondary"
      sx={{
        border: '1px solid',
        fontWeight: 700,
        whiteSpace: 'nowrap',
        mt: 2
      }}
      onClick={login}>
      <Box component="img" src={googleIcon} width={16} mr={2} />
      Continue with Google
    </LoadingButton>
  );
};
export default GoogleCalendar;
