import { Box, Divider, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React from 'react';
import colors from '../../components/common/colorConfig/colors.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../utils/constants.js';
import ScheduleHome from '../../components/common/ScheduleHome';
import SchedulePlans from '../../components/common/SchedulePlans';

function Schedule() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box sx={{ p: '40px 25px 0 25px' }}>
      <Typography
        variant="h4"
        component="h2"
        sx={{ fontWeight: 700, m: '0 0 40px 0', color: 'secondary.main' }}>
        Schedule
      </Typography>

      <Box sx={{}}>
        <ToggleButtonGroup
          color="primary"
          value={location.pathname}
          exclusive
          size="small"
          aria-label="Platform"
          sx={{
            display: 'flex',
            width: '100%',
            flexWrap: 'wrap',
            gap: 1,
            whiteSpace: 'nowrap'
          }}>
          <ToggleButton
            value="ScheduleHome"
            onClick={() => {
              navigate(APP_ROUTES.ScheduleHome);
            }}
            className={location.pathname === APP_ROUTES.ScheduleHome ? 'activeButton' : ''}
            sx={{
              backgroundColor: 'transperant',
              '&:hover': {
                backgroundColor: 'transperant'
              },
              '&.activeButton': {
                backgroundColor: colors.secondary,
                color: 'white'
              },
              '&.activeButton:hover': {
                backgroundColor: colors.secondary,
                color: 'white'
              },
              border: 'none',
              mr: '20px',
              borderRadius: '10px !important'
            }}>
            <Typography variant="body2" sx={{ display: 'flex', textTransform: 'none' }}>
              Schedule
            </Typography>
          </ToggleButton>
          <ToggleButton
            value="SchedulePlans"
            onClick={() => {
              navigate(APP_ROUTES.SchedulePlans);
            }}
            className={location.pathname === APP_ROUTES.SchedulePlans ? 'activeButton' : ''}
            sx={{
              backgroundColor: 'transperant',
              '&:hover': {
                backgroundColor: 'transperant'
              },
              '&.activeButton': {
                backgroundColor: colors.secondary,
                color: 'white'
              },
              '&.activeButton:hover': {
                backgroundColor: colors.secondary,
                color: 'white'
              },
              border: 'none',
              mx: '20px',
              borderRadius: '10px !important'
            }}>
            <Typography variant="body2" sx={{ display: 'flex', textTransform: 'none' }}>
              Plans
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Divider sx={{ m: '15px 0 0 0' }} />
      <Box
        sx={{
          m: '20px 0 0 8px',
          overflowY: 'auto',
          maxHeight: { xs: '100%', md: 'calc(100vh - 200px)' }
        }}>
        {location.pathname === APP_ROUTES.ScheduleHome ? (
          <ScheduleHome />
        ) : location.pathname === APP_ROUTES.SchedulePlans ? (
          <SchedulePlans />
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
}

export default Schedule;
