import { Box, Button, Container, Grid, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import colors from '../../components/common/colorConfig/colors';
import PostCard from './PostCard';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../utils/constants';

const BlogPostPage = () => {
  const [value, setValue] = useState(0);
  const [tempCard, setTempCard] = useState(['', '', '', '', '', '']);

  const navigate = useNavigate();
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ backgroundColor: '#F6F6F6' }}>
      {' '}
      <Box
        sx={{
          // minHeight: { xs: "72vh", sm: "60vh" },
          backgroundColor: colors.primary,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
          // resize: "both",
        }}>
        <Box
          height="auto"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            py: { xs: 5, sm: 10 }
          }}>
          <Typography
            variant="h2"
            color="white"
            sx={{
              background: 'linear-gradient(90deg, #06C7BB 0.21%, #FFF 39.69%)',
              backgroundClip: 'text',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent',
              textAlign: 'center'
            }}
            mx={2.5}
            fontSize={{ xs: 42, md: '3.75rem' }}>
            Elevate Your Studying
          </Typography>
          <Typography
            variant="h6"
            color="white"
            my={4}
            fontWeight={400}
            mx={2}
            fontSize={{ xs: 16, md: '1.25rem', textAlign: 'center' }}>
            Your Source for Proven Strategies and Free Study Aids!
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate(`${APP_ROUTES.Blog}?q1=true`)}
            sx={{
              mb: 4,
              fontWeight: '700'
            }}>
            JOIN FREE NOW
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Custom Tabs"
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            '& .MuiTab-root': {
              fontWeight: '600',
              color: colors.light,
              textTransform: 'none',
              fontSize: { xs: 16, md: '1.1rem' }
            },
            '& .MuiTabs-flexContainer': {
              //   justifyContent: "center",
            },
            '& .MuiTab-root.Mui-selected': {
              fontWeight: '700',
              color: colors.secondary
            },
            '& .MuiButtonBase-root': {
              mx: 2
            },
            '& .MuiTabs-scroller': {
              borderBottom: '1px solid lightgray'
            }
          }}
          TabIndicatorProps={{
            style: {
              backgroundColor: colors.secondary
            }
          }}>
          <Tab label="All Posts" />
          <Tab label="Studying" />
          <Tab label="Health & Wellness" />
          <Tab label="Productivity" />
          <Tab label="MCAT Exam" />
          <Tab label="Free Resources" />
          {/* Add more tabs as needed */}
        </Tabs>
      </Box>
      {value == 0 && (
        <Container>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              mt: 2,
              pb: 4,
              gap: 3
            }}>
            {/* Add spacing between grid items */}
            {tempCard.map((post: any, ind: any) => (
              <Box key={ind + 1}>
                <PostCard
                  name={`Title of Blog Post ${ind + 1}`}
                  tags={`Tag ${ind + 1}`}
                  bColor={ind % 2 == 0 ? '' : '#FF5F37'}
                  id={ind + 1}
                />
              </Box>
            ))}
          </Box>
        </Container>
      )}
      {value == 1 && (
        <Container>
          <Typography variant="h6" textAlign="center">
            Studying
          </Typography>
        </Container>
      )}
      {value == 2 && (
        <Container>
          <Typography variant="h6" textAlign="center">
            Health & Wellness
          </Typography>
        </Container>
      )}
      {value == 3 && (
        <Container>
          <Typography variant="h6" textAlign="center">
            Productivity
          </Typography>
        </Container>
      )}
      {value == 4 && (
        <Container>
          <Typography variant="h6" textAlign="center">
            MCAT Exam
          </Typography>
        </Container>
      )}
      {value == 5 && (
        <Container>
          <Typography variant="h6" textAlign="center">
            Free Resources
          </Typography>
        </Container>
      )}
    </Box>
  );
};

export default BlogPostPage;
