import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import React, { useState, ChangeEvent, useEffect } from 'react';
import colors from '../colorConfig/colors.js';
import HttpService from '../../../hooks/Https-services';
import { API_ROUTES } from '../../../utils/constants';
import { toast } from 'react-toastify';

function Pomodoro() {
  const [timer, setTimer] = useState({
    focus: '0',
    short_break: '0',
    long_break: '0',
    long_break_interval: '0'
  });

  const [startBreak, setStartBreak] = useState(false);
  const [startFocus, setStartFocus] = useState(false);
  const [sound, setSound] = useState(false);

  const handleChangeStartBreak = (event: ChangeEvent<HTMLInputElement>) => {
    setStartBreak(event.target.checked);
  };
  const handleChangeStartFocus = (event: ChangeEvent<HTMLInputElement>) => {
    setStartFocus(event.target.checked);
  };
  const handleChangeSound = (event: ChangeEvent<HTMLInputElement>) => {
    setSound(event.target.checked);
  };
  const getUserTimer = async () => {
    try {
      const res = await HttpService.get(API_ROUTES.GetUserTimer);
      const data = res.data.data;
      const temp: any = {
        focus: data?.focus,
        short_break: data?.short_break,
        long_break: data?.long_break,
        long_break_interval: data?.long_break_interval
      };
      setStartBreak(data?.auto_start_breaks);
      setStartFocus(data?.autostart_focus_session);
      setSound(data?.sound);
      setTimer(temp);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUserTimer();
  }, []);
  const setUserTimer = async () => {
    try {
      const data = {
        focus: timer.focus,
        short_break: timer.short_break,
        long_break: timer.long_break,
        auto_start_breaks: startBreak,
        autostart_focus_session: startFocus,
        sound: sound,
        long_break_interval: timer.long_break_interval
      };
      const res = await HttpService.patch(API_ROUTES.SetUserTimer, data);
      toast.success('Timer settings updated successfully');
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Card
      sx={{
        maxWidth: 'fit-content',
        borderRadius: '10px',
        border: `1px solid ${colors.darkGrey}`,
        padding: { xs: '5px 10px', md: '10px 25px' }
      }}>
      <CardContent>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 400 }}>
            Timer Settings (minutes)
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            borderRadius: '10px',
            gap: 1,
            mt: '5px'
          }}>
          <Box sx={{ mx: { xs: '5px', md: '10px' }, width: '120px' }}>
            <Typography variant="body2" sx={{ fontWeight: 800, color: colors.darkGrey }}>
              Focus
            </Typography>
            <TextField
              id="outlined-number"
              type="number"
              size="small"
              InputLabelProps={{
                shrink: true
              }}
              value={timer.focus}
              onChange={(e) => {
                setTimer({ ...timer, ['focus']: e.target.value });
              }}
            />
          </Box>
          <Box sx={{ mx: { xs: '5px', md: '10px' }, width: '120px' }}>
            <Typography variant="body2" sx={{ fontWeight: 800, color: colors.darkGrey }}>
              Short Break
            </Typography>
            <TextField
              id="outlined-number"
              type="number"
              size="small"
              InputLabelProps={{
                shrink: true
              }}
              value={timer.short_break}
              onChange={(e) => {
                setTimer({ ...timer, ['short_break']: e.target.value });
              }}
            />
          </Box>
          <Box sx={{ mx: { xs: '5px', md: '10px' }, width: '120px' }}>
            <Typography variant="body2" sx={{ fontWeight: 800, color: colors.darkGrey }}>
              Long Break
            </Typography>
            <TextField
              id="outlined-number"
              type="number"
              size="small"
              InputLabelProps={{
                shrink: true
              }}
              value={timer.long_break}
              onChange={(e) => {
                setTimer({ ...timer, ['long_break']: e.target.value });
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: { xs: '100%', md: '400px' }
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mx: { xs: '5px', md: '10px' },
              mt: '20px'
            }}>
            <Typography variant="h6" sx={{ fontWeight: 400 }}>
              Auto start breaks
            </Typography>
            <Switch
              checked={startBreak}
              onChange={handleChangeStartBreak}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mx: { xs: '5px', md: '10px' },
              mt: '20px'
            }}>
            <Typography variant="h6" sx={{ fontWeight: 400 }}>
              Auto start focus session
            </Typography>
            <Switch
              checked={startFocus}
              onChange={handleChangeStartFocus}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mx: { xs: '5px', md: '10px' },
              mt: '20px'
            }}>
            <Typography variant="h6" sx={{ fontWeight: 400 }}>
              Sound
            </Typography>
            <Switch
              checked={sound}
              onChange={handleChangeSound}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mx: { xs: '5px', md: '10px' },
              mt: '20px'
            }}>
            <Typography variant="h6" sx={{ fontWeight: 400 }}>
              Long break interval
            </Typography>
            <TextField
              id="outlined-number"
              type="number"
              size="small"
              sx={{ width: '90px' }}
              InputLabelProps={{
                shrink: true
              }}
              value={timer.long_break_interval}
              onChange={(e) => {
                setTimer({ ...timer, ['long_break_interval']: e.target.value });
              }}
            />
          </Box>
        </Box>
        <Box sx={{ mt: '50px' }}>
          <Stack spacing={2} direction="row-reverse">
            <Button variant="contained" onClick={setUserTimer}>
              Save
            </Button>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
}

export default Pomodoro;
