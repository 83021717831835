import { Box, Button, Card, CardContent, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import colors from '../colorConfig/colors.js';
import HttpService from '../../../hooks/Https-services';
import { API_ROUTES } from '../../../utils/constants';

const copyTextToClipboard = (text: any) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  document.body.appendChild(textArea);

  textArea.select();
  document.execCommand('copy');

  document.body.removeChild(textArea);
};

const Referrals = () => {
  const [code, setCode] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const personalReferralLink = `https://arc-academic.vercel.app?referral_code=${code?.user_referral_code}`;

  const copyURL = () => {
    copyTextToClipboard(code.user_referral_code);
  };
  const getReferral = async () => {
    try {
      const res = await HttpService.get(API_ROUTES.GetReferral);
      setCode(res.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getReferral();
  }, []);
  return (
    <Box sx={{ overflowY: 'auto' }}>
      <Box>
        <Typography variant="h6">Your Arc Referrals</Typography>
        <Typography variant="body1" sx={{ pt: '3px' }}>
          Refer friends, extend your Arc subscription! *Describe referral program here* It&apos;s a
          win-win: your friends will get a 10% discount and a bonus week, and you will get
          additional weeks added to your Arc subscription! Your friends can use either your referral
          code at checkout or use your unique signup link. The more friends you refer, the longer
          your subscription will be extended.
        </Typography>
      </Box>
      <Box sx={{ pt: '25px' }}>
        <Typography variant="h6">Your personal referral link</Typography>
        {!isLoading ? (
          <Typography variant="body1" sx={{ pt: '3px' }}>
            <a
              href={personalReferralLink}
              rel="noreferrer"
              target="_blank"
              style={{ color: colors.light }}>
              {personalReferralLink}
            </a>
          </Typography>
        ) : (
          <Skeleton variant="text" width={350} height={35} />
        )}
      </Box>
      <Box sx={{ pt: '25px' }}>
        <Typography variant="h6">Your personal referral code</Typography>
        <Box sx={{ display: 'flex', pt: '3px' }}>
          {!isLoading ? (
            <Typography
              variant="body1"
              sx={{
                color: colors.darkGrey,
                display: 'flex',
                alignContent: 'center'
              }}>
              {code?.user_referral_code}
            </Typography>
          ) : (
            <Skeleton variant="text" width={140} height={35} />
          )}
          <Button
            disableRipple
            variant="outlined"
            size="small"
            startIcon={<ContentCopyIcon />}
            sx={{
              ml: '10px',
              border: 'none',
              '&:hover': {
                border: 'none'
              }
            }}
            onClick={copyURL}></Button>
        </Box>
      </Box>
      <Box sx={{ pt: '25px' }}>
        <Typography variant="h6">Your referral history</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            textAlign: 'center',
            border: `1px solid ${colors.darkGrey}`,
            width: 'max-content',
            borderRadius: '10px',
            p: '10px 25px',
            mt: '5px'
          }}>
          <Box sx={{ mx: { xs: '5px', md: '30px' } }}>
            <Typography variant="h3" sx={{ fontWeight: 800 }}>
              {code.total_referrals_sent || '0'}
            </Typography>
            <Typography variant="body1" style={{ color: colors.darkGrey }}>
              Referral sent
            </Typography>
          </Box>
          <Box sx={{ mx: { xs: '5px', md: '30px' } }}>
            <Typography variant="h3" sx={{ fontWeight: 800 }}>
              {code.total_referrals_accepted || '0'}
            </Typography>
            <Typography variant="body1" style={{ color: colors.darkGrey }}>
              Referral accepted
            </Typography>
          </Box>
          <Box sx={{ mx: { xs: '5px', md: '30px' } }}>
            <Typography variant="h3" sx={{ fontWeight: 800 }}>
              2
            </Typography>
            <Typography variant="body1" style={{ color: colors.darkGrey }}>
              Bonus weeks
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Referrals;
