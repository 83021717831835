import { Box, Container, Typography } from '@mui/material';
import React from 'react';

const NoPageFound = () => {
  return (
    <Container>
      <Box
        sx={{
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Typography>No Page Found</Typography>
      </Box>
    </Container>
  );
};

export default NoPageFound;
