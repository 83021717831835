import { Box, Button, Card, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import banner1 from '../../assets/image/banner1.png';
import banner2 from '../../assets/image/banner2.png';
import arrow from '../../assets/image/arrow.png';
import colors from '../../components/common/colorConfig/colors.js';
import ImageCards from '../../components/common/ImageCards';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../utils/constants';

const LandingPage = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Box
        sx={{
          height: '100vh',
          backgroundColor: colors.primary,
          display: 'flex',
          flexDirection: { xs: 'column-reverse', md: 'row' },
          py: { xs: 4, sm: 0 }
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '100%',
            // width: "640px",
            width: { xs: '100%', md: '50%' },

            position: 'relative'
          }}>
          <Box
            sx={{
              minWidth: '320px',
              margin: '0 auto',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: { xs: 'center', md: 'unset' }
            }}>
            <Typography variant="body1" color="white" fontSize={{ xs: '0.90rem', md: '1rem' }}>
              A LEARNING OPTIMIZATION PLATFORM
            </Typography>
            <Typography
              variant="h2"
              color="white"
              sx={{
                mt: '25px',
                background: `linear-gradient(90deg, ${colors.secondary} 0.21%, #FFF 39.69%)`,
                backgroundClip: 'text',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent'
              }}
              fontSize={{ xs: 48, md: '3.75rem' }}>
              Realize your
            </Typography>
            <Typography variant="h2" color="white" fontSize={{ xs: 48, md: '3.75rem' }}>
              potential
            </Typography>
            <Typography
              variant="body1"
              color="white"
              mt={2}
              fontSize={{ xs: '0.90rem', md: '1rem' }}>
              Transform your studying with our time management tools and scientifically backed study
              methods.
            </Typography>
            <Button
              variant="contained"
              onClick={() => navigate(`${APP_ROUTES.Landing}?q1=true`)}
              sx={{
                mt: 2,
                fontWeight: '700'
              }}>
              GET STARTED FREE
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            // width: "640px",
            // minWidth: "520px",
            width: { xs: '100%', md: '50%' },
            position: 'relative'
          }}>
          <Box
            component="img"
            src={banner2}
            sx={{
              position: 'absolute',
              width: { xs: '90%', sm: '100%' },
              maxWidth: { xs: '500px', md: '700px' },
              minWidth: '280px',
              top: '50%',
              left: '50%',
              transform: {
                xs: 'translate(-45%, -60%)',
                md: 'translate(-50%, -60%)'
              },
              zIndex: 1
            }}
          />
          <Box
            component="img"
            src={banner2}
            sx={{
              position: 'absolute',
              // height: "490px",
              maxWidth: { xs: '500px', md: '700px' },
              minWidth: '280px',
              width: { xs: '90%', sm: '100%' },
              top: '50%',
              left: '50%',
              transform: {
                xs: 'translate(-55%, -30%)',
                md: 'translate(-60%, -30%)'
              }
            }}
          />
        </Box>
      </Box>
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            p: 2,
            backgroundColor: 'white',
            borderRadius: '60px'
          }}>
          <Box
            component="img"
            src={arrow}
            height={30}
            width={17}
            sx={{
              px: 3.1,
              py: 2.3,
              border: '1px solid gray',
              borderRadius: '60px',
              backgroundColor: '#fff'
            }}
          />
        </Box>
      </Box>
      <Box sx={{ mt: 10, textAlign: 'center' }}>
        <Typography variant="body1" color={colors.secondary} fontWeight={700} fontSize={18}>
          OUR METHODOLOGY
        </Typography>
        <Typography
          variant="h2"
          fontWeight={600}
          fontSize={{ xs: 35, md: '3.75rem' }}
          sx={{ mx: { xs: 4, md: 'auto' } }}>
          Your all-in-one resource hub for <br />
          comprehensive exam preparation.
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 10,
          flexDirection: 'column',
          margin: '0 auto',
          my: 5
        }}>
        <ImageCards
          left={true}
          title1="SCHEDULE"
          title2="Responsive scheduler to optimize your study time."
          text="Effortlessly create personalized study plans tailored to your changing priorities and preferences."
        />
        <ImageCards
          left={false}
          title1="LEARN"
          title2="Comprehensive study materials for success."
          text="Access review sheets and quizzes to deepen your understanding and reinforce key concepts."
        />
        <ImageCards
          left={true}
          title1="PRACTICE"
          title2="Develop expertise with our extensive question bank."
          text="Strengthen MCAT knowledge and sharpen test-taking skills with a wide range of practice questions and in-depth explanations."
        />
        <ImageCards
          left={false}
          title1="RETAIN"
          title2="Master the Art of Long-Term Retention."
          text="Unlock the power of spaced repetition and interleaving with our innovative flashcards, designed to combat the forgetting curve and ensure lasting knowledge retention."
        />
      </Box>
      <Box
        sx={{
          position: 'relative',
          backgroundColor: colors.primary,
          height: '521px'
        }}>
        <Box
          sx={{
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center'
          }}>
          <Typography variant="h3" color="white" fontSize={{ xs: 48, md: '3.75rem' }} mx={2}>
            Don’t miss out on the key to achieving your goals.
          </Typography>
          <Typography
            variant="h6"
            color="white"
            my={4}
            fontWeight={400}
            mx={2}
            fontSize={{ xs: 16, md: '1.25rem' }}>
            Get instant access to optimize your studying when you sign up.
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate(`${APP_ROUTES.Landing}?q1=true`)}
            sx={{
              fontWeight: '700'
            }}>
            GET STARTED FREE
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingPage;
