export const APP_ROUTES = {
  Landing: '/',
  Mcat: '/mcat',
  Blog: '/blog',
  Post: '/post',
  ContactUs: '/contact-us',
  Terms: '/terms',
  Privacy: '/privacy',
  Refer: '/refer',
  Partner: '/partner',
  Onboard: '/onboard',
  MyProfile: '/account-setting/my-profile',
  AccountSetting: '/account-setting',
  PlansAndBilling: '/account-setting/plans-billing',
  CalendarAndSchedules: '/account-setting/calendar-schedules',
  Pomodoro: '/account-setting/pomodoro',
  Referrals: '/account-setting/referrals',
  ScheduleHome: '/schedule/home',
  SchedulePlans: '/schedule/home/plans',
  VerifyEmail: '/verify-email',
  Any: '/*'
};

export const API_ROUTES = {
  RefreshToken: '/v1/auth/refresh-tokens',
  Register: '/v1/auth/register',
  VerifyEmail: '/v1/auth/verify-email',
  GoogleAuth: '/v1/auth/google-auth',
  Login: '/v1/auth/login',
  ForgotPassword: '/v1/auth/forgot-password',
  ResetPassword: '/v1/auth/reset-password',
  UpdateUser: '/v1/users/update-user',
  MsAuth: '/v1/auth/microsoft-auth',

  //profile settings
  GetUserProfile: '/v1/users/get-user-details',
  UpdateUserProfile: '/v1/users/update-user',

  //timer settings
  SetUserTimer: '/v1/users/user-timer-setting',
  GetUserTimer: '/v1/users/get-user-timer-setting',

  //register calendar
  RegisterGoogleCalendar: '/v1/calendar/user-calendar-register-google',
  RegisterMsCalendar: '/v1/calendar/user-calendar-register-outlook',
  GetGoogleCalendar: '/v1/calendar/get-user-calendars',
  DeleteCalendar: '/v1/calendar/delete-user-calendar/:id',
  GetSubCalendar: '/v1/calendar/get-user-sub-calendars/:id',

  //preference calendarn
  SetPreference: '/v1/userpreference/user-preference-register/:id',

  //contact us
  ContactUs: '/v1/auth/contact-us',
  //Schdeulers
  GetScheduler: '/v1/calendar/get-user-calendar-schedules',
  SetScheduler: '/v1/calendar/create-user-calendar-schedule',
  UpdateScheduler: '/v1/calendar/update-user-calendar-schedule/:id',
  DeleteScheduler: '/v1/calendar/delete-user-calendar-schedule/:id',

  //task configuration
  taskConfiguration: '/v1/calendar/calendar-task-configuration',

  //Referral email
  SendReferralEmail: '/v1/users/send-user-referral',
  GetReferral: '/v1/users/get-user-referrals',

  //create plan
  GetGroup: '/v1/plan/get-plan-groups',
  GetTask: '/v1/plan/get-user-tasks',

  // Common
  ApplicationObject: '/v1/common/application-object/:id',

  // Calendar
  GetAllCalendarEvent: '/v1/calendar/get-all-calendar-events',

  // Drop-down
  GetAllTasks: '/v1/drop-down/tasks',
  GetAllSchedules: '/v1/drop-down/schedules',
  GetAllPlans: '/v1/drop-down/plans',
  GetAllGroups: '/v1/drop-down/groups/:id',
  GetAllSubGroups: '/v1/drop-down/sub-groups/:id',

  // User Plan
  GetUserPlans: '/v1/plan/get-user-plans',

  CreatePlan: '/v1/plan/create-plan',
  UpdatePlan: '/v1/plan/update-plan/:id',
  DeletePlan: '/v1/plan/delete-plan/:id',

  CreateGroup: '/v1/plan/create-plan-group',
  UpdateGroup: '/v1/plan/update-group/:id',
  DeleteGroup: '/v1/plan/delete-group/:id',

  CreateSubGroup: '/v1/plan/create-plan-sub-group',
  UpdateSubGroup: '/v1/plan/update-sub-group/:id',
  DeleteSubGroup: '/v1/plan/delete-sub-group/:id',

  CreateTask: '/v1/plan/create-task',
  UpdateTask: '/v1/plan/update-task/:id',
  DeleteTask: '/v1/plan/delete-task/:id',

  GetUnplanedGroups: '/v1/plan/get-unplanned-groups',
  GetAddedGroups: '/v1/plan/get-added-groups',
  HandleGroupAddStatus: '/v1/plan/handle-add-status-group'
};
export const TimeZoneData = [
  {
    offset: 'GMT-12:00',
    name: 'Etc/GMT-12'
  },
  {
    offset: 'GMT-11:00',
    name: 'Etc/GMT-11'
  },
  {
    offset: 'GMT-11:00',
    name: 'Pacific/Midway'
  },
  {
    offset: 'GMT-10:00',
    name: 'America/Adak'
  },
  {
    offset: 'GMT-09:00',
    name: 'America/Anchorage'
  },
  {
    offset: 'GMT-09:00',
    name: 'Pacific/Gambier'
  },
  {
    offset: 'GMT-08:00',
    name: 'America/Dawson_Creek'
  },
  {
    offset: 'GMT-08:00',
    name: 'America/Ensenada'
  },
  {
    offset: 'GMT-08:00',
    name: 'America/Los_Angeles'
  },
  {
    offset: 'GMT-07:00',
    name: 'America/Chihuahua'
  },
  {
    offset: 'GMT-07:00',
    name: 'America/Denver'
  },
  {
    offset: 'GMT-06:00',
    name: 'America/Belize'
  },
  {
    offset: 'GMT-06:00',
    name: 'America/Cancun'
  },
  {
    offset: 'GMT-06:00',
    name: 'America/Chicago'
  },
  {
    offset: 'GMT-06:00',
    name: 'Chile/EasterIsland'
  },
  {
    offset: 'GMT-05:00',
    name: 'America/Bogota'
  },
  {
    offset: 'GMT-05:00',
    name: 'America/Havana'
  },
  {
    offset: 'GMT-05:00',
    name: 'America/New_York'
  },
  {
    offset: 'GMT-04:30',
    name: 'America/Caracas'
  },
  {
    offset: 'GMT-04:00',
    name: 'America/Campo_Grande'
  },
  {
    offset: 'GMT-04:00',
    name: 'America/Glace_Bay'
  },
  {
    offset: 'GMT-04:00',
    name: 'America/Goose_Bay'
  },
  {
    offset: 'GMT-04:00',
    name: 'America/Santiago'
  },
  {
    offset: 'GMT-04:00',
    name: 'America/La_Paz'
  },
  {
    offset: 'GMT-03:00',
    name: 'America/Argentina/Buenos_Aires'
  },
  {
    offset: 'GMT-03:00',
    name: 'America/Montevideo'
  },
  {
    offset: 'GMT-03:00',
    name: 'America/Araguaina'
  },
  {
    offset: 'GMT-03:00',
    name: 'America/Godthab'
  },
  {
    offset: 'GMT-03:00',
    name: 'America/Miquelon'
  },
  {
    offset: 'GMT-03:00',
    name: 'America/Sao_Paulo'
  },
  {
    offset: 'GMT-03:30',
    name: 'America/St_Johns'
  },
  {
    offset: 'GMT-02:00',
    name: 'America/Noronha'
  },
  {
    offset: 'GMT-01:00',
    name: 'Atlantic/Cape_Verde'
  },
  {
    offset: 'GMT',
    name: 'Europe/Belfast'
  },
  {
    offset: 'GMT',
    name: 'Africa/Abidjan'
  },
  {
    offset: 'GMT',
    name: 'Europe/Dublin'
  },
  {
    offset: 'GMT',
    name: 'Europe/Lisbon'
  },
  {
    offset: 'GMT',
    name: 'Europe/London'
  },
  {
    offset: 'UTC',
    name: 'UTC'
  },
  {
    offset: 'GMT+01:00',
    name: 'Africa/Algiers'
  },
  {
    offset: 'GMT+01:00',
    name: 'Africa/Windhoek'
  },
  {
    offset: 'GMT+01:00',
    name: 'Atlantic/Azores'
  },
  {
    offset: 'GMT+01:00',
    name: 'Atlantic/Stanley'
  },
  {
    offset: 'GMT+01:00',
    name: 'Europe/Amsterdam'
  },
  {
    offset: 'GMT+01:00',
    name: 'Europe/Belgrade'
  },
  {
    offset: 'GMT+01:00',
    name: 'Europe/Brussels'
  },
  {
    offset: 'GMT+02:00',
    name: 'Africa/Cairo'
  },
  {
    offset: 'GMT+02:00',
    name: 'Africa/Blantyre'
  },
  {
    offset: 'GMT+02:00',
    name: 'Asia/Beirut'
  },
  {
    offset: 'GMT+02:00',
    name: 'Asia/Damascus'
  },
  {
    offset: 'GMT+02:00',
    name: 'Asia/Gaza'
  },
  {
    offset: 'GMT+02:00',
    name: 'Asia/Jerusalem'
  },
  {
    offset: 'GMT+03:00',
    name: 'Africa/Addis_Ababa'
  },
  {
    offset: 'GMT+03:00',
    name: 'Asia/Riyadh89'
  },
  {
    offset: 'GMT+03:00',
    name: 'Europe/Minsk'
  },
  {
    offset: 'GMT+03:30',
    name: 'Asia/Tehran'
  },
  {
    offset: 'GMT+04:00',
    name: 'Asia/Dubai'
  },
  {
    offset: 'GMT+04:00',
    name: 'Asia/Yerevan'
  },
  {
    offset: 'GMT+04:00',
    name: 'Europe/Moscow'
  },
  {
    offset: 'GMT+04:30',
    name: 'Asia/Kabul'
  },
  {
    offset: 'GMT+05:00',
    name: 'Asia/Tashkent'
  },
  {
    offset: 'GMT+05:30',
    name: 'Asia/Kolkata'
  },
  {
    offset: 'GMT+05:45',
    name: 'Asia/Katmandu'
  },
  {
    offset: 'GMT+06:00',
    name: 'Asia/Dhaka'
  },
  {
    offset: 'GMT+06:00',
    name: 'Asia/Yekaterinburg'
  },
  {
    offset: 'GMT+06:30',
    name: 'Asia/Rangoon'
  },
  {
    offset: 'GMT+07:00',
    name: 'Asia/Bangkok'
  },
  {
    offset: 'GMT+07:00',
    name: 'Asia/Novosibirsk'
  },
  {
    offset: 'GMT+08:00',
    name: 'Etc/GMT+8'
  },
  {
    offset: 'GMT+08:00',
    name: 'Asia/Hong_Kong'
  },
  {
    offset: 'GMT+08:00',
    name: 'Asia/Krasnoyarsk'
  },
  {
    offset: 'GMT+08:00',
    name: 'Australia/Perth'
  },
  {
    offset: 'GMT+08:45',
    name: 'Australia/Eucla'
  },
  {
    offset: 'GMT+09:00',
    name: 'Asia/Irkutsk'
  },
  {
    offset: 'GMT+09:00',
    name: 'Asia/Seoul'
  },
  {
    offset: 'GMT+09:00',
    name: 'Asia/Tokyo'
  },
  {
    offset: 'GMT+09:30',
    name: 'Australia/Adelaide'
  },
  {
    offset: 'GMT+09:30',
    name: 'Australia/Darwin'
  },
  {
    offset: 'GMT+09:30',
    name: 'Pacific/Marquesas'
  },
  {
    offset: 'GMT+10:00',
    name: 'Etc/GMT+10'
  },
  {
    offset: 'GMT+10:00',
    name: 'Australia/Brisbane'
  },
  {
    offset: 'GMT+10:00',
    name: 'Australia/Hobart'
  },
  {
    offset: 'GMT+10:00',
    name: 'Asia/Yakutsk'
  },
  {
    offset: 'GMT+10:30',
    name: 'Australia/Lord_Howe'
  },
  {
    offset: 'GMT+11:00',
    name: 'Asia/Vladivostok'
  },
  {
    offset: 'GMT+11:30',
    name: 'Pacific/Norfolk'
  },
  {
    offset: 'GMT+12:00',
    name: 'Etc/GMT+12'
  },
  {
    offset: 'GMT+12:00',
    name: 'Asia/Anadyr'
  },
  {
    offset: 'GMT+12:00',
    name: 'Asia/Magadan'
  },
  {
    offset: 'GMT+12:00',
    name: 'Pacific/Auckland'
  },
  {
    offset: 'GMT+12:45',
    name: 'Pacific/Chatham'
  },
  {
    offset: 'GMT+13:00',
    name: 'Pacific/Tongatapu'
  },
  {
    offset: 'GMT+14:00',
    name: 'Pacific/Kiritimati'
  }
];

export const Priority = {
  Low: 1,
  Medium: 2,
  High: 3,
  ASAP: 4
};

export const PriorityColor = (name) => {
  switch (name) {
    case 'Low':
      return '#17A1FA';
    case 'Medium':
      return '#FFF504';
    case 'High':
      return '#FF5F37';
    case 'ASAP':
      return '#DE0000';
    default:
      return '#000000';
  }
};
