import { Box } from '@mui/material';
import React from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import PrivateFooter from '../PrivateFooter';
import PrivateHeader from '../PrivateHeader';

const MainContainer = ({ children }: { children: React.ReactElement }) => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        maxWidth: '100%'
      }}>
      <Sidebar />
      <Box sx={{ width: '100%', background: '#fafbfc', overflow: 'auto' }}>
        <PrivateHeader />
        <Box>{children}</Box>
        <PrivateFooter />
      </Box>
    </Box>
  );
};

export default MainContainer;
