import { Avatar, Box, Button, Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import colors from '../colorConfig/colors';
import tik from '../../../assets/image/tick_circle.png';
import finish from '../../../assets/image/finish1.png';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../utils/constants';

const SubCard = () => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        width: { xs: '80%', md: '750px' },
        margin: '0 auto',
        borderRadius: '20px',
        boxShadow: '0 8px 6px rgba(0, 0, 0, 0.3)'
      }}>
      <CardContent sx={{ p: '20px ', px: '26px !important' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap'
          }}>
          <Typography
            variant="h3"
            color={colors.primary}
            fontSize={{ xs: 28, md: '3rem' }}
            fontWeight={600}>
            Subscription
          </Typography>
          <Typography
            variant="h3"
            color={colors.primary}
            fontSize={{ xs: 28, md: '3rem' }}
            fontWeight={600}>
            $40
            <Typography
              variant="body1"
              color={colors.primary}
              component="span"
              fontWeight={700}
              fontSize={{ xs: '0.90rem', md: '1rem' }}>
              /month
            </Typography>
          </Typography>
        </Box>
        <Typography variant="body1" my={4} fontSize={{ xs: '0.90rem', md: '1rem' }}>
          Get started today with a risk-free 7-day free trial, and after the trial period, continue
          your MCAT prep journey at just $40/month.
        </Typography>
        <Typography
          variant="body1"
          my={4}
          color={colors.primary}
          fontSize={{ xs: '0.90rem', md: '1.1rem' }}
          fontWeight={700}>
          What You’ll Get
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            position: 'relative'
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box component="img" src={tik} width={30} sx={{ mr: 1.5 }} />
            <Typography color={colors.primary} fontSize={{ xs: 14, md: 18 }}>
              {' '}
              Dynamic Scheduler
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box component="img" src={tik} width={30} sx={{ mr: 1.5 }} />
            <Typography color={colors.primary} fontSize={{ xs: 14, md: 18 }}>
              {' '}
              Leverage 18 academically backed strategies across our features
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box component="img" src={tik} width={30} sx={{ mr: 1.5 }} />
            <Typography color={colors.primary} fontSize={{ xs: 14, md: 18 }}>
              {' '}
              All Content Review and Quizzes
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box component="img" src={tik} width={30} sx={{ mr: 1.5 }} />
            <Typography color={colors.primary} fontSize={{ xs: 14, md: 18 }}>
              {' '}
              1000+ Flashcards
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box component="img" src={tik} width={30} sx={{ mr: 1.5 }} />
            <Typography color={colors.primary} fontSize={{ xs: 14, md: 18 }} mr="82px">
              {' '}
              2000+ Practice Questions
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box component="img" src={tik} width={30} sx={{ mr: 1.5 }} />
            <Typography color={colors.primary} fontSize={{ xs: 14, md: 18 }} mr="82px">
              {' '}
              Referral Program Perks
              <Typography
                component="span"
                onClick={() => navigate(`${APP_ROUTES.Mcat}?q1=true`)}
                color={colors.secondary}
                sx={{
                  textDecoration: 'underline',
                  ml: 1,
                  cursor: 'pointer',
                  whiteSpace: 'nowrap'
                }}>
                {' '}
                Learn More
              </Typography>
            </Typography>
          </Box>
          <Box
            onClick={() => navigate(`${APP_ROUTES.Mcat}?q1=true`)}
            component="img"
            src={finish}
            width={{ xs: '80px', md: '100px' }}
            sx={{
              // position: { xs: "relative", sm: "absolute" },
              position: 'absolute',
              bottom: '3%',
              right: { xs: 0, sm: '2%' },
              marginLeft: 'auto',
              cursor: 'pointer'
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default SubCard;
