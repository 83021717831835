import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  Grid,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import colors from '../colorConfig/colors.js';

function PlanNBilling() {
  const [open, setOpen] = useState(false);
  const [subscribed, setSubscribed] = useState(true);

  const handleChangeRefresh = () => {
    setSubscribed(true);
  };

  const handleChangePlan = () => {
    setSubscribed(false);
    setOpen(false);
  };

  const handleChangeCancelPlan = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={{ maxWidth: '600px', whiteSpace: 'wrap' }}>
      <Typography variant="h6" sx={{ fontWeight: 400 }}>
        Plan Details
      </Typography>

      <Card
        sx={{
          borderRadius: '10px',
          border: `1px solid ${colors.darkGrey}`,
          mt: '15px',
          padding: { xs: '5px 10px', md: '10px 25px' }
        }}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            <Typography variant="body1" sx={{ fontWeight: '700' }}>
              Plan Status
            </Typography>

            {subscribed ? (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: '700',
                  ml: '20px',
                  border: 'none',
                  borderRadius: '10px',
                  p: '5px 15px',
                  color: colors.primary,
                  backgroundColor: colors.green
                }}>
                Subscribed
              </Typography>
            ) : (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: '700',
                  ml: '20px',
                  border: 'none',
                  borderRadius: '10px',
                  p: '5px 15px',
                  color: colors.darkRed,
                  backgroundColor: colors.red
                }}>
                Unsubscribed
              </Typography>
            )}
          </Box>
          <Box sx={{ mt: '10px' }}>
            <Typography variant="body1" sx={{ fontWeight: '700' }}>
              $40 CAD/ month
            </Typography>
          </Box>
          {subscribed ? (
            <Box sx={{ mt: '10px' }}>
              <Typography variant="body1" sx={{ fontWeight: '500', color: colors.darkGrey }}>
                Next Billing Date: June 10, 2023
              </Typography>
            </Box>
          ) : (
            <Box sx={{ mt: '10px' }}>
              <Typography variant="body1" sx={{ fontWeight: '500', color: colors.darkGrey }}>
                You are no longer being billed.
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
      {subscribed ? (
        <Box sx={{ mt: '20px' }}>
          <Stack direction="row">
            <Button
              color="secondary"
              variant="contained"
              sx={{
                width: '100%'
              }}>
              Update Payment Method, View Invoices
            </Button>
          </Stack>
        </Box>
      ) : (
        <Box sx={{ mt: '20px' }}>
          <Stack direction="row">
            <Button
              color="secondary"
              variant="contained"
              sx={{
                width: '100%'
              }}>
              Subscribe to continue using Arc
            </Button>
          </Stack>
        </Box>
      )}
      <Box sx={{ mt: '10px', textAlign: 'center' }}>
        <Typography variant="body1" sx={{ fontWeight: '500', color: colors.darkGrey }}>
          View Arc Academic’s Terms of Service and Privacy Policy
        </Typography>
      </Box>

      {subscribed ? (
        <Box sx={{ mt: '10px', textAlign: 'center' }}>
          <Button
            disableRipple
            variant="outlined"
            color="error"
            sx={{ fontWeight: '500', color: colors.darkRed }}
            onClick={handleChangeCancelPlan}>
            Cancel Plan
          </Button>
        </Box>
      ) : (
        <Box sx={{ mt: '10px', textAlign: 'center' }}>
          <Button
            disableRipple
            variant="outlined"
            color="error"
            sx={{ fontWeight: '500', color: colors.darkRed }}
            onClick={handleChangeRefresh}>
            !Refresh!
          </Button>
        </Box>
      )}

      <Dialog onClose={handleClose} open={open} maxWidth={'sm'} fullWidth>
        <Card sx={{ padding: '1.5rem 2rem !important', overflowY: 'scroll' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} style={{ display: 'flex' }}>
              <Typography variant="body1" sx={{ fontWeight: '700' }}>
                Are you sure you want to cancel?
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} style={{ display: 'flex' }}>
              <Typography variant="body2" sx={{ fontWeight: '500' }}>
                You will lose access to Arc. You’ll still have access to Arc until your plan ends on
                June 11, 2023.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} style={{ display: 'flex', textAlign: 'center' }}>
              <Typography variant="body2" sx={{ fontWeight: '500', color: colors.darkGrey }}>
                We&apos;re sorry to see you go! Can you let us know why you&apos;re canceling? Your
                feedback will help us improve Arc.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                size="small"
                id="outlined-basic"
                fullWidth
                rows={4}
                multiline
                variant="outlined"
                placeholder="Enter message"
                sx={{ borderRadius: '20px' }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                justifyContent: { xs: 'unset', sm: 'flex-end' },
                marginTop: '20px'
              }}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="outlined"
                  sx={{
                    color: colors.primary,
                    borderColor: colors.primary,
                    fontWeight: 'bold',
                    '&:hover': { borderColor: `${colors.primary} !important` }
                  }}
                  onClick={handleClose}>
                  Go Back
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ whiteSpace: 'nowrap' }}
                  onClick={handleChangePlan}>
                  Cancel on Next Page
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </Dialog>
    </Box>
  );
}

export default PlanNBilling;
