import { Box, Typography, Avatar, TextField, Grid, Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import colors from '../../components/common/colorConfig/colors';
import bg from '../../assets/image/BG.png';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import HttpService from '../../hooks/Https-services';
import { API_ROUTES } from '../../utils/constants';

const ContactUs = () => {
  const onChange = (value: any) => {
    setRecaptchaValue(value);
  };
  const captchaRef = useRef<any>();
  const [recaptchaValue, setRecaptchaValue] = useState('');

  // const captchaToken = await recaptchaRef.current.executeAsync();
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    unregister,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data: any) => {
    try {
      const dataBody: any = {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        message: data.message,
        captchaToken: recaptchaValue
      };
      const response: any = await HttpService.post(API_ROUTES.ContactUs, dataBody);
      toast.success('Submited successfully');
      reset();
    } catch (err: any) {
      console.log(err);
    } finally {
      await captchaRef.current.reset();
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <Box sx={{ backgroundColor: '#F6F6F6' }}>
      <Box
        sx={{
          backgroundColor: colors.primary
        }}>
        <Box
          height="auto"
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(auto-fit,1fr)',
              md: '0.2fr 1.5fr'
            },
            alignItems: 'center',
            // justifyContent: "space-between",
            gap: 3,
            mx: 2,
            // alignItems: "center",
            py: { xs: 5, sm: 10 }
          }}>
          <Box
            component="img"
            src={bg}
            height={460}
            sx={{ display: { xs: 'none', md: 'block' } }}
          />
          <Box width={{ xs: '80%', md: '80%', lg: '60%' }} sx={{ justifySelf: 'center' }}>
            <Typography
              variant="h2"
              color="white"
              sx={{
                background: 'linear-gradient(90deg, #06C7BB 0.21%, #FFF 39.69%)',
                backgroundClip: 'text',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent'
              }}
              mx={1}
              fontSize={{ xs: 42, md: '3.75rem' }}>
              Contact Us
            </Typography>
            <Typography
              variant="h6"
              color="white"
              my={4}
              fontWeight={400}
              mx={1}
              fontSize={{ xs: 16, md: '1.25rem' }}>
              Got questions or need assistance? We`&apos;`re here to help! Reach out to us using the
              form below, and our dedicated support team will promptly respond to your inquiries.
            </Typography>
            <form
              onSubmit={handleSubmit(onSubmit, (err) => {
                console.log(err);
              })}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="First Name"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { color: 'white' } // Set the text color to white
                    }}
                    {...register('firstName', {
                      required: 'This field is required.'
                    })}
                    sx={{
                      width: '100%', // Full width within the grid item
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'white' // Set the border color to white for default state
                        },
                        '&:hover fieldset': {
                          borderColor: 'white' // Set the border color to white on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'white' // Set the border color to white when focused
                        }
                      },
                      '& .MuiFormLabel-root': {
                        color: `${colors.secondary} !important`
                      }
                    }}
                    error={!!errors.firstName}
                    helperText={
                      errors.firstName && (
                        <Typography variant="caption" sx={{ color: 'red' }}>
                          {errors.firstName.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {' '}
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { color: 'white' } // Set the text color to white
                    }}
                    {...register('lastName', {
                      required: 'This field is required.'
                    })}
                    sx={{
                      width: '100%', // Full width within the grid item
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'white' // Set the border color to white for default state
                        },
                        '&:hover fieldset': {
                          borderColor: 'white' // Set the border color to white on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'white' // Set the border color to white when focused
                        }
                      },
                      '& .MuiFormLabel-root': {
                        color: `${colors.secondary} !important`
                      }
                    }}
                    error={!!errors.lastName}
                    helperText={
                      errors.lastName && (
                        <Typography variant="caption" sx={{ color: 'red' }}>
                          {errors.lastName.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  {' '}
                  <TextField
                    label="Email"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    {...register('email', {
                      required: 'This field is required.',
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: 'Please enter valid email.'
                      }
                    })}
                    InputProps={{
                      style: { color: 'white' } // Set the text color to white
                    }}
                    sx={{
                      width: '100%', // Full width within the grid item
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'white' // Set the border color to white for default state
                        },
                        '&:hover fieldset': {
                          borderColor: 'white' // Set the border color to white on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'white' // Set the border color to white when focused
                        }
                      },
                      '& .MuiFormLabel-root': {
                        color: `${colors.secondary} !important`
                      }
                    }}
                    error={!!errors.email}
                    helperText={
                      errors.email && (
                        <Typography variant="caption" sx={{ color: 'red' }}>
                          {errors.email.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  {' '}
                  <TextField
                    label="Message"
                    variant="outlined"
                    multiline // Enable multiline input
                    rows={4} // Number of v
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { color: 'white' } // Set the text color to white
                    }}
                    {...register('message', {
                      required: 'This field is required.'
                    })}
                    sx={{
                      width: '100%', // Full width within the grid item
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'white' // Set the border color to white for default state
                        },
                        '&:hover fieldset': {
                          borderColor: 'white' // Set the border color to white on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'white' // Set the border color to white when focused
                        }
                      },
                      '& .MuiFormLabel-root': {
                        color: `${colors.secondary} !important`
                      }
                    }}
                    error={!!errors.message}
                    helperText={
                      errors.message && (
                        <Typography variant="caption" sx={{ color: 'red' }}>
                          {errors.message.message as any}
                        </Typography>
                      )
                    }
                  />
                </Grid>
              </Grid>

              <Box mt={3} sx={{ transform: 'scale(0.82)', transformOrigin: '0 0' }}>
                <ReCAPTCHA
                  sitekey="6LctkPwoAAAAAJC2KTBYjdw9R_qMn3hvBpZN3KzN"
                  onChange={onChange}
                  ref={captchaRef}
                />
              </Box>

              <Button
                variant="contained"
                type="submit"
                sx={{
                  mt: 1,
                  fontWeight: '600',

                  textTransform: 'none'
                }}>
                Submit
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactUs;
