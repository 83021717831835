import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  Checkbox,
  Collapse,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CalendarComp from '../CalendarComp';
import moment from 'moment';
import { API_ROUTES, TimeZoneData } from '../../../utils/constants';
import Scheduler from './Scheduler';
import Calendars from './Calendars';
import HttpService from '../../../hooks/Https-services';
import { toast } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleCalendar from '../../../pages/Onboard/Calendar';
import microsft from '../../../assets/image/microsoft.png';
import { useSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

const CalendarNSchedules = () => {
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [emails, setEmails] = useState([]);
  const [scheduler, setScheduler] = useState([]);
  const [events, setEvents] = useState<any>([]);
  const [duration, setDuration] = useState('GMT+05:30');
  const min = ['15', '30', '45', '60'];
  const hour = ['1', '2', '3', '4'];
  const [minute, setMinute] = useState('15');
  const [hours, setHours] = useState('2');
  const [calendarName, setCalendarName] = useState('');
  const [load1, setLoad1] = useState(false);
  const [load2, setLoad2] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearcParams] = useSearchParams();
  const code = searchParams.get('code');
  const skt = ['', '', ''];
  const [onlyArcCalendar, setOnlyArcCalendar] = useState(false);
  const [markAsFree, setMarkAsFree] = useState(false);
  const [markAsBusy, setMarkAsBusy] = useState(false);

  const handleOnlyArcCalendarChange = (event: any) => {
    setOnlyArcCalendar(event.target.checked);
  };

  const handleMarkAsFreeChange = (event: any) => {
    setMarkAsFree(event.target.checked);
  };

  const handleMarkAsBusyChange = (event: any) => {
    setMarkAsBusy(event.target.checked);
  };
  const handleChangeDuration = (event: any) => {
    setDuration(event.target.value as string);
  };
  const handleChangeMin = (event: any) => {
    setMinute(event.target.value as string);
  };
  const handleChangeHour = (event: any) => {
    setHours(event.target.value as string);
  };

  const setTaskconfiguration = async () => {
    try {
      // console.log(onlyArcCalendar, markAsFree, markAsBusy);

      const dataBody = {
        tasks_on_arc_only: onlyArcCalendar,
        tasks_on_google_outlook_free: markAsFree,
        tasks_on_google_outlook_busy: markAsBusy
      };

      setIsLoading(true);
      await HttpService.post(API_ROUTES.taskConfiguration, dataBody);

      toast.success('Task configuration successfully');
      getCalendar();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const createSevenDaysEvent = () => {
    // Get the current date and time
    const now = moment();

    // Calculate the start of the week (Monday) at 11 AM
    const startOfWeek = now.clone().startOf('isoWeek').hour(11).minute(0).second(0);

    // Create an array to store the schedule for 7 days
    const weekSchedule = [];

    for (let i = 0; i < 6; i++) {
      const dayStart = startOfWeek.clone();
      const dayEnd = startOfWeek.clone().hour(15);

      weekSchedule.push({
        id: i + 1,
        title: ``,
        start: dayStart.format('YYYY-MM-DDTHH:mm:ss'),
        end: dayEnd.format('YYYY-MM-DDTHH:mm:ss')
      });

      // Move to the next day
      startOfWeek.add(1, 'days');
    }
    setEvents(weekSchedule);
    console.log('click');
  };
  const getScheduler = async () => {
    try {
      const res = await HttpService.get(API_ROUTES.GetScheduler);
      const temp: any = [];
      res.data.data.map((ele: any) => {
        const name = ele.schedule_name;
        const event = ele.prefered_time;
        const id = ele.calendar_schedule_sid;
        temp.push({ name: name, events: event, id: id });
      });
      const res2 = await HttpService.get(API_ROUTES.taskConfiguration);
      setMarkAsBusy(res2.data.data.tasks_on_google_outlook_busy);
      setMarkAsFree(res2.data.data.tasks_on_google_outlook_free);
      setOnlyArcCalendar(res2.data.data.tasks_on_arc_only);

      setScheduler(temp);
      setLoad2(false);
    } catch (error) {
      setLoad2(false);
      console.log(error);
    }
  };
  const getCalendar = async () => {
    try {
      setLoad1(true);
      const res = await HttpService.get(API_ROUTES.GetGoogleCalendar);
      const tempEmail: any = [];
      res.data.data.map((ele: any) => {
        const id = ele.user_calendar_sid;
        const email = ele.user_email;
        const type = ele.calendar_type;
        tempEmail.push({ id: id, email: email, type: type });
      });
      setEmails(tempEmail);
      setLoad1(false);
    } catch (error: any) {
      setLoad1(false);
      console.log(error);
    }
  };
  useEffect(() => {
    createSevenDaysEvent();
    getCalendar();
    getScheduler();
    if (code) {
      setMsCalendar(code);
      console.log(code);
      searchParams.delete('session_state');
      searchParams.delete('code');
      setSearcParams(searchParams);
    }
  }, []);
  const setCalendar = async () => {
    try {
      const data = {
        schedule_name: calendarName,
        prefered_time: events,
        timezone: duration
      };
      const res = await HttpService.post(API_ROUTES.SetScheduler, data);
      setOpenEdit(false);
      toast.success('Scheduler added successfully');
      getScheduler();
      createSevenDaysEvent();
    } catch (error) {
      console.log(error);
    }
  };
  const setMsCalendar = async (code: any) => {
    try {
      setIsLoading(true);
      const res = await HttpService.post(API_ROUTES.RegisterMsCalendar, { code: code });
      toast.success('Calendar added successfully');
      setOpenAdd(false);
      getCalendar();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const getmsaltokens = () => {
    window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_MICROSOFT_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_REDIRECT_URL_ACCOUNT}&response_mode=query&scope=offline_access user.read calendars.read Calendars.ReadWrite&code_challenge=${process.env.REACT_APP_MICROSOFT_CODECALLANGE}&code_challenge_method=S256&prompt=consent`;
  };
  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 3, flexDirection: { xs: 'column', md: 'row' } }}>
        <Box sx={{ flex: 0.6 }}>
          <Box
            sx={{
              maxWidth: '600px',
              whiteSpace: 'wrap',
              display: 'flex',
              flexDirection: 'column',
              gap: 1.5
            }}>
            <Typography variant="h6" sx={{ fontWeight: 400 }}>
              My Calendar
            </Typography>
            {!load1
              ? emails.map((e: any, idx) => (
                  <Calendars
                    email={e.email}
                    key={e.id}
                    id={e.id}
                    type={e.type}
                    getCalendar={getCalendar}
                  />
                ))
              : skt.map((ele, idx) => <Skeleton variant="rectangular" height={44} key={idx} />)}
            <Button
              variant="contained"
              fullWidth
              color="secondary"
              sx={{ borderRadius: '6px' }}
              onClick={() => setOpenAdd(true)}>
              + Add Account
            </Button>
            <Dialog
              open={openAdd}
              maxWidth="md"
              onClose={() => setOpenAdd(false)}
              sx={{
                '& .MuiDialog-paper': {
                  backgroundColor: 'transparent', // Set the background color to transparent
                  boxShadow: 'none', // Remove the box shadow
                  borderRadius: '0' // Remove the border radius
                }
              }}>
              <Card
                sx={{
                  p: { xs: 3, sm: 4 },
                  px: { xs: 3, sm: 6 },
                  maxWidth: '300px',
                  borderRadius: '20px'
                }}>
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1
                    }}>
                    {/* <GoogleCalendar /> */}
                    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CALENDAR_ID || ''}>
                      <GoogleCalendar
                        setEmail={setEmails}
                        getCalendar={getCalendar}
                        setOpenAdd={setOpenAdd}
                      />
                    </GoogleOAuthProvider>
                    <LoadingButton
                      loading={isLoading}
                      variant="outlined"
                      color="secondary"
                      sx={{
                        border: '1px solid',
                        fontWeight: 700,
                        whiteSpace: 'nowrap'
                      }}
                      onClick={getmsaltokens}>
                      <Box component="img" src={microsft} width={16} mr={2} />
                      Log in with Microsoft
                    </LoadingButton>
                  </Box>
                </Box>
              </Card>
            </Dialog>
            <Typography color="GrayText" textAlign="center">
              View Arc Academic’s Terms of Service and Privacy Policy{' '}
            </Typography>
            <Box sx={{ margin: '0 auto' }}>
              <Button variant="contained" color="error" sx={{ borderRadius: '6px' }}>
                Delete tasks, syncing, and focus time
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              maxWidth: '600px',
              whiteSpace: 'wrap',
              display: 'flex',
              flexDirection: 'column',
              mt: 5,
              gap: 1.5
            }}>
            <Typography variant="h6" sx={{ fontWeight: 400 }}>
              Arc Schedules
            </Typography>
            {!load2
              ? scheduler.map((e: any) => (
                  <Box key={e.id}>
                    <Scheduler
                      events={e.events}
                      key={e}
                      name={e.name}
                      id={e.id}
                      getScheduler={getScheduler}
                    />
                  </Box>
                ))
              : skt.map((ele, idx) => <Skeleton variant="rectangular" height={44} key={idx} />)}
            <Box>
              <Button
                variant="contained"
                fullWidth
                color="secondary"
                sx={{ borderRadius: '6px' }}
                onClick={() => {
                  setOpenEdit(true);
                }}>
                + Add Schedules
              </Button>
              <Typography color="black" mt={1}>
                Break between tasks
              </Typography>

              <Typography>
                <Checkbox />
                Schedule a{' '}
                {
                  <Select size="small" value={minute} sx={{ mt: '6px' }} onChange={handleChangeMin}>
                    {min.map((ele) => (
                      <MenuItem value={ele} key={ele}>
                        {ele}
                      </MenuItem>
                    ))}
                  </Select>
                }{' '}
                minute break every{' '}
                {
                  <Select size="small" value={hours} sx={{ mt: '6px' }} onChange={handleChangeHour}>
                    {hour.map((ele) => (
                      <MenuItem value={ele} key={ele}>
                        {ele}
                      </MenuItem>
                    ))}
                  </Select>
                }{' '}
                hour(s)
              </Typography>
            </Box>
            <Dialog
              open={openEdit}
              maxWidth="md"
              sx={{
                '& .MuiDialog-paper': {
                  backgroundColor: 'transparent', // Set the background color to transparent
                  boxShadow: 'none', // Remove the box shadow
                  borderRadius: '0' // Remove the border radius
                }
              }}>
              <Card
                sx={{
                  p: { xs: 3, sm: 4 },
                  px: { xs: 3, sm: 8 },
                  width: '600px',
                  borderRadius: '20px'
                }}>
                <Typography mb={2}>Create a new Schedule</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography>Schedule name</Typography>
                    <TextField
                      sx={{ fontWeight: 500, mt: '6px' }}
                      size="small"
                      id="outlined-basic"
                      fullWidth
                      variant="outlined"
                      placeholder="Schedule name"
                      onChange={(e) => {
                        setCalendarName(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>Schedule Timezone</Typography>
                    <FormControl>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        fullWidth
                        value={duration}
                        sx={{ mt: '6px' }}
                        onChange={handleChangeDuration}>
                        {TimeZoneData.map((ele) => (
                          <MenuItem value={ele.offset} key={ele.offset}>
                            {ele.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Typography color="GrayText" my={1.5}>
                  Drag to change your hours
                </Typography>
                <CalendarComp events={events} setEvent={setEvents} />
                <CardActions sx={{ gap: 1, mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setOpenEdit(false);
                      createSevenDaysEvent();
                    }}>
                    cancel
                  </Button>
                  <Button variant="contained" color="secondary" onClick={setCalendar}>
                    Save
                  </Button>
                </CardActions>
              </Card>
            </Dialog>
          </Box>
        </Box>
        <Box sx={{ flex: 0.4 }}>
          <Typography mb={1}>Show tasks on Google & Outlook Calendars</Typography>
          <FormGroup sx={{ gap: 0.5 }}>
            <FormControlLabel
              control={
                <Checkbox checked={onlyArcCalendar} onChange={handleOnlyArcCalendarChange} />
              }
              label="Only show tasks on Arc Calendar"
            />
            <FormControlLabel
              control={<Checkbox checked={markAsFree} onChange={handleMarkAsFreeChange} />}
              label="Show tasks on Google & Outlook Calendars, mark as free"
            />
            <FormControlLabel
              control={<Checkbox checked={markAsBusy} onChange={handleMarkAsBusyChange} />}
              label="Show tasks on Google & Outlook Calendars, mark as busy"
            />
          </FormGroup>
          <Box sx={{ mt: '50px' }}>
            <Stack spacing={2} direction="row-reverse">
              <Button variant="contained" onClick={setTaskconfiguration}>
                Save
              </Button>
            </Stack>
          </Box>
          {/* <Typography mt={2.5} mb={1}>
            Display tasks on Google & Outlook Calendars
          </Typography>
          <FormGroup sx={{ gap: 0.5 }}>
            <FormControlLabel
              control={<Checkbox />}
              label="Display task names on Google & Outlook Calendars"
            />
            <FormControlLabel
              control={<Checkbox />}
              label="Show tasks on Google & Outlook Calendars, mark as busy"
            />
          </FormGroup> */}
        </Box>
      </Box>
    </Box>
  );
};

export default CalendarNSchedules;
