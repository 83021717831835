import { useNavigate } from 'react-router-dom';

import { API_ROUTES } from '../utils/constants';
import { getCurrentAuth, Logout } from './useAxiosPrivate';
import axios from '../utils/axios';
import { authDetailsType } from './http-server';

export function setAuth(data: authDetailsType) {
  const oldAuth = getCurrentAuth();
  const tempAuth = { ...oldAuth, ...data };
  tempAuth.access.token = data.access.token;
  tempAuth.refresh.token = data.refresh.token;
  localStorage.setItem('auth', JSON.stringify(tempAuth));
}

const useRefreshToken = () => {
  const navigate = useNavigate();

  // var auth = useTypedSelector(getAuthState, shallowEqual);
  const auth = getCurrentAuth();

  const refresh = async () => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `bearer ${auth.access}`
      };
      const response = await axios.post(
        API_ROUTES.RefreshToken,
        {
          refreshToken: auth.refresh
        },
        { headers: headers }
      );
      if (response.data) {
        const body: authDetailsType = response.data;
        setAuth(body);
        return response.data.access.token;
      }
      return;
    } catch (error: any) {
      // dispatch(logout());
      Logout();
      // navigate(PageRoutes.Login);
    }
  };
  return refresh;
};

export default useRefreshToken;
