import { Avatar, Box, Button, Card, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import colors from '../../components/common/colorConfig/colors';
import PostCard from '../BlogPostPage/PostCard';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../utils/constants';

const PostPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [tempCard, setTempCard] = useState(['', '', '']);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <Box sx={{ backgroundColor: '#F6F6F6' }}>
      <Box
        sx={{
          // minHeight: { xs: "72vh", sm: "60vh" },
          backgroundColor: colors.primary,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
          // resize: "both",
        }}>
        <Box
          height="auto"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 3,
            mx: 2,
            // alignItems: "center",
            py: { xs: 5, sm: 10 }
          }}>
          <Typography
            variant="h2"
            color="white"
            sx={{
              background: 'linear-gradient(90deg, #06C7BB 0.21%, #FFF 39.69%)',
              backgroundClip: 'text',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }}
            mx={2.5}
            fontSize={{ xs: 42, md: '3.75rem' }}>
            Title of Blog Post Goes Here...
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2
            }}>
            <Avatar src="" sx={{ width: 55, height: 55, ml: 1.5 }} />
            <Typography
              variant="h6"
              color="white"
              fontSize={{ xs: 16, md: '1.25rem', textAlign: 'center' }}>
              Arc Academic
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Container
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(auto-fit,1fr)',
              sm: '1.5fr 1fr'
            },
            pb: 4,
            alignItems: 'baseline',
            borderBottom: '1px solid lightgray'
          }}>
          <Typography
            variant="body1"
            fontSize={{ xs: '0.90rem', md: '1rem' }}
            mx={{ xs: 2, md: 2 }}>
            <Typography variant="h6" fontWeight={700} my={3} fontSize={{ xs: 16, md: '1.25rem' }}>
              Sample Text
            </Typography>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis officia, at asperiores
            ut adipisci eius fugiat consequatur, perspiciatis veniam quaerat corporis repudiandae
            unde quia voluptate fuga rem amet? Amet, iusto.
            <br /> <br /> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Architecto
            similique ipsam officiis, quisquam unde impedit blanditiis porro odit fuga pariatur,
            accusantium est inventore perferendis quae? Ex, error assumenda! Quam, vitae?
            <br /> <br /> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Neque ipsum
            quasi in praesentium nesciunt nisi vero optio ea veritatis do <br /> <br />
            lorem. Blanditiis laborum velit enim sequi expedita iusto consequatur vitae temporibus?{' '}
            <br /> <br />
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, pariatur tempora ea
            voluptatibus laudantium, aliquam alias minus odio optio totam, esse quis iusto voluptate
            blanditiis tenetur molestiae quibusdam corporis recusandae?
            <br /> <br /> Lorem ipsum dolor sit amet consectetur adipisicing elit. In suscipit
            laboriosam eaque quas sequi beatae consequatur, minus ex. Impedit, commodi iste enim
            tempora sequi alias eligendi nesciunt voluptate ullam labore.
            <br /> <br /> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Architecto
            similique ipsam officiis, quisquam unde impedit blanditiis porro odit fuga pariatur,
            accusantium est inventore perferendis quae? Ex, error assumenda! Quam, vitae?
            <br /> <br />
          </Typography>
          <Box sx={{ justifySelf: { xs: 'center', sm: 'right' } }}>
            <Typography variant="h6" fontWeight={700} fontSize={{ xs: 16, md: '1.25rem' }} ml={1}>
              Free Resource
            </Typography>
            <Card
              sx={{
                width: '340px',
                // height: "364px",
                // width: "564px",
                mt: 2,
                borderRadius: '20px '
                // backgroundColor: "red",
              }}>
              <Box
                sx={{
                  background: colors.lighter,
                  height: '236px',
                  borderRadius: '20px ',
                  width: '282px',
                  margin: 'auto',
                  mt: 3.5
                }}></Box>
              <Box sx={{ width: '282px', margin: 'auto' }}>
                <Button
                  variant="contained"
                  onClick={() => navigate(`${location.pathname}?q1=true`)}
                  size="small"
                  fullWidth
                  sx={{
                    textTransform: 'none',
                    fontWeight: '700',
                    fontSize: '14px',
                    my: 3,
                    mb: 3,
                    px: 3
                  }}>
                  DOWNLOAD
                </Button>
              </Box>
            </Card>
            <Typography
              variant="h6"
              fontWeight={700}
              fontSize={{ xs: 16, md: '1.25rem' }}
              mt={4}
              ml={1}>
              Study with us
            </Typography>
            <Card
              sx={{
                width: '340px',
                // height: "364px",
                // width: "564px",
                mt: 2,
                borderRadius: '20px '
                // backgroundColor: "red",
              }}>
              <Box
                sx={{
                  width: '282px',
                  margin: 'auto',
                  mt: 3.5
                }}>
                <Typography variant="h6" fontSize={{ xs: 16, md: '1.25rem' }}>
                  The help you need to optimize your life and studying.
                </Typography>
              </Box>
              <Box sx={{ width: '282px', margin: 'auto' }}>
                <Button
                  onClick={() => navigate(`${APP_ROUTES.Post}?q1=true`)}
                  variant="contained"
                  size="small"
                  fullWidth
                  sx={{
                    textTransform: 'none',
                    fontWeight: '700',
                    fontSize: '14px',
                    my: 3,
                    mb: 3,
                    px: 3
                  }}>
                  FREE TRIAL
                </Button>
              </Box>
            </Card>
          </Box>
        </Container>
        <Container>
          <Typography
            variant="h6"
            fontWeight={700}
            fontSize={{ xs: 16, md: '1.25rem' }}
            my={4}
            ml={1}>
            Other posts you might like:
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: {
                xs: 'center',
                lg: 'space-between'
              },
              flexWrap: { xs: 'wrap', lg: 'nowrap' },
              mt: 2,
              pb: 4,
              gap: 3
            }}>
            {/* Add spacing between grid items */}
            {tempCard.map((post: any, ind: any) => (
              <Box key={ind + 1}>
                <PostCard
                  name={`Title of Blog Post ${ind + 1}`}
                  tags={`Tag ${ind + 1}`}
                  bColor={ind % 2 == 0 ? '' : '#FF5F37'}
                />
              </Box>
            ))}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default PostPage;
