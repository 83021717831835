import { Box, Button, Card, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';
import colors from '../../../components/common/colorConfig/colors';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../utils/constants';

const PostCard = (props: any) => {
  const { name, tags, bColor, id } = props;
  const navigate = useNavigate();
  return (
    <Card
      key={id}
      sx={{
        width: '340px',
        // height: "364px",
        // width: "564px",
        borderRadius: '20px ',
        // backgroundColor: "red",
        cursor: 'pointer'
      }}
      onClick={() => {
        navigate(APP_ROUTES.Post);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }}>
      <Box
        sx={{
          background: colors.lighter,
          height: '236px',
          borderRadius: '20px ',
          width: '282px',
          margin: 'auto',
          mt: 3.5
        }}></Box>
      <Box sx={{ width: '282px', margin: 'auto' }}>
        <Typography
          variant="h6"
          fontWeight={700}
          mt={1.5}
          ml={1}
          fontSize={{ xs: 16, md: '1.15rem' }}>
          {name}
        </Typography>
        <Button
          variant="contained"
          size="small"
          sx={{
            backgroundColor: bColor ? bColor : '',
            textTransform: 'none',
            fontWeight: '700',
            fontSize: '14px',
            ml: 1,
            mt: 1.5,
            mb: 3,
            px: 3,
            borderRadius: '20px',
            '&:hover': {
              backgroundColor: bColor ? bColor : ''
            }
          }}>
          {tags}
        </Button>
      </Box>
    </Card>
  );
};

export default PostCard;
