import { Box, Typography, Button, Card, CardMedia, Container, CardContent } from '@mui/material';
import React, { useEffect } from 'react';
import colors from '../../components/common/colorConfig/colors';
import icon1 from '../../assets/image/creditcard.png';
import icon2 from '../../assets/image/users.png';
import icon3 from '../../assets/image/reward.png';
import reward from '../../assets/image/Pricing.png';
import arrow from '../../assets/image/arrow.png';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../utils/constants';

const Refer = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <Box>
      {' '}
      <Box
        sx={{
          // minHeight: { xs: "72vh", sm: "60vh" },
          backgroundColor: colors.primary,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
          // resize: "both",
        }}>
        <Box
          height="auto"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            py: { xs: 5, sm: 10 }
          }}>
          <Typography
            variant="h2"
            color="white"
            sx={{
              background: 'linear-gradient(90deg, #06C7BB 0.21%, #FFF 39.69%)',
              backgroundClip: 'text',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent',
              textAlign: 'center'
            }}
            mx={2.5}
            fontSize={{ xs: 42, md: '3.75rem' }}>
            Share the Success - Refer and Save!
          </Typography>
          <Typography
            variant="h6"
            color="white"
            my={4}
            fontWeight={400}
            mx={2}
            fontSize={{ xs: 16, md: '1.25rem', textAlign: 'center' }}>
            It`&apos;`s a win-win! You`&apos;` ll receive a discount as a thank-you for referring
            your friend, and your friend will also receive a <br />
            discount as they join the community of successful MCAT preppers.
          </Typography>
          <Button
            onClick={() => navigate(`${APP_ROUTES.Refer}?q=true`)}
            variant="contained"
            sx={{
              mb: 4,
              fontWeight: '700'
            }}>
            REFER YOUR FRIENDS
          </Button>
        </Box>
      </Box>
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            p: 2,
            backgroundColor: 'white',
            borderRadius: '60px'
          }}>
          <Box
            component="img"
            src={arrow}
            height={30}
            width={17}
            sx={{
              px: 3.1,
              py: 2.3,
              border: '1px solid gray',
              borderRadius: '60px',
              backgroundColor: '#fff'
            }}
          />
        </Box>
      </Box>
      <Typography
        variant="h6"
        fontWeight={600}
        color={colors.secondary}
        my={4}
        py={4}
        fontSize={{ xs: 16, md: '1.25rem', textAlign: 'center' }}>
        HOW IT WORKS
      </Typography>
      <Box mt={4} pb={5}>
        <Container>
          <Box
            sx={{
              display: 'flex',
              justifyContent: {
                xs: 'center',
                lg: 'space-between'
              },
              flexWrap: { xs: 'wrap', lg: 'nowrap' },
              mt: 2,
              pb: 4,
              gap: 3
            }}>
            <Card
              sx={{
                width: { xs: '80%', sm: '446px' },
                // width: "564px",
                borderRadius: '20px ',
                backgroundColor: colors.lighter
              }}>
              <CardMedia
                component="img"
                image={icon1}
                sx={{
                  height: { xs: 90, sm: 140 },
                  width: { xs: 120, sm: 170 },
                  margin: '0 auto',
                  mt: 3
                }}
              />
              <CardContent>
                <Typography
                  variant="h6"
                  fontWeight={600}
                  mx={1}
                  fontSize={{ xs: 16, md: '1.25rem', textAlign: 'center' }}>
                  Sign up and receive your unique referral code and personalized referral link.
                </Typography>
              </CardContent>
            </Card>
            <Card
              sx={{
                width: { xs: '80%', sm: '446px' },
                // width: "564px",
                borderRadius: '20px ',
                backgroundColor: colors.lighter
              }}>
              <CardMedia
                component="img"
                image={icon2}
                sx={{
                  height: { xs: 100, sm: 140 },
                  width: { xs: 120, sm: 170 },
                  margin: '0 auto',
                  mt: 3
                }}
              />
              <CardContent>
                <Typography
                  variant="h6"
                  fontWeight={600}
                  mx={1}
                  fontSize={{ xs: 16, md: '1.25rem', textAlign: 'center' }}>
                  Share your code and link with friends who are preparing for the MCAT.
                </Typography>
              </CardContent>
            </Card>
            <Card
              sx={{
                width: { xs: '80%', sm: '446px' },
                // width: "564px",
                borderRadius: '20px ',
                backgroundColor: colors.lighter
              }}>
              <CardMedia
                component="img"
                image={icon3}
                sx={{
                  height: { xs: 90, sm: 140 },
                  width: { xs: 120, sm: 170 },
                  margin: '0 auto',
                  mt: 3
                }}
              />
              <CardContent>
                <Typography
                  variant="h6"
                  fontWeight={600}
                  mx={1}
                  fontSize={{ xs: 16, md: '1.25rem', textAlign: 'center' }}>
                  Share your code and link with friends who are preparing for the MCAT.
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          // minHeight: { xs: "72vh", sm: "60vh" },
          backgroundColor: colors.primary,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
          // resize: "both",
        }}>
        <Box
          height="auto"
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(auto-fit,1fr)',
              md: '1fr 1fr'
            },
            alignItems: 'center',
            // justifyContent: "space-between",
            gap: 3,
            mx: 2,
            // alignItems: "center",
            py: { xs: 5, sm: 10 }
          }}>
          <Box
            // width={{ xs: "70%", md: "80%", lg: "60%" }}
            width="90%"
            sx={{ justifySelf: 'center' }}>
            <Typography variant="h2" color="white" mx={1} fontSize={{ xs: 42, md: '3.75rem' }}>
              Empower and Earn Together!
            </Typography>
            <Typography
              variant="h6"
              color="white"
              my={4}
              fontWeight={400}
              mx={1}
              fontSize={{ xs: 16, md: '1.25rem' }}>
              Start referring and saving today! Share the success of our MCAT prep web app with your
              friends and unlock exclusive discounts. Sign up now to get your unique referral code
              and start earning rewards.
            </Typography>
            <Button
              onClick={() => navigate(`${APP_ROUTES.Refer}?q=true`)}
              variant="contained"
              sx={{
                mb: 4,
                fontWeight: '700'
              }}>
              REFER YOUR FRIENDS
            </Button>
          </Box>
          <Box
            component="img"
            src={reward}
            height={{ xs: 150, sm: 230, md: 300, lg: 300 }}
            sx={{ justifySelf: 'center' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Refer;
