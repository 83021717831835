import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Typography,
  Button,
  Card,
  CardActions,
  Dialog,
  IconButton
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CalendarComp from '../../CalendarComp';
import HttpService from '../../../../hooks/Https-services';
import { API_ROUTES } from '../../../../utils/constants';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';

const Scheduler = (props: any) => {
  const { events, name, id, getScheduler } = props;
  const [newEvent, setNewEvent] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setNewEvent(events);
  }, [events]);
  const [open, setOpen] = useState(false);
  const deleteScheduler = async () => {
    try {
      const res = await HttpService.del(API_ROUTES.DeleteScheduler.replace(':id', id));
      toast.success('Schedule Deleted');
      getScheduler();
    } catch (error) {
      console.log(error);
    }
  };
  const updateScheduler = async () => {
    try {
      const data = {
        prefered_time: newEvent
      };
      setIsLoading(true);
      const res = await HttpService.patch(API_ROUTES.UpdateScheduler.replace(':id', id), data);
      toast.success('Schedule Updated');
      getScheduler();
      handleClose();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setNewEvent(events);
  };
  return (
    <Box sx={{ border: '1px solid black', borderRadius: '10px' }}>
      <List
        sx={{
          width: '100%'
        }}
        disablePadding
        component="nav"
        aria-labelledby="nested-list-subheader">
        <ListItemButton
          disableRipple
          sx={{
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }}>
          <ListItemText>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                {name}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => setOpen(true)}
                  sx={{ borderRadius: '8px' }}>
                  Edit
                </Button>
                <IconButton sx={{ ml: 1 }} onClick={deleteScheduler}>
                  <DeleteIcon color="error" />
                </IconButton>
              </Box>
            </Box>
          </ListItemText>
        </ListItemButton>
      </List>
      <Dialog
        open={open}
        maxWidth="md"
        onClose={() => setOpen(false)}
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: 'transparent', // Set the background color to transparent
            boxShadow: 'none', // Remove the box shadow
            borderRadius: '0' // Remove the border radius
          }
        }}>
        <Card
          sx={{
            p: { xs: 3, sm: 4 },
            px: { xs: 3, sm: 8 },
            width: '600px',
            borderRadius: '20px'
          }}>
          <Typography>Select your {name} hours</Typography>
          <Typography color="GrayText" my={1.5}>
            Drag to change your hours
          </Typography>
          <CalendarComp events={newEvent} setEvent={setNewEvent} />
          <CardActions sx={{ gap: 1, mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              cancel
            </Button>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="secondary"
              onClick={updateScheduler}>
              Save Changes
            </LoadingButton>
          </CardActions>
        </Card>
      </Dialog>
    </Box>
  );
};

export default Scheduler;
