import { Avatar, Box, Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import colors from '../../components/common/colorConfig/colors';
import rIcon from '../../assets/image/rihgt.png';
import SubCard from '../../components/common/SubCard';
import Faqs from '../../components/common/Faqs';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../utils/constants';
const Subscription = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <Box sx={{ backgroundColor: '#F6F6F6' }}>
      <Box
        sx={{
          // minHeight: { xs: "72vh", sm: "60vh" },
          backgroundColor: colors.primary,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
          // resize: "both",
        }}>
        <Box
          height="auto"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            py: { xs: 5, sm: 10 }
          }}>
          <Typography
            variant="h2"
            color="white"
            sx={{
              background: 'linear-gradient(90deg, #06C7BB 0.21%, #FFF 39.69%)',
              backgroundClip: 'text',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent',
              textAlign: 'center'
            }}
            mx={2.5}
            fontSize={{ xs: 42, md: '3.75rem' }}>
            Start Your MCAT Prep Journey Today!
          </Typography>
          <Typography
            variant="body1"
            color="white"
            my={4}
            sx={{ textAlign: 'center' }}
            fontSize={{ xs: '0.90rem', md: '1rem' }}
            fontWeight={600}
            mx={2.5}>
            Sign up now and take advantage of our 7-day free trial to experience the full benefits
            of our comprehensive platform.
          </Typography>
          <Typography
            variant="body1"
            color="white"
            mb={4}
            fontSize={{ xs: '0.90rem', md: '1rem' }}
            sx={{ textAlign: 'center' }}
            mx={2.5}>
            We believe in fairness and accessibility, which is why we offer an affordable pricing{' '}
            <br /> structure without any long-term commitments or hefty upfront costs.
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate(`${APP_ROUTES.Mcat}?q1=true`)}
            sx={{
              mb: 4,
              fontWeight: '700'
            }}>
            GET STARTED FREE
          </Button>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'flex-start',
              width: '80%',
              gap: 1,
              flexWrap: 'wrap'
            }}>
            <Typography color="white" fontSize={{ xs: '0.90rem', md: '1rem' }}>
              {' '}
              <Box component="img" height={16} src={rIcon} mr={1} />
              One week free trial
            </Typography>
            <Typography color="white" fontSize={{ xs: '0.90rem', md: '1rem' }}>
              {' '}
              <Box component="img" height={16} src={rIcon} mr={1} />
              No commitment, cancel anytime
            </Typography>
            <Typography color="white" fontSize={{ xs: '0.90rem', md: '1rem' }}>
              {' '}
              <Box component="img" height={16} src={rIcon} mr={1} />
              Simple, transparent pricing
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box mt={4}>
        <SubCard />
      </Box>
      <Box
        sx={{
          display: 'flex',
          mt: 4,
          pb: 10,
          alignItems: 'center',
          flexWrap: 'wrap',
          flexDirection: { xs: 'column', lg: 'row' }
        }}>
        <Box
          margin="0 auto"
          mt={{ xs: 4, md: 4, lg: 0 }}
          width={{ xs: '80%', md: '750px', lg: '750px' }}>
          <Typography
            variant="h3"
            color={colors.primary}
            fontSize={{ xs: 28, md: '3rem' }}
            fontWeight={600}
            ml={4}>
            FAQs
          </Typography>
          <Faqs />
        </Box>
      </Box>
    </Box>
  );
};

export default Subscription;
